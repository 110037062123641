<template>
    <div
        class="mx-5 mt-2"
    >
        <v-sheet class="rounded-10">
            <!-- 제목 -->
            <div class="pt-3 px-3">
                <v-sheet>
                    <p
                        style="font-size:14px; overflow:hidden; word-wrap:break-word; max-height:40px;"
                        class="ma-0"
                    >
                        {{title}}
                    </p>
                </v-sheet>
                <v-sheet
                    class="d-flex py-2px mt-1"
                >
                    <ProfileForBoardMobile v-if="writer" :user="writer" />
                    <p class="ml-2 my-0 font-weight-bold" style="font-size:10px;">{{nickname}}</p>
                    <font class="ml-4" style="font-size:10px;">
                        조회수 {{view_count}}
                    </font>
                    <font class="ml-2" style="font-size:10px; color:#B3B3C2;">
                        {{new Date(created).toLocaleString()}}
                    </font>
                </v-sheet>

                <!-- 판매자 매너점수 -->
                <v-sheet class="d-flex align-center">
                    <font style="font-size:12px;">판매자 매너점수</font>
                    <v-rating
                        class="ml-2"
                        :value="review_rating"
                        readonly
                        size="14"
                        style="height:26px;"
                        color="#615DFA"
                    ></v-rating>
                    <p
                        class="ml-1 mb-0 font-weight-bold mt-2px"
                        style="font-size:12px; color:#615DFA;"
                    >
                        ({{review_count}})
                    </p>
                </v-sheet>
            </div>

            <v-divider class="my-1 mx-3"></v-divider>

            <!-- 본문 -->
            <TipTapReader 
                class="tiptap_style px-3"
                v-if="options.content"
                :options="options"
            />

            <!-- 기타상품 목록 -->
            <EtcList
                @selected="etcListUpdated"
                :delivery_price="delivery_price"
            />

            <!-- 카드상품 목록 -->
            <CardListMobile
                v-if="card_list.length"
                @selected="cardListUpdated"
                :delivery_price="delivery_price"
                @tradeApply="tradeApply"
            />

            <!-- 덱상품 목록 -->
            <DeckListMobile
                v-if="deck_list.length"
                @selected="deckListUpdated"
                :delivery_price="delivery_price"
            />

            <!-- 본문 상단 -->
            <v-sheet 
                width="100%" 
                class="d-flex text-caption align-center py-1 px-5"
            >
                <div>
                    <div class="d-flex">
                        <!-- 거래유형 -->
                        <p class="mb-0">
                            거래유형
                            <span class="ml-1 font-weight-bold mr-2" style="color:#615DFA;">{{trade_type}}</span>
                        </p>

                        <!-- 거래방법 -->
                        <p class="mb-0">
                            거래방법
                            <span class="ml-1 font-weight-bold mr-2" style="color:#615DFA;">{{trade_way}}</span>
                        </p>
                    </div>

                    <!-- 주소 -->
                    <p class="mb-0 text-truncate" style="max-width:160px; font-size:11px;">
                        주소
                        <span class="ml-1 font-weight-bold" style="color:#615DFA;">{{seller_address.split(' ').slice(0, 3).join(' ')}}</span>
                    </p>
                </div>
            </v-sheet>

            <div class="px-5 d-flex justify-space-between mb-2 mt-2">
                <!-- 거래종료 (글쓴이) -->
                <v-btn
                    v-if="writer == $store.state.user_id && status == 'open'"
                    height="34"
                    width="48%"
                    class="rounded-10"
                    color="#F8468D"
                    dark
                    depressed
                    @click="closePost()"
                >
                    거래종료
                </v-btn>

                <!-- 거래종료 (글쓴이) -->
                <v-btn
                    v-if="writer == $store.state.user_id && status == 'open'"
                    height="35"
                    width="48%"
                    class="rounded-10"
                    color="#F8468D"
                    dark
                    depressed
                    @click="selectedCardClose()"
                >
                    선택 거래종료
                </v-btn>
            </div>

            <!-- 복사글일 시 원본글 링크 -->
            <v-sheet
                v-if="original_post_id != null"
                class="grey lighten-3 text-center my-4 py-2 rounded-10"
                style="cursor:pointer"
                @click="$router.push('/trade/post/read?id='+original_post_id)"
            >
                <span class="text-subtitle-1 font-weight-medium">
                    복사글입니다
                    <span class="primary--text">(원본 링크)</span>
                </span>
            </v-sheet>

            <!-- 게시글 아랫단 버튼 -->
            <v-sheet class="d-flex align-center mt-2 px-5 mb-4">
                <!-- 거래진행중 -->
                <span
                    v-if="status=='open'"
                    class="primary--text mr-5"
                    style="font-size:13px;"
                    text
                    depressed
                >
                    <v-icon color="primary" size="16" class="pb-3px">mdi-swap-horizontal-circle</v-icon>
                    거래진행중 {{count.progress}}
                </span>

                <!-- 거래완료 -->
                <span
                    class="primary--text mr-5"
                    style="font-size:13px;"
                    text
                    depressed
                >
                    <v-icon color="primary" size="16" class="pb-3px">mdi-check-circle</v-icon>
                    거래완료 {{count.finished}}
                </span>
            </v-sheet>

            <!-- 댓글 -->
            <Comment
                :writer="writer"
            />
        </v-sheet>

        <!-- 게시글 하단 버튼 -->
        <div class="d-flex align-center mt-2 mb-4 pr-1">
            <!-- 목록 -->
            <v-btn
                width="48"
                height="24"
                class="mr-1 rounded-5"
                style="font-size:10px; font-weight:bold;"
                color="#ADAFCA"
                dark
                depressed
                to="/trade/post/list"
            >
                목록
            </v-btn>
            

            <!-- 신고 (글쓴이 X) -->
            <v-btn
                v-if="writer != $store.state.user_id"
                width="48"
                height="24"
                class="rounded-5"
                style="font-size:10px; font-weight:bold;"
                color="#F8468D"
                dark
                depressed
                @click="report_post_open()"
            >
                신고
            </v-btn>

            <v-spacer></v-spacer>

            <!-- 관리자 수정요청 -->
            <v-btn
                dark
                depressed
                width="24"
                height="24"
                icon
                class="mr-6px pa-0 font-weight-bold rounded-circle"
                style="background:#23D2E2"
                @click="dialog.requestUpdate = true"
            >
                <v-icon size="12">mdi-application-settings-outline</v-icon>
            </v-btn>

            <!-- 글쓴이일 경우 또는 관리자일 경우 -->
            <div
                v-if="writer==$store.state.user_id || $store.state.type=='관리자'"
                class="d-flex"
            >
                <v-btn
                    dark
                    depressed
                    width="24"
                    height="24"
                    icon
                    class="mr-6px pa-0 font-weight-bold rounded-circle"
                    color="#F8468D"
                    :to="'/trade/post/update?id='+$route.query.id"
                >
                    <v-img contain src="@/assets/board/update.svg"></v-img>
                </v-btn>

                <v-btn
                    dark
                    depressed
                    width="24"
                    height="24"
                    icon
                    class="pa-0 font-weight-bold rounded-circle"
                    color="#F8468D"
                    @click="deletePost()"
                >
                    <v-img contain src="@/assets/board/delete.svg"></v-img>
                </v-btn>
            </div>
        </div>

        <!-- 관리자 수정요청 (dialog) -->
        <v-dialog v-model="dialog.requestUpdate" width="800" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    관리자 수정요청
                </p>

                <!-- 거래글 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; width:160px;">거래글</span>
                        <v-sheet class="text-truncate" style="width:400px;">
                            {{title}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 요청 항목 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; width:148px;">요청 항목</span>
                        <v-select
                            style="max-width:225px;"
                            placeholder="요청 항목"
                            dense
                            hide-details
                            solo
                            flat
                            :items="select_list.request"
                            v-model="requestUpdate.category"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 요청 내용 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">요청 내용</span>
                    <v-textarea
                        class="no_under"
                        rows="4"
                        hide-details
                        v-model="requestUpdate.content"
                        placeholder="내용을 입력해주세요"
                    ></v-textarea>
                </v-sheet>

                <div class="mt-4 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="requestUpdateSubmit()"
                    >
                        수정요청
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.requestUpdate = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 선택한 상품 거래신청 (dialog) -->
        <v-dialog
            v-model="dialog.apply"
            width="830"
            content-class="rounded-15 white"
        >
            <OrderMobile 
                :trade_way="trade_way"
                :seller_address="seller_address"
                :delivery_price="delivery_price"
                :selected_etc_list="selected_etc_list"
                :selected_card_list="selected_card_list"
                :seller="writer"
                @cancel="close_tradeApply"
            />
        </v-dialog>

        <!-- 거래글 신고하기 (dialog) -->
        <v-dialog v-model="dialog.reportPost" width="800" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    거래글 신고하기
                </p>

                <!-- 신고 거래글 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 거래글</span>
                        <v-sheet class="text-truncate" style="width:400px;">
                            {{title}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 대상자 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 대상자</span>
                        <v-sheet class="text-truncate" style="width:400px;">
                            {{writer}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 항목 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:240px;">신고 항목</span>
                        <v-select
                            style="max-width:260px;"
                            placeholder="신고 항목"
                            dense
                            hide-details
                            solo
                            flat
                            :items="select_list.report"
                            v-model="reportPost.category"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 신고 내용 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">신고 내용</span>
                    <v-textarea
                        class="no_under"
                        rows="4"
                        hide-details
                        v-model="reportPost.content"
                        placeholder="내용을 입력해주세요"
                    ></v-textarea>
                </v-sheet>

                <div class="mt-4 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="report_post_submit()"
                    >
                        신고
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.reportPost=false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 거래글 복사하기 (dialog) -->
        <v-dialog v-model="dialog.copy" width="800">
            <v-sheet class="rounded-10 pa-4">
                <p class="pt-1 font-weight-bold text-center" style="font-size:26px;">
                    거래글 복사하기
                </p>
                <v-divider></v-divider>
                <p class="mt-4 mb-0 text-center text-body-2 primary--text">
                    거래글 복사 시 제목, 판매카드의 가격에 한정하여 수정하여 복사하실 수 있습니다.
                </p>

                <v-sheet class="px-3 mt-4">
                    <v-sheet outlined class="pa-1 rounded-10">
                        <v-text-field
                            v-model="copy.title"
                            class="rounded-10"
                            hide-details
                            dense
                            solo
                            flat
                        ></v-text-field>
                    </v-sheet>
                </v-sheet>

                <v-sheet class="pa-3">
                    <v-sheet class="mt-2 d-flex" v-for="(item, index) in card_list" :key="index">
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:100%;">
                            <v-text-field
                                v-model="item.card_name"
                                class="rounded-10"
                                hide-details
                                dense
                                solo
                                flat
                                readonly
                                background-color="grey lighten-3"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 mr-2 rounded-10" style="width:100%;">
                            <v-text-field
                                v-model="item.card_state"
                                class="rounded-10"
                                hide-details
                                dense
                                solo
                                flat
                                readonly
                                background-color="grey lighten-3"
                            ></v-text-field>
                        </v-sheet>
                        <v-sheet outlined class="pa-1 rounded-10" style="width:100%;">
                            <v-text-field
                                v-model="item.card_price"
                                class="rounded-10"
                                hide-details
                                dense
                                placeholder="가격"
                                solo
                                flat
                                reverse
                                prefix="원"
                                @input="e => item.card_price = $inputToComma(e)"
                            ></v-text-field>
                        </v-sheet>
                    </v-sheet>
                    <v-sheet class="mt-4 pa-2 d-flex justify-center">
                        <v-btn
                            large
                            dark
                            depressed
                            height="49"
                            color="primary"
                            class="rounded-10 px-6 mr-4"
                            @click="copy_submit()"
                        >
                            <v-icon small left>mdi-database-edit-outline</v-icon>
                            복사하기
                        </v-btn>
                        <v-btn
                            large
                            dark
                            depressed
                            height="49"
                            color="grey"
                            class="rounded-10 px-6"
                            @click="dialog.copy=false"
                        >
                            <v-icon small left>mdi-cancel</v-icon>
                            취소하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Comment from "@/views/trade/post/read/components/Comment"
import ProfileForBoardMobile from "@/components/ProfileForBoardMobile"
import TipTapReader from "@/components/tiptap/Reader"
import EtcList from "@/views/trade/post/read/components/EtcList"
import CardListMobile from "@/views/trade/post/read/components/CardListMobile"
import DeckListMobile from "@/views/trade/post/read/components/DeckListMobile"
import OrderMobile from "@/views/trade/post/read/components/OrderMobile"
import PointModule from '@/components/PointModule.js'

export default {
    components: {
        Comment,
        ProfileForBoardMobile,
        TipTapReader,
        EtcList,
        CardListMobile,
        DeckListMobile,
        OrderMobile,
        PointModule
    },

    data: () => ({
        title: '',
        price: '',
        sell_how: '',
        created: '',
        writer: '',
        profile_image: '',
        nickname: '',
        view_count: 0,
        review_rating: 0,
        review_count:0,
        copy_count:0,
        original_post_id: null,
        
        trade_type: "",
        trade_way: "",
        delivery_price: "",
        trade_state: "",
        seller_address: "",

        post: {
            writer: "",
            writer: "",
        },

        //TipTap
        options: {
            content: '',
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        like_list: [],
        dislike_list: [],

        myAddressDefault: "",

        status: "",

        selected_etc_list: [],

        selected_card_list: [],

        dialog: {
            requestUpdate: false,
            reportPost: false,
            apply: false,
            copy: false
        },

        select_list: {
            request: [
                "카드 변경",
                "카드등급 변경",
                "카드가격 변경",
                "기타"
            ],

            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        requestUpdate: {
            category: "",
            content: ""
        },

        reportPost: {
            category: "",
            content: ""
        },

        copy: {
            title: ""
        },

        // 기타상품 목록
        etc_list: [],

        // 카드상품 목록
        card_list: [],

        // 덱상품 목록
        deck_list: [],

        // 거래진행중, 거래완료 Count
        count: {
           progress: 0,
           finished: 0
        }
    }),

    mounted(){
        // 게시글 정보 불러오기
        this.$http.post('/api/trade/post/read/import', {
            params: {
                id: this.$route.query.id
            }
        }).then((res) => {
            this.title = res.data[0].title
            this.writer = res.data[0].writer
            this.nickname = res.data[0].nickname
            this.profile_image = res.data[0].profile_image
            this.view_count = res.data[0].view_count
            this.review_rating = res.data[0].review_rating
            this.review_count = res.data[0].review_count
            this.options.content = res.data[0].content
            this.created = res.data[0].created
            this.trade_type = res.data[0].trade_type
            this.trade_way = res.data[0].trade_way
            this.delivery_price = res.data[0].delivery_price
            this.trade_state = res.data[0].trade_state
            this.seller_address = res.data[0].seller_address
            this.status = res.data[0].status

            if(this.status == 'deleted')
            {
                alert("이 거래글은 삭제된 거래글입니다.")
                this.$router.go(-1)
            }

            this.copy_count = res.data[0].copy_count
            this.original_post_id = res.data[0].original_post_id
        }),

        // 내 배송지 목록 불러오기
        this.$http.post('/api/shipping/select', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then(res => {
            this.myAddressDefault = res.data[0]
        })

        // 기타상품목록 불러오기
        this.$http.post('/api/trade/post/select/etclist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            if(res.data.length){
                this.etc_list = res.data
                this.etc_list.forEach(e => {
                    e.etc_price = this.$toComma(e.etc_price)
                })
            }
        })

        // 덱상품목록 불러오기
        this.$http.post('/api/trade/post/select/decklist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            if(res.data.length){
                this.deck_list = res.data
                this.deck_list.forEach(e => {
                    e.deck_price = this.$toComma(e.deck_price)
                })
            }
        })

        // 카드목록 불러오기
        this.$http.post('/api/trade/post/read/import/cardlist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            this.card_list = res.data

            this.card_list.forEach(e => {
                e.card_price = this.$toComma(e.card_price)
            })
        })

        // 거래진행중, 거래완료 Count 불러오기
        this.$http.post('/api/trade/post/select/order/count', {
            params: {
                post_id: this.$route.query.id
            }
        }).then((res) => {
            this.count.progress = res.data[0].progress_count
            this.count.finished = res.data[0].finished_count
        })

        // 조회수 증가
        this.$http.post('/api/trade/post/update/view_count/increase', {
            params: {
                id: this.$route.query.id
            }
        })
    },

    methods: {
        // 선택된 기타상품 불러오기
        etcListUpdated(list){
            this.selected_etc_list = list
        },

        // 선택된 카드목록 불러오기
        cardListUpdated(list) {
            this.selected_card_list = list
        },

        // 선택된 덱목록 불러오기
        deckListUpdated(list) {
            this.selected_deck_list = list
        },

        // 선택한 카드 거래종료
        async selectedCardClose(){
            if(this.selected_card_list.length == 0){
                alert("선택한 상품이 없습니다.")
            }
            else{
                // state 거래종료로 변경
                for(let i=0; i<this.selected_card_list.length; i++){
                    await this.$http.post("/api/trade/post/read/update/card/state/close", {
                        params: {
                            id: this.selected_card_list[i]
                        }
                    })
                }

                this.$router.go(this.$router.currentRoute)
            }
        },

        // 거래신청하기 열기
        tradeApply(){
            if(!this.$store.state.is_logined){
                alert("거래신청하기는 로그인 후에만 가능합니다.")
            }
            else if(this.$store.state.type == '밴 유저'){
                alert('밴 유저는 거래신청하기가 제한됩니다.')
            }
            else{
                if(this.writer == this.$store.state.user_id){
                    alert("자신의 거래글에는 거래신청을 할 수 없습니다.")
                }
                else{
                    if(!this.myAddressDefault){
                        alert("배송지가 등록되지 않았거나 기본배송지가 설정되어 있지 않습니다.")
                    }
                    else{
                        if(this.selected_etc_list.length == 0 && this.selected_card_list.length == 0){
                            alert("선택한 상품이 없습니다.")
                        }
                        else{
                            this.dialog.apply = true
                        }
                    }
                }
            }
        },

        // 거래신청하기 닫기
        close_tradeApply(){
            this.dialog.apply = false
        },

        // 거래종료
        closePost(){
            if(confirm("해당 판매글을 거래종료하겠습니까?")){
                this.$http.post('/api/trade/post/update/status/close', {
                    params: {
                        id : this.$route.query.id
                    }
                }).then(() => {
                    this.$router.go(this.$router.currentRoute)
                })
            }
        },

        // 거래글 신고하기 열기
        report_post_open(){
            if(!this.$store.state.is_logined){
                alert("신고하기는 로그인 후 이용가능합니다")
            }else{
                this.dialog.reportPost = true
            }
        },

        // 거래글 신고하기 제출
        report_post_submit(){
            if(this.reportPost.category == ""){
                alert("신고 항목을 선택해주세요")
            }else if(this.reportPost.content == ""){
                alert("신고 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/user/reported/insert', {
                    params: {
                        reporter_user_id: this.$store.state.user_id,
                        reported_user_id: this.writer,
                        type: "trade_post",
                        reported_id: this.$route.query.id,
                        category: this.reportPost.category,
                        content: this.reportPost.content,
                        link: this.$route.fullPath
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        // DB 알림
                        this.$dbAlarm(
                            "신고 - 거래글",
                            this.nickname,
                            this.user_id
                        )

                        alert("신고가 접수되었습니다.")
                        this.dialog.reportPost = false
                    }
                })
            }
        },

        // 관리자 수정요청 제출
        requestUpdateSubmit(){
            if(this.requestUpdate.category == ""){
                alert("요청 항목을 선택해주세요")
            }else if(this.requestUpdate.content == ""){
                alert("요청 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/trade/request/insert', {
                    params: {
                        post_id: this.$route.query.id,
                        user_id: this.$store.state.user_id,
                        category: this.requestUpdate.category,
                        content: this.requestUpdate.content,
                        status: "접수"
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("수정요청이 접수되었습니다.")
                        this.dialog.requestUpdate = false
                    }
                })
            }
        },

        // 거래글 복사하기 열기
        copy_open(){
            if(this.copy_count >=3 && this.copy_count < 10){
                alert("거래글 복사는 최대 3회까지 가능합니다.")
            }else if(this.copy_count > 900){
                alert("복사된 거래글은 다시 복사하실 수 없습니다.")
            }else{
                this.copy.title = this.title
                this.dialog.copy = true
            }
        },

        // 거래글 복사하기 제출
        copy_submit(){
            // 거래글 복사하기 오늘 했는지 체크
            this.$http.post('/api/trade/post/read/copy/list', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                if(new Date().toLocaleDateString() == new Date(res.data[0].created).toLocaleDateString()){
                    alert("거래글 복사하기는 하루에 1회만 가능하며, 회원님께서는 이미 오늘 거래글 복사하기를 사용하셨습니다.")
                }else{
                    // 거래글 복사하기 제출
                    this.$http.post('/api/trade/post/read/copy', {
                        params: {
                            id: this.$route.query.id,
                            title: this.copy.title
                        }
                    }).then((res) => {
                        if(res.data.insertId){
                            this.copy_count += 1
                            this.cardSubmit(res.data.insertId)
                            alert("거래글이 복사되었습니다.")
                            this.dialog.copy = false
                        }
                    })
                }
            })
        },

        // 거래글 복사하기 > 카드입력
        async cardSubmit(insertId){
            for(let i=0; i<this.card_list.length; i++){
                await this.$http.post('/api/trade/post/read/copy/card', {
                    params: {
                        post_id : insertId,
                        card_id: this.card_list[i].card_id,
                        card_nation : this.card_list[i].card_nation,
                        card_name : this.card_list[i].card_name,
                        card_state : this.card_list[i].card_state,
                        card_price : parseInt(this.$toUnComma(this.card_list[i].card_price))
                    }
                })
            }
        },

        // 거래글 삭제하기
        deletePost(){
            if(confirm("정말 해당 거래글을 삭제하시겠습니까?")){
                this.$http.post("/api/trade/post/read/update/status", {
                    params: {
                        id: this.$route.query.id,
                        status: "deleted"
                    }
                }).then((res) => {
                    PointModule.amountCheck('카드거래', '거래글 등록 카드 1개당').then((res) => {
                        PointModule.Update(this.writer, res.data[0].amount*this.card_list.length)
                        PointModule.Record(this.writer, '카드거래', '거래글 등록 카드 1개당 (취소)', this.title, -res.data[0].amount*this.card_list.length)
                    })
                    
                    if(res.data.affectedRows){
                        alert("거래글이 삭제되었습니다.")
                        this.$router.push("/trade/post/list")
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>