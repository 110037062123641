<template>
    <div class="rounded-15 pa-4">
        <!-- 제목 -->
        <v-sheet class="pb-4">
            <p
                class="font-weight-bold py-1 ma-0 text-center"
                style="font-size:16px;"
            >
                선택한 상품 거래 신청
            </p>
        </v-sheet>

        <!-- 카드 목록 -->
        <div
            v-for="item in card_list" :key="item.id"
            style="cursor:pointer; border-bottom:1px solid #e0e0e0; width:100%;"
            class="pl-5 pr-2 d-flex align-center"
        >
            <!-- 이미지 -->
            <v-sheet class="my-2" @click="goToDetail(item.card_id)">
                <!-- <v-img height="80" width="58" src="@/assets/default.png"></v-img> -->
                <v-img
                    v-if="item.card_nation=='en'" 
                    width="48" 
                    :src="'/upload/card_en_image/'+(item.image_en==null? 'default.png':encodeURI(item.image_en))"
                ></v-img>
                <v-img 
                    v-if="item.card_nation=='kr'"
                    width="48" 
                    :src="'/upload/card_kr_image/'+(item.image_kr==null? 'default.png':encodeURI(item.image_kr))"
                ></v-img>
                <v-img 
                    v-if="item.card_nation=='jp'" 
                    width="48" 
                    :src="'/upload/card_jp_image/'+(item.image_jp==null? 'default.png':encodeURI(item.image_jp))"
                ></v-img>
            </v-sheet>

            <!-- 정보 -->
            <v-sheet
                class="ml-4"
                @click="goToDetail(item.card_id)"
            >
                <!-- 카드 이름 -->
                <p
                    class="mb-0"
                    style="font-size:15px; font-weight:bold;"
                >
                    {{item.card_name}}
                </p>

                <p
                    class="mb-0"
                    style="font-size:14px; font-weight:medium; line-height:18px;"
                >
                    {{item.card_state}} <font class="icu_purple--text">{{$toComma(item.card_price)}}원</font>
                </p>

                <p class="mb-0 text-truncate" style="font-size:12px; line-height:14px; width:180px;">
                    <v-icon small style="margin-bottom:2px;" class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                    <font v-if="item.card_nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                    <font v-if="item.card_nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                    <font v-if="item.card_nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                    <font v-if="item.card_nation=='en' && item.printedTotal">/{{item.printedTotal.replaceAll("\"", "")}}</font>
                    <font v-if="item.card_nation=='kr' && item.set_printedTotal_kr">/{{item.set_printedTotal_kr}}</font>
                    <font v-if="item.card_nation=='jp' && item.set_printedTotal_jp">/{{item.set_printedTotal_jp}}</font>
                    <span class="ml-2">
                        <font v-if="item.card_nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                        <font v-if="item.card_nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                        <font v-if="item.card_nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                    </span>
                </p>
                <p v-if="item.card_nation=='en' && item.set_en" class="mb-0 text-truncate" style="width:180px; font-size:12px; letter-spacing:-0.4px; line-height:14px;">{{item.set_en}}</p>
                <p v-if="item.card_nation=='kr' && item.set_kr" class="mb-0 text-truncate" style="width:180px; font-size:12px; letter-spacing:-0.4px; line-height:14px;">{{item.set_kr}}</p>
                <p v-if="item.card_nation=='jp' && item.set_jp" class="mb-0 text-truncate" style="width:180px; font-size:12px; letter-spacing:-0.4px; line-height:14px;">{{item.set_jp}}</p>
            </v-sheet>
        </div>

        <!-- 입력란 -->
        <v-sheet class="mt-4">
            <v-select
                class="rounded-10 font-weight-bold"
                hide-details
                dense
                outlined
                placeholder="배송지 목록"
                :items="select_list.shipping_address"
                v-model="shipping_address"
            ></v-select>
            <v-textarea
                class="mt-2 rounded-10"
                outlined
                placeholder="메세지"
                v-model="content"
            ></v-textarea>
        </v-sheet>

        <!-- 버튼 -->
        <v-sheet class="pb-1 px-2 d-flex justify-center">
            <v-btn
                height="40"
                width="110"
                class="rounded-10 mr-2"
                color="#ADAFCA"
                dark
                depressed
                @click="cancel()"
            >
                취소하기
            </v-btn>
            <v-btn
                height="40"
                width="110"
                class="rounded-10"
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                거래신청하기
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: [
        "trade_way",
        "seller_address",
        "delivery_price",
        "selected_etc_list",
        "selected_card_list",
        "seller"
    ],

    data: () => ({
        etc_list: [],
        card_list: [],

        select_list: {
            shipping_address: []
        },

        shipping_address: "",
        content: "",

        etc_sum: 0,
        sum: 0
    }),

    mounted(){
        this.etc_list = this.selected_etc_list

        // 선택한 카드목록 가져오기
        this.$http.post('/api/trade/post/read/import/cardList/selected', {
            params: {
                ids : this.selected_card_list
            }
        }).then(res => {
            this.card_list = res.data
            this.card_list.forEach(e => {
                this.sum += e.card_price
            })
        })

        // 내 배송지 목록 가져오기
        this.$http.post('/api/shipping/select', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then(res => {
            this.select_list.shipping_address = res.data.map(e => e.address)
        })
    },
    
    computed:{
        selectedEtcSum(){
            let sum = 0

            this.etc_list.forEach(e => {
                sum +=  e.etc_price
            })

            return Math.round(parseInt(sum))
        },

        selectedSum(){
            let sum = 0

            this.card_list.forEach(e => {
                sum +=  e.card_price
            })

            return Math.round(parseInt(sum))
        }
    },

    watch: {
        // 선택한 카드목록 가져오기
        selected_list(){
            this.$http.post('/api/trade/post/read/import/cardList/selected', {
                params: {
                    ids : this.selected_card_list
                }
            }).then(res => {
                this.card_list = res.data
            })
        }
    },
    
    methods: {
        // 취소하기
        cancel(){
            this.$emit("cancel")
        },

        // 거래신청하기
        submit(){
            // 거래정보 입력
            this.$http.post('/api/trade/post/read/apply/submit', {
                params: {
                    post_id: this.$route.query.id,
                    trade_post_card_ids: this.card_list.map(a => a.id).toString(),
                    buyer: this.$store.state.user_id,
                    seller: this.seller,
                    shipping_address: this.shipping_address,
                    content: this.content
                }
            }).then(res => {
                // 메세지 입력
                this.$http.post('/api/message/insert', {
                    params: {
                        sender: this.$store.state.user_id,
                        reciever: this.seller,
                        type: "order",
                        order_id: res.data.insertId
                    }
                }).then(res => {
                    alert("거래신청이 완료되었습니다.")
                    this.$router.push("/mypage/message")
                })

                // DB 알림
                this.$dbAlarm(
                    "카드거래 - 거래",
                    this.$store.state.nickname,
                    this.$store.state.user_id
                )

                // 판매자에게 거래신청 알림
                this.$http.post('/api/alarm/insert', {
                    params: {
                        user_id: this.seller,
                        sender: this.$store.state.user_id,
                        type: "trade_requested"
                    }
                })
            })
        }
    }
}
</script>
<style scoped>
.cardList{
    width:100%;
    border-collapse: collapse;
}

.cardList tr{
    border-top:1px solid #e0e0e0;
}

.cardList tr:last-child{
    border-bottom:1px solid #e0e0e0;
}

.cardList tr td{
    padding:8px;
}

.cardList tr th{
    height:70px;
    font-size:16px;
   
    padding:8px;
}
</style>