<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <div class="pa-4 pr-0 d-flex align-center">
                <!-- 제목 -->
                <div>
                    <p
                        style="font-size:26px; font-weight:bold; margin-bottom:4px; cursor:pointer;"
                        @click="$router.push('/trade/post/list')"
                    >
                        카드거래
                        <span style="margin-left:4px; color:#F8468D; font-size:12px; vertical-align:super; font-weight:500;">BETA</span>
                    </p>
                    <p style="font-size:11px;" class="mb-0">유저간 실거래 기록으로 합리적 가격 시장 형성</p>
                </div>
                <v-spacer></v-spacer>
                
                <!-- 메뉴 -->
                <v-sheet class="mt-1 pt-1 px-7 rounded-15 d-flex justify-space-between">
                    <v-btn
                        height="56"
                        class="px-0 mr-8"
                        style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="$route.path.startsWith('/trade/post')? 'primary':''"
                        :style="$route.path.startsWith('/trade/post')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                        :dark="$route.path.startsWith('/trade/post')"
                        @click="link('/trade/post/list')"
                    >
                        거래글
                    </v-btn>
                    <v-btn
                        height="56"
                        class="px-0 mr-8"
                        style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="$route.path.startsWith('/trade/card')? 'primary':''"
                        :style="$route.path.startsWith('/trade/card')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                        :dark="$route.path.startsWith('/trade/card')"
                        @click="link('/trade/card_list')"
                    >
                        카드별
                    </v-btn>
                    <v-btn
                        height="56"
                        class="px-0"
                        style="font-size:18px; font-weight:bold; letter-spacing:-1px; min-width:0px;"
                        text
                        tile
                        depressed
                        v-ripple="false"
                        :color="$route.path.startsWith('/trade/log')? 'primary':''"
                        :style="$route.path.startsWith('/trade/log')? 'border-bottom:4px solid #23D2E2;':'border-bottom:4px solid white;'"
                        :dark="$route.path.startsWith('/trade/log')"
                        @click="link('/trade/log')"
                    >
                        거래 이력 조회
                    </v-btn>
                </v-sheet>
            </div>

            <!-- 본문 -->
            <router-view></router-view>

            <!-- 등급 참고용 가이드 안내문구 -->
            <v-sheet class="px-4 py-1 mt-2 rounded-10">
                <p class="text-caption text-left my-4" style="font-size:14px !important;">
                <v-icon color="#ADAFCA" class="mb-3px">
                    mdi-alert-circle-outline
                </v-icon>
                    [등급 참고용 가이드] <br/>
                    <font style="font-size:13px !important;">
                        <span class="primary--text">S급</span> : 공정하자가 없고 사용한 적이 없는 신품급 / 
                        <span class="primary--text">A급</span> : 미세한 생활 기스, 미세한 공정하자 / <br/>
                        <span class="primary--text">B급</span> : 미세한 잉크 벗겨짐 및 종이 손상, 생활 기스
                        <span class="primary--text">C급 이하</span> : 카드 많이 휨, 다수의 손상, 색바램, 얼룩 / 
                        <span class="primary--text">등급</span> : PSG등의 그레이딩 <br/>
                    </font>
                </p>
            </v-sheet>

            <!-- 전자상거래 안내문구 -->
            <v-sheet class="px-4 py-1 mt-2 rounded-10">
                <p class="text-caption text-center my-4">
                    모든 상품들에 대해 ICU.GG(너정다)는 통신판매중개자로서 거래 당사자(판매자, 구매자)가 아니며 <br/>
                    거래편의를 위한 카드 정보(이미지, 가격 등)외의 어떤 절차에도 관여하지 않고 이에 어떠한 의무와 책임도 부담하지 않습니다.<br/>
                    거래 전 <a style="text-decoration:none;" href="/policy/rule?type=read">이용약관(17조 물품거래서비스의 이용)</a>을 확인하시고 거래를 진행해주세요. 
                </p>
            </v-sheet>

            <!-- 전자상거래 안내문구 -->
            <div
                class="d-flex"
            >
                <!-- 더치트1 -->
                <v-sheet class="rounded-10 mt-2 px-10">
                    <v-img
                        class="rounded-10"
                        style="cursor:pointer;"
                        src="@/assets/the_cheat_1.png"
                        @click="openTheCheat1()"
                    />
                </v-sheet>

                <v-sheet class="px-4 py-1 mt-2 rounded-10 mx-2 flex-grow-1">
                    <p class="text-caption text-center my-2">
                        <a style="text-decoration:none;" href="/board/list?board_group=커뮤니티&board_name=블랙리스트%20공유%20게시판">블랙리스트 공유 게시판</a>
                    </p>
                </v-sheet>

                <!-- 더치트2 -->
                <v-sheet class="rounded-10 mt-2 px-10">
                    <v-img
                        class="rounded-10"
                        style="cursor:pointer;"
                        src="@/assets/the_cheat_2.png"
                        @click="openTheCheat2()"
                    />
                </v-sheet>
            </div>

            <BannerView
                v-if="$store.state.ads_banner.card_trade"
                class="mt-3 mx-auto"
                type="card_trade"
                width="360"
                height="46"
                :round="'rounded-10'"
            />
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <div class="px-4 pt-3 pb-2 d-flex align-center">
                <!-- 제목 -->
                <div>
                    <p
                        style="font-size:16px; font-weight:bold; margin-bottom:0px; cursor:pointer;"
                        @click="$router.push('/trade/post/list')"
                    >
                        카드거래
                        <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
                    </p>
                </div>

                <v-spacer></v-spacer>

                <div
                    v-if="!$route.path.startsWith('/trade/post/write') && !$route.path.startsWith('/trade/post/update')"
                >
                    <v-btn
                        v-if="$route.path.startsWith('/trade/post/read')"
                        width="68"
                        height="28"
                        style="font-size:12px; letter-spacing:-0.6px;"
                        depressed
                        class="rounded-10 mr-3"
                        text
                        v-ripple="false"
                        @click="link('/trade/log')"
                    >
                        거래이력조회
                    </v-btn>
                    <v-btn
                        v-show="false"
                        width="34"
                        height="28"
                        style="font-size:10px; letter-spacing:-1px; min-width:0 !important;"
                        depressed
                        class="rounded-10 px-0 mr-0"
                        dark
                        x-small
                        v-ripple="false"
                        color="primary"
                    >
                        <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn
                        width="34"
                        height="28"
                        style="font-size:10px; letter-spacing:-1px; min-width:0 !important;"
                        depressed
                        class="rounded-10 px-0"
                        dark
                        x-small
                        v-ripple="false"
                        color="icu_purple"
                        @click="link('/trade/post/write')"
                    >
                        <v-icon small>mdi-pencil-outline</v-icon>
                    </v-btn>
                </div>
            </div>

            <!-- 메뉴 -->
            <div
                v-if="!$route.path.startsWith('/trade/post/write') && !$route.path.startsWith('/trade/post/update')"
                class="d-flex justify-space-between px-4"
            >
                <v-btn
                    width="76"
                    height="28"
                    style="font-size:11px; letter-spacing:-0.4px;"
                    depressed
                    class="rounded-10"
                    dark
                    v-ripple="false"
                    :color="$route.path.startsWith('/trade/post/list') && !$route.query.my? '#615DFA':'#ADAFCA'"
                    @click="link('/trade/post/list')"
                >
                    거래글
                </v-btn>
                <v-btn
                    width="76"
                    height="28"
                    style="font-size:11px; letter-spacing:-0.4px;"
                    depressed
                    class="rounded-10"
                    dark
                    v-ripple="false"
                    :color="$route.path.startsWith('/trade/card')? '#615DFA':'#ADAFCA'"
                    @click="link('/trade/card_list')"
                >
                    카드별
                </v-btn>
                <v-btn
                    width="76"
                    height="28"
                    style="font-size:11px; letter-spacing:-0.4px;"
                    depressed
                    class="rounded-10"
                    dark
                    v-ripple="false"
                    :color="$route.path.startsWith('/trade/post/list')  && $route.query.my? '#615DFA':'#ADAFCA'"
                    @click="link('/trade/post/list?my=true')"
                >
                    내 거래글
                </v-btn>
                <v-btn
                    v-if="!$route.path.startsWith('/trade/post/read')"
                    width="76"
                    height="28"
                    style="font-size:11px; letter-spacing:-0.4px;"
                    depressed
                    class="rounded-10"
                    dark
                    v-ripple="false"
                    :color="$route.path.startsWith('/trade/log')? 'primary':'#ADAFCA'"
                    @click="link('/trade/log')"
                >
                    거래이력조회
                </v-btn>

                <v-btn
                    v-if="$route.path.startsWith('/trade/post/read')"
                    width="76"
                    height="28"
                    style="font-size:11px; letter-spacing:-0.4px;"
                    depressed
                    class="rounded-10"
                    dark
                    v-ripple="false"
                    :color="post_status == 'open'? 'primary':'#ADAFCA'"
                    @click="link('/trade/log')"
                >
                    {{post_status == 'open'? '거래중':'판매종료'}}
                </v-btn>
            </div>

            <BannerView
                v-if="$store.state.ads_banner.card_trade"
                class="mt-3"
                type="card_trade"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />

            <!-- 본문 -->
            <router-view></router-view>

            <!-- 등급 참고용 가이드 안내문구 -->
            <v-sheet class="px-4 py-1 mt-2 rounded-10 mx-3">
                <p class="text-caption text-left my-4" style="font-size:14px !important;">
                <v-icon color="#ADAFCA" class="mb-3px">
                    mdi-alert-circle-outline
                </v-icon>
                    [등급 참고용 가이드] <br/>
                    <font style="font-size:13px !important;">
                        <span class="primary--text">S급</span> : 공정하자가 없고 사용한 적이 없는 신품급<br/>
                        <span class="primary--text">A급</span> : 미세한 생활 기스, 미세한 공정하자<br/>
                        <span class="primary--text">B급</span> : 미세한 잉크 벗겨짐 및 종이 손상, 생활 기스<br/>
                        <span class="primary--text">C급 이하</span> : 카드 많이 휨, 다수의 손상, 색바램, 얼룩<br/>
                        <span class="primary--text">등급</span> : PSG등의 그레이딩
                    </font>
                </p>
            </v-sheet>

            <!-- 전자상거래 안내문구 -->
            <v-sheet class="px-4 py-1 mt-2 rounded-10 mx-3">
                <p class="text-caption text-center my-4">
                    모든 상품들에 대해 ICU.GG(너정다)는 통신판매중개자로서 거래 당사자(판매자, 구매자)가 아니며 <br/>
                    거래편의를 위한 카드 정보(이미지, 가격 등)외의 어떤 절차에도 관여하지 않고 이에 어떠한 의무와 책임도 부담하지 않습니다.<br/>
                    거래 전 <a style="text-decoration:none;" href="/policy/rule?type=read">이용약관(17조 물품거래서비스의 이용)</a>을 확인하시고 거래를 진행해주세요. 
                </p>
            </v-sheet>

            <!-- 전자상거래 안내문구 -->
            <v-sheet class="px-4 py-1 mt-2 rounded-10 mx-3">
                <p class="text-caption text-center my-2">
                    <a style="text-decoration:none;" href="/board/list?board_group=커뮤니티&board_name=블랙리스트%20공유%20게시판">블랙리스트 공유 게시판</a>
                </p>
            </v-sheet>

            <!-- 더치트1 -->
            <v-sheet class="rounded-10 mt-2 mx-3">
                <v-img
                    width="60%"
                    class="rounded-10 mx-auto"
                    style="cursor:pointer;"
                    src="@/assets/the_cheat_1.png"
                    @click="openTheCheat1()"
                />
            </v-sheet>

            <!-- 더치트2 -->
            <v-sheet class="rounded-10 mt-2 mx-3 mb-10">
                <v-img
                    width="60%"
                    class="rounded-10 mx-auto"
                    style="cursor:pointer;"
                    src="@/assets/the_cheat_2.png"
                    @click="openTheCheat2()"
                />
            </v-sheet>
        </div>
    </div>
</template>
<script>
import PostList from '@/views/trade/post/list'
import CardList from '@/views/trade/card_list'
import Log from '@/views/trade/log'
import BannerView from '@/components/BannerView'

export default {
    components: {
        PostList,
        CardList,
        Log,
        BannerView
    },

    data: () => ({
        tabs: null,
        menu: [
            '전체',
            '새 소식',
            '대회 / 이벤트 공지',
            '공인샵 / 취급매장 공지',
            '너정다 공지'
        ],

        post_status: "open"
    }),

    mounted(){
        // (Mobile) 거래글 상태 불러오기
        if(this.$route.path.startsWith('/trade/post/read')){
            this.$http.post("/api/trade/post/select", {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.post_status = res.data[0].status
                }
            })
        }
    },

    methods: {
        link(url){
            if(!(url ==(this.$route.path+'?menu='+this.$route.query.menu))){
                this.$router.push(url)
            }
        },

        openTheCheat1(){
            window.open("https://thecheat.co.kr/rb/?mod=_search", '_blank')
        },

        openTheCheat2(){
            window.open("https://cyberbureau.police.go.kr/mobile/tm/sub/sub_02.jsp", '_blank')
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>