<template>
    <div>
        <table class="cardListTable" v-if="etc_list.length">
            <tr style="border-top:none;">
                <td colspan="5">
                    <v-sheet class="d-flex justify-end py-1">
                        <!-- 총 수량 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            총 수량
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{etc_list.length}}개</span>
                        </p>

                        <!-- 총 가격 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            총 가격
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(totalSum)}}원</span>
                        </p>
                    </v-sheet>
                </td>
            </tr>
            <tr>
                <th>이미지</th>
                <th>기타상품명</th>
                <th>상태</th>
                <th>가격</th>
                <th>선택</th>
            </tr>
            <tr v-for="item in etc_list" :key="item.id">
                <td width="76">
                    <v-img
                        @click="selected_item = item; dialog.image = true"
                        style="cursor:pointer;"
                        width="48"
                        :src="'/upload/trade/etc/'+item.etc_image"
                    ></v-img>
                </td>
                <td>
                    <p class="mb-0">{{item.etc_name}}</p>
                </td>
                <td width="120" class="text-center">{{item.etc_state}}</td>
                <td width="200" class="text-center">{{$toComma(item.etc_price)}}원</td>
                <td width="80">
                    <v-sheet class="d-flex justify-center">
                        <v-checkbox
                            v-if="item.state=='등록됨'"
                            class="ml-3"
                            v-model="selected"
                            :value="item"
                            @change="emitSelected()"
                        ></v-checkbox>
                        <p
                            v-if="item.state=='거래진행중'"
                            class="my-0 grey--text text-body-2"
                            style="letter-spacing:-1px !important;"
                        >
                            거래진행중
                        </p>
                        <p
                            v-if="item.state=='판매완료'"
                            class="my-0 grey--text text-body-2"
                        >
                            판매완료
                        </p>
                    </v-sheet>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <v-sheet class="d-flex py-1 ml-2">
                        <!-- 총 수량 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            선택된 서플라이 총 수량
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{selected.length}}개</span>
                        </p>

                        <!-- 총 가격 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            선택 된 서플라이 총 가격
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(selectedSum)}}원</span>
                        </p>

                        <!-- 배송비 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            배송비
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(delivery_price)}}원</span>
                        </p>
                    </v-sheet>
                </td>
                <td class="py-0 text-center">
                    <span class="text-body-2 font-weight-medium">전체선택/취소</span>
                </td>
                <td class="py-0">
                    <v-sheet class="d-flex justify-center">
                    <v-checkbox
                        class="ml-3"
                        @change="selectAll(); emitSelected()"
                    ></v-checkbox>
                    </v-sheet>
                </td>
            </tr>
        </table>

        <!-- 사진 확대 (dialog) -->
        <v-dialog
            v-model="dialog.image"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-2 rounded-15"
            >
                <v-img
                    class="rounded-10"
                    contain
                    max-width="1000"
                    :src="'/upload/trade/etc/'+selected_item.etc_image"
                ></v-img>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: [
        "delivery_price"
    ],
    
    data: () => ({
        etc_list: [],
        selected: [],

        dialog: {
            image: false
        },

        selected_item: {}
    }),

    mounted() {
        // 기타상품목록 불러오기
        this.$http.post('/api/trade/post/select/etclist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            if(res.data.length){
                this.etc_list = res.data
            }
        })
    },

    computed:{
        selectedSum(){
            let sum = 0
            this.selected.forEach(e => {
                sum +=  e.etc_price
            })

            return Math.round(parseInt(sum))
        },

        totalSum(){
            let sum = 0
            this.etc_list.forEach(e => {
                sum +=  e.etc_price
            })

            return Math.round(parseInt(sum))
        }
    },

    methods: {
        // 전체선택/취소
        selectAll(){
            if(this.selected.length){
                this.selected = []
            }else{
                this.selected = this.etc_list
            }
        },

        // 선택값 Emit
        emitSelected(){
            this.$emit("selected", this.selected)
        }
    }
}
</script>
<style scoped>
.cardListTable{
    width:100%;
    border-collapse: collapse;
}

.cardListTable tr{
    border-top:1px solid #e0e0e0;
}

.cardListTable tr:last-child{
    border-bottom:1px solid #e0e0e0;
}

.cardListTable tr td{
    padding:8px;
}

.cardListTable tr th{
    height:70px;
    font-size:16px;
   
    padding:8px;
}
</style>