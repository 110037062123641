<template>
    <v-sheet
        class="rounded-10"
    >
        <!-- 목록 -->
        <v-sheet
            v-for="item in comment_list" :key="item.id"
            class="px-3 pt-3 pb-4 my-1"
        >
            <!-- 블라인드됨 -->
            <v-sheet 
                v-if="item.status=='블라인드됨'"
                class="py-3"
                style="position:relative"
            >
                <p class="text-caption text-center my-2">해당 댓글은 관리자에 의해 블라인드 처리되었습니다.</p>
                <v-btn
                    v-if="$store.state.type=='관리자'"
                    style="position:absolute; top:4px; right:4px;"
                    class="rounded-10"
                    color="#23D2E2"
                    small
                    dark
                    depressed
                    @click="unblind_comment(item)"
                >   
                    블라인드 해제
                </v-btn>
            </v-sheet>

            <!-- 삭제 -->
            <v-sheet 
                v-else-if="item.status=='삭제'"
                class="py-3"
                style="position:relative"
            >
                <!-- 상단 -->
                <v-sheet
                    class="d-flex align-center"
                    :class="$vuetify.breakpoint.xs? 'mb-0' : 'mb-2'"
                >
                    <!-- 프로필 -->
                    <Profile :user="item.user_id"/>

                    <!-- 닉네임 -->
                    <p
                        class="mx-2 my-0 font-weight-bold"
                        style="font-size:13px; line-height:18px !important;"
                    >
                        {{item.nickname}}
                    </p>

                    <!-- 작성자일 시 -->
                    <v-icon
                        v-if="item.user_id == writer"
                        size="16"
                        class="ml-1 mr-1"
                        color="blue"
                    >
                        mdi-check-circle
                    </v-icon>

                    <!-- 댓글 작성일 -->
                    <p class="mb-0 mr-1" style="font-size:9px; color:#ADAFCA; line-height:20px;">
                        {{new Date(item.created).toLocaleString()}}
                    </p>
                </v-sheet>
                <p class="text-caption ml-2 my-2 mt-6 grey--text">삭제된 코멘트입니다</p>

                <!-- 대댓글 목록 -->
                <SubCommentList
                    v-if="item.sub_comment_count"
                    :comment_id="item.id"
                    :writer="writer"
                    :key="componentKey"
                />
            </v-sheet>

            <!-- 공개 -->
            <v-sheet v-else>
                <!-- 상단 -->
                <v-sheet
                    class="d-flex align-center"
                    :class="$vuetify.breakpoint.xs? 'mb-0' : 'mb-2'"
                >
                    <!-- 프로필 -->
                    <Profile :user="item.user_id"/>

                    <!-- 닉네임 -->
                    <p
                        class="mx-2 my-0 font-weight-bold"
                        style="font-size:13px; line-height:18px !important;"
                    >
                        {{item.nickname}}
                    </p>

                    <!-- 작성자일 시 -->
                    <v-icon
                        v-if="item.user_id == writer"
                        size="16"
                        class="ml-1 mr-1"
                        color="blue"
                    >
                        mdi-check-circle
                    </v-icon>

                    <!-- 댓글 작성일 -->
                    <p class="mb-0 mr-1" style="font-size:9px; color:#ADAFCA; line-height:20px;">
                        {{new Date(item.created).toLocaleString()}}
                    </p>

                    <v-spacer></v-spacer>

                    <!-- 댓글 삭제하기 -->
                    <v-sheet
                        v-if="item.user_id==$store.state.user_id || $store.state.type=='관리자'"
                        title="댓글 삭제하기"
                        width="14"
                        height="14"
                        class="ml-1"
                        style="cursor:pointer;"
                        @click="delete_comment(item)"
                    >
                        <v-img contain src="@/assets/board/delete.svg"></v-img>
                    </v-sheet>

                    <!-- 댓글 수정하기 -->
                    <v-sheet
                        v-if="item.user_id==$store.state.user_id"
                        title="댓글 수정하기"
                        width="14"
                        height="14"
                        class="ml-1"
                        style="cursor:pointer;"
                        @click="item.isUpdating = !item.isUpdating"
                    >
                        <v-img contain src="@/assets/board/update.svg"></v-img>
                    </v-sheet>

                    <!-- 블라인드 처리 -->
                    <v-sheet
                        v-if="$store.state.type=='관리자'"
                        title="댓글 블라인드"
                        width="14"
                        height="14"
                        class="ml-1"
                        style="cursor:pointer;"
                        @click="blind_comment(item)"
                    >
                        <v-img contain src="@/assets/board/blind.svg"></v-img>
                    </v-sheet>

                    <!-- 댓글 신고하기 -->
                    <v-icon
                        v-if="item.user_id!=$store.state.user_id"
                        v-ripple="false"
                        title="댓글 신고하기"
                        size="16"
                        class="ml-1"
                        color="red"
                        style="background-color:none; cursor:pointer;"
                        @click="report_comment_open(item)"
                    >
                        mdi-alert-circle
                    </v-icon>
                </v-sheet>

                <!-- 댓글 내용 -->
                <div
                    v-if="$store.state.is_logined && !item.isUpdating"
                    class="pa-3"
                    style="white-space:pre-line; font-size:13px;"
                    v-html="$linkify(item.content)"
                ></div>

                <!-- 수정 시 -->
                <div class="mt-4">
                    <!-- 내용 -->
                    <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1" style="min-height:56px;">
                        <v-textarea
                            hide-details
                            flat
                            solo
                            auto-grow
                            v-model="item.content"
                            style="font-size:13px;"
                            placeholder="평가 내용을 입력해주세요"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 수정, 취소 버튼 -->
                    <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                        <v-btn
                            class="rounded-10"
                            width="90"
                            height="35"
                            dark
                            depressed
                            color="primary"
                            @click="comment_update_submit(item)"
                        >
                            수정
                        </v-btn>
                        <v-btn
                            class="rounded-10 ml-2"
                            width="90"
                            height="35"
                            dark
                            depressed
                            color="primary"
                            @click="item.isUpdating = false; load()"
                        >
                            취소
                        </v-btn>
                    </div>
                </div>

                <!-- 좋아요, 싫어요 -->
                <v-sheet
                    class="d-flex mr-2px"
                >
                    <v-btn
                        v-if="false"
                        icon
                        style="font-size:10px;"
                        :v-ripple="false"
                        @click="item.isSubWriting = !item.isSubWriting"
                    >
                        답글
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- 좋아요 -->
                    <v-btn icon @click="comment_like(item)">
                        <v-img width="14" height="14" contain src="@/assets/board/like.png"></v-img>
                        <span style="font-size:8px;">
                            {{item.like_count}}
                        </span>
                    </v-btn>

                    <!-- 싫어요 -->
                    <v-btn icon @click="comment_dislike(item)">
                        <v-img width="14" height="14" contain src="@/assets/board/dislike.png"></v-img>
                        <span style="font-size:8px;">
                            {{item.dislike_count}}
                        </span>
                    </v-btn>
                </v-sheet>
                <v-divider class="grey lighten-3"></v-divider>

                <!-- 대댓글 목록 -->
                <SubCommentList
                    v-if="item.sub_comment_count"
                    :comment_id="item.id"
                    :writer="writer"
                    :key="componentKey"
                />

                <!-- 대댓글 쓰기 -->
                <v-sheet
                    v-if="item.isSubWriting"
                    class="pl-5 d-flex pb-1 rounded-10"
                >
                    <!-- 입력칸 -->
                    <v-sheet outlined class="rounded-5 mr-1 flex-grow-1" style="min-height:56px;">
                        <v-textarea
                            style="font-size:12px; padding:0px;"
                            class="rounded-5"
                            hide-details 
                            flat
                            solo
                            rows="1"
                            auto-grow
                            v-model="subComment_content"
                            :placeholder="ads.text"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 댓글 입력 Submit -->
                    <v-btn
                        width="48"
                        height="56"
                        class="rounded-5"
                        style="font-weight:400;"
                        dark
                        small
                        depressed
                        color="#23D2E2"
                        @click="subComment_write(item)"
                    >
                        <div>
                            댓글<br/>
                            쓰기
                        </div>
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-sheet>

        <v-divider class="grey lighten-3 mx-3"></v-divider>
        
        <!-- 댓글 쓰기 -->
        <v-sheet class="px-3 d-flex pb-4 rounded-10" style="margin-top:-8px;">
            <!-- 입력칸 -->
            <v-sheet outlined class="rounded-5 mr-1 flex-grow-1" style="min-height:56px;">
                <v-textarea
                    style="font-size:12px; padding:0px;"
                    class="rounded-5"
                    hide-details 
                    flat
                    solo
                    rows="1"
                    auto-grow
                    v-model="comment_content"
                ></v-textarea>
            </v-sheet>

            <!-- 댓글 입력 Submit -->
            <v-btn
                width="48"
                height="56"
                class="rounded-5"
                style="font-weight:400;"
                dark
                small
                depressed
                color="#23D2E2"
                @click="comment_write()"
            >
                <div>
                    댓글<br/>
                    쓰기
                </div>
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
import Profile from "@/components/ProfileForBoardMobile"

export default {
    props: ["writer"],

    components: {
        Profile
    },

    data: () => ({
        // 댓글 입력
        content: "",

        // 신고 항목
        select_list: {
            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        // 신고
        report: {
            id: 0,
            target: "",
            writer: "",
            category: "",
            content: ""
        },

        // 신고 dialog
        dialog: {
            report: false
        },

        comment_content: "",
        comment_list: [],
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/trade/post/read/comment/list', {
                params: {
                    post_id:this.$route.query.id
                }
            }).then((res) => {
                this.comment_list = res.data
            })
        },

        // 댓글 달기
        comment_write(){
            if(!this.$store.state.is_logined){
                alert("댓글 달기는 로그인 이후 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 밴 기간동안 댓글 달기가 제한됩니다.')
            }else{
                if(this.comment_content==""){
                    alert("댓글 내용을 입력해주세요")
                }else{
                    // 댓글 입력
                    this.$http.post('/api/trade/post/read/comment/insert', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.user_id,
                            content: this.comment_content
                        }
                    }).then((res) => {
                        this.comment_content = ""

                        // 거래글 작성자에게 댓글 알림
                        this.$http.post('/api/alarm/insert', {
                            params: {
                                user_id: this.writer,
                                sender: this.$store.state.user_id,
                                type: "trade_comment",
                                link: this.$route.fullPath
                            }
                        }).then(res => {
                            console.log(res);
                        })

                        // DB 알림
                        this.$dbAlarm(
                            "카드거래 - 댓글",
                            this.$store.state.nickname,
                            this.$store.state.user_id
                        )

                        this.load()
                    })
                }
            }
        },

        // 댓글 삭제
        delete_comment(item){
            if(confirm("정말 해당 댓글을 삭제하시겠습니까?")){
                this.$http.post('/api/trade/post/read/comment/delete', {
                    params: {
                        id: item.id
                    }
                }).then(() => {
                    alert('댓글이 삭제되었습니다.')

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 수정
        comment_update_submit(item){
            this.$http.post('/api/trade/post/read/comment/update', {
                params: {
                    id :item.id,
                    content :item.content
                }
            }).then(() => {
                // DB 알림
                this.$dbAlarm(
                    "카드거래 - 댓글",
                    this.$store.state.nickname,
                    this.$store.state.user_id
                )

                // 댓글 목록
                this.load()
            })
        },

        // 댓글 블라인드 처리
        blind_comment(item){
            if(confirm("정말 해당 댓글을 블라인드 처리하시겠습니까?")){
                this.$http.post('/api/trade/post/read/comment/blind', {
                    params: {
                        id : item.id
                    }
                }).then(() => {
                    alert('해당 댓글이 블라인드 처리되었습니다')

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 블라인드 해제
        unblind_comment(item){
            if(confirm("정말 해당 댓글을 블라인드 해제하시겠습니까?")){
                this.$http.post('/api/trade/post/read/comment/unblind', {
                    params: {
                        id : item.id
                    }
                }).then(() => {
                    alert('해당 댓글이 블라인드 해제되었습니다')
                    
                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 신고하기 열기
        report_comment_open(item){
            if(!this.$store.state.is_logined){
                alert("신고하기는 로그인 후 이용가능합니다")
            }else {
                this.dialog.report = true
                this.report.id = item.id
                this.report.writer = item.user_id
                this.report.target = item.content
            }
        },

        // 댓글 신고하기 제출
        report_comment_submit(){
            if(this.report.category==""){
                alert("신고 항목을 선택해주세요")
            }else if(this.report.content==""){
                alert("신고 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/user/reported/insert', {
                    params: {
                        reporter_user_id: this.$store.state.user_id,
                        reported_user_id: this.report.writer,
                        type: "trade_comment",
                        reported_id: this.report.id,
                        category: this.report.category,
                        content: this.report.content,
                        link: this.$route.fullPath+"&reported_comment_id="+this.report.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("신고가 접수되었습니다.")
                        this.dialog.report = false
                    }
                })
            }
        },

        // 댓글 추천
        comment_like(item){
            if(!this.$store.state.is_logined){
                alert("댓글 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 댓글 추천이 제한됩니다.')
            }else{
                this.$http.post('/api/trade/post/read/comment/like/list', {
                    params: {
                        comment_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/trade/post/read/comment/like', {
                            params: {
                                comment_id: item.id,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            this.load()
                        })
                    }
                })
            }
        },

        // 댓글 비추천
        comment_dislike(item){
            if(!this.$store.state.is_logined){
                alert("댓글 비추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 댓글 비추천이 제한됩니다.')
            }else{
                this.$http.post('/api/trade/post/read/comment/dislike/list', {
                    params: {
                        comment_id: item.id,
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 비추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/trade/post/read/comment/dislike', {
                            params: {
                                comment_id: item.id,
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            this.load()
                        })
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>