<template>
    <div>
        <!-- 검색 -->
        <v-sheet class="d-flex pa-4 rounded-15">
            <!-- 입력 -->
            <v-text-field
                dense
                outlined
                hide-details
                class="mr-3 rounded-15 font-weight-bold"
                height="49"
                v-model="keyword"
                placeholder="조회하실 판매자의 닉네임을 입력해주세요"
                @keyup.enter="searchLog()"
            >
                <template v-slot:append>
                    <v-icon class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                </template>
            </v-text-field>

            <!-- 버튼 -->
            <v-btn
                depressed
                dark
                style="font-size:16px;"
                class="rounded-10"
                color="#23D2E2"
                width="125"
                height="49"
                @click="searchLog()"
            >
                검색
            </v-btn>
        </v-sheet>

        <!-- 검색 전 -->
        <v-sheet v-if="!isSearch" class="py-14 mt-2 rounded-15">
            <v-img
                width="129"
                height="138"
                src="@/assets/logos/icon_grey.svg"
                class="mx-auto mb-6"
            ></v-img>
            <p class="text-center font-weight-medium" style="font-size:16px;">
                <span>거래 내역을 조회할 유저의 <font class="primary--text">닉네임</font>을 입력해주세요</span>
            </p>
        </v-sheet>
        
        <!-- 검색 후 -->
        <v-sheet v-if="isSearch" class="mt-2 mb-2 rounded-15 pa-4">
            <!-- 검색 결과 없을때 -->
            <v-sheet v-if="summary.user_id == ''" class="py-8">
                <!-- 아이콘 -->
                <v-img
                    width="129"
                    height="138"
                    src="@/assets/logos/icon_grey.svg"
                    class="mx-auto mb-4"
                ></v-img>

                <!-- 문구 -->
                <p class="text-center font-weight-medium" style="font-size:16px;">
                    <span>검색 결과가 없습니다</span><br/>
                    <span>거래 내역을 조회할 유저의 <font class="primary--text">닉네임</font>을 입력해주세요</span>
                </p>
            </v-sheet>

            <!-- 검색 결과 있을때 -->
            <v-sheet v-if="summary.user_id != ''" class="d-flex align-center justify-center" color="transparent">
                <!-- 프로필 -->
                <Avatar :user="summary.user_id" />
                <p class="ml-4 my-0" style="font-weight:bold;">
                    {{summary.nickname}}
                </p>

                <!-- 평점 -->
                <v-sheet class="d-flex align-center ml-4 mb-1 mr-2">
                    <v-rating
                        :value="summary.rating"
                        small
                        readonly
                        color="#615DFA"
                    ></v-rating>
                    <p class="mx-1 text-caption pt-5 font-weight-bold" style="color:#615DFA;">
                        (리뷰 {{summary.review_count}}, 거래완료 {{summary.finish_count}})
                    </p>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        
        <!-- 평가 List -->
        <v-sheet
            v-for="(item, index) in list" :key="index"
            class="px-2 pt-3 pb-5 mb-2 rounded-10"
        >
            <v-sheet class="d-flex align-center mb-1 ml-1">
                <!-- 프로필 -->
                <Avatar :user="item.buyer_user_id" />

                <font class="ml-3 font-weight-bold mr-2" style="font-size:13px; color:#707070;">{{item.buyer_nickname}}</font>
                <font style="font-size:13px; color:#ADAFCA;">({{new Date(item.created).toLocaleString()}})</font>
                <v-rating
                    v-model="item.rating"
                    class="ml-4 pb-1 mb-1px"
                    readonly
                    half-increments
                    background-color="grey"
                    color="#615DFA"
                    small
                ></v-rating>
            </v-sheet>
            <v-sheet class="px-13" style="font-size:13px;">
                {{item.content}}
            </v-sheet>
        </v-sheet>
    </div>
</template>
<script>
import Avatar from "@/components/Avatar"

export default {
    components: {
        Avatar
    },

    data: () => ({
        keyword: "",
        list: [],
        summary: {
            user_id: "",
            rating: 0,
            review_count: 0,
            finish_count: 0
        },

        isSearch: false
    }),

    mounted(){
        // 프로필 > 거래내역 보기
        if(this.$route.query.nickname){
            this.keyword = this.$route.query.nickname
            this.searchLog()
        }
    },

    methods: {
        // 판매자 검색
        async searchLog(){
            this.summary = {
                user_id: "",
                rating: 0,
                count: 0
            }
            this.list = []

            // 상단 요약
            await this.$http.post('/api/trade/review/search/summary', {
                params: {
                    user_id : this.keyword
                }
            }).then(res => {
                if(res.data.length){
                    this.summary = res.data[0]
                }
            })

            // 리뷰 목록
            console.log(this.keyword)
            await this.$http.post('/api/trade/review/search', {
                params: {
                    nickname : this.keyword
                }
            }).then(res => {
                console.log(res)
                this.list = res.data
            })

            this.keyword = ""
            this.isSearch = true
        }
    }
}
</script>
<style scoped>
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}
</style>

<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>