<template>
    <div>
        <!-- 검색 (PC) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-4 pa-4 rounded-10 mb-3"
        >
            <v-sheet class="d-flex px-2">
                <!-- 순서 -->
                <v-select
                    height="49"
                    style="width:175px;"
                    class="mr-2 rounded-15 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    v-model="sequence"
                    :items="select_list.sequence"
                    @change="sequenceUpdated()"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- supertype -->
                <v-select
                    height="49"
                    style="width:175px;"
                    class="mr-2 rounded-15 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    :items="supertype_selectList"
                    item-text="name"
                    item-value="value"
                    v-model="keyword.supertype"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pt-1">{{item.name}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 카드 이름 -->
                <v-text-field
                    height="49"
                    style="width:250px;"
                    class="mr-3 rounded-15 font-weight-bold"
                    hide-details
                    dense
                    outlined
                    placeholder="카드 이름"
                    v-model="keyword.text"
                    @keyup.enter="submit()"
                >
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    width="86"
                    height="49"
                    depressed
                    dark
                    class="rounded-10"
                    color="primary"
                    style="font-size:16px; font-weight:bold;"
                    @click="submit()"
                >
                    검색
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 검색 (Mobile) -->
        <v-sheet
            v-if="$vuetify.breakpoint.mobile"
            class="py-3 px-3 pr-5"
        >
            <div
                class="d-flex mb-2"
            >
                <!-- 순서 -->
                <v-select
                    height="40"
                    class="rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    v-model="sequence"
                    :items="select_list.sequence"
                    @change="sequenceUpdated()"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- supertype -->
                <v-select
                    height="40"
                    class="rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    :items="supertype_selectList"
                    item-text="name"
                    item-value="value"
                    v-model="keyword.supertype"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item.name}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </div>

            <div
                class="d-flex"
            >
                <!-- 카드 이름 -->
                <v-text-field
                    height="40"
                    class="mr-2 rounded-10 font-weight-bold"
                    hide-details
                    dense
                    outlined
                    placeholder="검색할 카드이름을 입력하세요"
                    v-model="keyword.text"
                    append-icon="mdi-magnify"
                    @keyup.enter="submit()"
                ></v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    height="40"
                    depressed
                    dark
                    small
                    class="rounded-10"
                    color="primary"
                    style="font-size:13px; font-weight:bold;"
                    @click="submit()"
                >
                    검색
                </v-btn>
            </div>
        </v-sheet>

        <!-- 목록 (PC) -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <div v-if="list_ready">
                <v-row dense justify="space-around">
                    <v-col
                        :cols="6"
                        v-for="item in currentPageList"
                        :key="item.id"
                        width="350"
                    >
                        <v-sheet
                            class="pa-4 rounded-15"
                            height="220"
                            style="cursor:pointer; border:1px solid white;"
                            @click="searchTradePostByCard(item)"
                        >
                            <v-sheet class="d-flex" height="122" style="position:relative;">
                                <!-- 이미지 -->
                                <v-sheet
                                    min-width="116"
                                    max-width="116"
                                    min-height="116"
                                    max-height="116"
                                    class="rounded-circle grey lighten-4"
                                    style="overflow:hidden; position:relative;"
                                >
                                    <v-img
                                        height="200"
                                        style="position:absolute; bottom:-54px;"
                                        position="center bottom"
                                        :src="view_image(item)"
                                    ></v-img>
                                </v-sheet>

                                <v-sheet class="ml-3 pa-1">
                                    <!-- 카드 이름 -->
                                    <p
                                        class="font-weight-bold text-truncate mb-1"
                                        style="font-size:24px; width:190px;"
                                    >
                                        {{item.card_name}}
                                    </p>

                                    <!-- 발매국가 -->
                                    <p
                                        v-if="item.card_nation"
                                        class="font-weight-bold primary--text ml-2"
                                        style="position:absolute; top:8px; right:12px; font-size:19px;"
                                    >
                                        <span v-if="item.card_nation=='kr'">한글</span>
                                        <span v-if="item.card_nation=='en'">영어</span>
                                        <span v-if="item.card_nation=='jp'">일어</span>
                                    </p>

                                    <p class="mb-0" style="font-size:15px; font-weight:300;"> 
                                        <!-- 레귤레이션 -->
                                        <v-icon v-if="item.regulationMark" style="margin-bottom:2px;">
                                            mdi-alpha-{{item.regulationMark.replaceAll("\"","").toLowerCase()}}-box-outline
                                        </v-icon>

                                        <!-- 블록넘버 / 토탈넘버 -->
                                        <span v-if="item.number && item.set_printedTotal">
                                            {{item.number.toString().padStart(3,'0')}} / {{item.set_printedTotal}}
                                        </span>
                                    </p>
                                    <!-- 희귀도 -->
                                    <p style="width:230px; font-size:15px; font-weight:300;" class="mb-0 text-truncate">
                                        {{item.rarity}}
                                    </p>

                                    <!-- 제품명 -->
                                    <p style="width:230px; font-size:15px; font-weight:300;" class="mb-0 text-truncate">
                                        {{item.set_name}}
                                    </p>
                                </v-sheet>
                            </v-sheet>

                            <v-sheet class="px-3 pt-1 pb-2 mt-2 d-flex justify-space-between">
                                <p class="mb-0" style="font-size:19px; font-weight:400;">
                                    거래 등록 : {{item.registered_count}}건<br/>
                                    거래 완료 : {{item.finished_count}}건
                                </p>
                                <p class="mb-0 font-weight-bold text-right" style="font-size:19px;">
                                    <font style="color:#615DFA">최저가 {{$toComma(item.min_price)}}원</font><br/>
                                    <font style="color:#F8468D">최고가 {{$toComma(item.max_price)}}원</font>
                                </p>
                            </v-sheet>
                        </v-sheet>
                        <!-- {{item.card_id}} -->
                    </v-col>
                </v-row>
            </div>

            <!-- 로딩 -->
            <v-sheet v-if="!list_ready">
                <v-sheet height="180" class="d-flex justify-center align-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-sheet>
            </v-sheet>

            <!-- 페이지네이션 -->
            <v-sheet class="d-flex justify-center rounded-10 align-center mt-2 py-1">
                <v-pagination
                    class="elevation-0"
                    color="primary"
                    v-model="page"
                    :length="pageLength"
                    total-visible="10"
                ></v-pagination>
            </v-sheet>
        </div>

        <!-- 목록 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2"
        >
            <div v-if="list_ready">
                <v-row dense justify="space-around">
                    <v-col
                        :cols="12"
                        v-for="item in currentPageList"
                        :key="item.id"
                        width="350"
                        class="px-6 py-2"
                    >
                        <v-sheet
                            class="pa-4 rounded-15"
                            height="180"
                            style="cursor:pointer; border:1px solid white;"
                            @click="searchTradePostByCard(item)"
                        >
                            <v-sheet class="d-flex" height="100" style="position:relative;">
                                <!-- 이미지 -->
                                <v-sheet
                                    min-width="88"
                                    max-width="88"
                                    min-height="88"
                                    max-height="88"
                                    class="rounded-circle grey lighten-4"
                                    style="overflow:hidden; position:relative;"
                                >
                                    <v-img
                                        height="160"
                                        style="position:absolute; bottom:-54px;"
                                        position="center bottom"
                                        :src="view_image(item)"
                                    ></v-img>
                                </v-sheet>

                                <v-sheet class="ml-3 pa-1">
                                    <!-- 카드 이름 -->
                                    <p
                                        class="font-weight-bold text-truncate mb-1"
                                        style="font-size:18px; width:100px;"
                                    >
                                        {{item.card_name}}
                                    </p>

                                    <!-- 발매국가 -->
                                    <p
                                        v-if="item.card_nation"
                                        class="font-weight-bold ml-2"
                                        style="position:absolute; top:8px; right:12px; font-size:14px;"
                                    >
                                        <span v-if="item.card_nation=='kr'" class="primary--text">한글</span>
                                        <span v-if="item.card_nation=='en'" class="primary--text">영어</span>
                                        <span v-if="item.card_nation=='jp'" class="primary--text">일어</span>
                                    </p>

                                    <p class="mb-0" style="font-size:11px; font-weight:300;"> 
                                        <!-- 레귤레이션 -->
                                        <v-icon v-if="item.regulationMark" style="margin-bottom:2px;" size="14">
                                            mdi-alpha-{{item.regulationMark.replaceAll("\"","").toLowerCase()}}-box-outline
                                        </v-icon>

                                        <!-- 블록넘버 / 토탈넘버 -->
                                        <span v-if="item.number && item.set_printedTotal">
                                            {{item.number.toString().padStart(3,'0')}} / {{item.set_printedTotal}}
                                        </span>
                                    </p>
                                    <!-- 희귀도 -->
                                    <p style="width:160px; font-size:11px; font-weight:300;" class="mb-0 text-truncate">
                                        {{item.rarity}}
                                    </p>

                                    <!-- 제품명 -->
                                    <p style="width:160px; font-size:11px; font-weight:300;" class="mb-0 text-truncate">
                                        {{item.set_name}}
                                    </p>
                                </v-sheet>
                            </v-sheet>

                            <v-sheet class="pl-2 pr-3 pb-2 d-flex justify-space-between">
                                <p class="mb-0" style="font-size:14px; font-weight:400;">
                                    거래 등록 : {{item.registered_count}}건<br/>
                                    거래 완료 : {{item.finished_count}}건
                                </p>
                                <p class="mb-0 font-weight-bold text-right" style="font-size:14px;">
                                    <font style="color:#615DFA">최저가 {{$toComma(item.min_price)}}원</font><br/>
                                    <font style="color:#F8468D">최고가 {{$toComma(item.max_price)}}원</font>
                                </p>
                            </v-sheet>
                        </v-sheet>
                        <!-- {{item.card_id}} -->
                    </v-col>
                </v-row>
            </div>

            <!-- 로딩 -->
            <v-sheet v-if="!list_ready">
                <v-sheet height="180" class="d-flex justify-center align-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-sheet>
            </v-sheet>

            <!-- 페이지네이션 -->
            <v-sheet class="d-flex justify-center rounded-10 align-center mt-2 py-1">
                <v-pagination
                    class="elevation-0"
                    color="primary"
                    v-model="page"
                    :length="pageLength"
                    total-visible="10"
                ></v-pagination>
            </v-sheet>
        </div>

        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <BannerView
                v-if="$store.state.ads_banner.card_trade"
                class="mt-3"
                type="card_trade"
                width="100%"
                :height="46/360*100+'vw'"
                :round="'rounded-0'"
            />
        </div>
    </div>
</template>
<script>
import BannerView from '@/components/BannerView'

export default {
    components: {
        BannerView
    },
    props: [ "isWrite" ],

    data: () => ({
        // 타입이름
        type: [
            "Colorless",
            "Darkness",
            "Dragon",
            "Fairy",
            "Fighting",
            "Fire",
            "Grass",
            "Lightning",
            "Metal",
            "Psychic",
            "Water"
        ],

        // 슈퍼타입 선택목록
        supertype_selectList: [
            { name: '모든 카드',  value: 'ALL' },
            { name: '에너지',  value: 'Energy' },
            { name: '포켓몬', value: 'Pokémon' },
            { name: '트레이너스', value: 'Trainer' }
        ],

        // 검색조건
        keyword: {
            text: "",
            set_nation: "한글 제품명",
            set_name: "",
            rarity_nation:"한글 희귀도",
            rarity:"",
            abilities_name: "",
            abilities_text: "",
            attacks_name: "",
            attacks_cost: [],
            attacks_text: "",
            artist: "",
            supertype: "ALL",
            number: [],
            subtype: [],
            type: [],
            weaknesses: {
                type: []
            },
            resistances: {
                type: []
            },
            attacks: {
                cost: [],
            },
            hp: [0,340],
            retreatCostLength: [0,5],
        },
        
        // 상세조건 스위치
        switchDetail: true,

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        isKorean: true,
        isJapanese: false,
        isEnglish: false,

        sequence: "거래 완료순",

        select_list: {
            sequence: ["거래 완료순", "최신순", "오래된순", "높은 가격순", "낮은 가격순"]
        },

        isSearch: false
    }),

    mounted(){
        this.sequenceUpdated()
    },

    watch: {
        page(newVal) {
            this.$router.replace({ query: { ...this.$route.query, page: newVal } })
            this.currentPageList = this.list.slice((this.page-1)*8,(this.page)*8)
            window.scrollTo(0, 0)
        }
    },

    methods: {
        sequenceUpdated(){
            if(!this.isSearch){
                if(this.sequence == '높은 가격순'){
                    this.$http.post('/api/trade/card/list/price/desc', {
                        params: {
                            keyword: this.keyword
                        }
                    }).then((res) => {
                        this.list = res.data
                        this.currentPageList = res.data.slice(0,8)
                        this.pageLength=Math.ceil(res.data.length/8)
                        this.list_ready=true
                    })
                }else if(this.sequence == '낮은 가격순'){
                    this.$http.post('/api/trade/card/list/price/asc', {
                        params: {
                            keyword: this.keyword
                        }
                    }).then((res) => {
                        this.list = res.data
                        this.currentPageList = res.data.slice(0,8)
                        this.pageLength=Math.ceil(res.data.length/8)
                        this.list_ready=true
                    })
                }else if(this.sequence == '최신순'){
                    this.$http.post('/api/trade/card/list', {
                        params: {
                            keyword: this.keyword
                        }
                    }).then((res) => {
                        this.list = res.data.reverse()
                        this.currentPageList = res.data.slice(0,8)
                        this.pageLength=Math.ceil(res.data.length/8)
                        this.list_ready=true
                    })
                }else if(this.sequence == '오래된순'){
                    this.$http.post('/api/trade/card/list', {
                        params: {
                            keyword: this.keyword
                        }
                    }).then((res) => {
                        this.list = res.data
                        this.currentPageList = res.data.slice(0,8)
                        this.pageLength=Math.ceil(res.data.length/8)
                        this.list_ready=true
                    })
                }else if(this.sequence == '거래 완료순'){
                    this.$http.post('/api/trade/card/list/price/asc', {
                        params: {
                            keyword: this.keyword
                        }
                    }).then((res) => {
                        this.list = res.data.sort((a, b) => b.finished_count - a.finished_count)
                        this.currentPageList = res.data.slice(0,8)
                        this.pageLength=Math.ceil(res.data.length/8)
                        this.list_ready=true
                    })
                }
            }else{
                this.submit()
            }
        },

        toThousand(value) {
            return (value/1000).toFixed(1)
        },

        // 해당 카드를 팔고 있는 거래글 목록으로 이동
        searchTradePostByCard(item){
            this.$router.push('/trade/post/list?card_id='+item.card_id+'&card_nation='+item.card_nation)
        },

        // 검색조건 초기화
        keywordReset(){
            Object.assign(this.$data.keyword, this.$options.data().keyword)
        },

        // 블록넘버 전체선택&초기화
        blockNumber_all_icon(){
            if(this.keyword.number.length!=0){
                this.keyword.number = []
            }else{
                this.keyword.number = ['A', 'B', 'C', 'D', 'E', 'F', 'G','H','I']
            }
        },

        // 이미지 표시
        view_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },

        // 언어 확인
        nation_check(){
            const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
            // 한글 여부
            let kr_name = korean.test(this.keyword.text)
            let kr_attacks_name = korean.test(this.keyword.attacks_name)
            let kr_set = this.keyword.set_nation=='한글 제품명' && this.keyword.set_name!=''
            let kr_rarity = this.keyword.rarity_nation=='한글 희귀도' && this.keyword.rarity!=''
            this.isKorean = kr_name || kr_attacks_name || kr_set || kr_rarity
            // console.log("한글 여부: "+this.isKorean)

            const REGEX_JAPANESE = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;
            const hasJapanese = (str) => REGEX_JAPANESE.test(str);
            const Japanese = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;
            let jp_name = Japanese.test(this.keyword.text)
            let jp_attacks_name = Japanese.test(this.keyword.attacks_name)
            let jp_set = this.keyword.set_nation=='일본 제품명' && this.keyword.set_name!=''
            let jp_rarity = this.keyword.rarity_nation=='일본 희귀도' && this.keyword.rarity!=''
            this.isJapanese = jp_name || jp_attacks_name || jp_set || jp_rarity
            // console.log("일본 여부: "+this.isJapanese)

            this.isEnglish = !(this.isKorean || this.isJapanese)
        },

        // 검색
        submit(){
            this.isKorean = false
            this.isJapanese = false
            this.nation_check()

            // 페이징
            if (this.$route.query.page) {
                this.page = parseInt(this.$route.query.page)
            }

            if(this.isEnglish){
                this.list_ready = false
                this.$http.post('/api/trade/card/list/search', {
                    params: {
                        keyword: this.keyword,
                        card_nation: "en",
                        sequence: this.sequence
                    }
                }).then((res) => {
                    if(this.sequence == '오래된순'){
                        this.list = res.data
                    }else if(this.sequence == '최신순'){
                        this.list = res.data.reverse()
                    }

                    this.currentPageList = this.list.slice((this.page-1)*8,(this.page)*8)
                    this.pageLength = Math.ceil(res.data.length/8)
                    this.list_ready = true
                    this.isSearch = true
                })
            }else if(this.isKorean){
                this.list_ready = false
                this.$http.post('/api/trade/card/list/search', {
                    params: {
                        keyword: this.keyword,
                        card_nation: "kr",
                        sequence: this.sequence
                    }
                }).then((res) => {
                    if(this.sequence == '오래된순'){
                        this.list = res.data
                    }else if(this.sequence == '최신순'){
                        this.list = res.data.reverse()
                    }

                    this.list = res.data
                    this.currentPageList = this.list.slice((this.page-1)*8,(this.page)*8)
                    this.pageLength = Math.ceil(res.data.length/8)
                    this.list_ready = true
                    this.isSearch = true 
                })
            }else if(this.isJapanese){
                this.list_ready = false
                this.$http.post('/api/trade/card/list/search', {
                    params: {
                        keyword: this.keyword,
                        card_nation: "jp",
                        sequence: this.sequence
                    }
                }).then((res) => {
                    if(this.sequence == '오래된순'){
                        this.list = res.data
                    }else if(this.sequence == '최신순'){
                        this.list = res.data.reverse()
                    }

                    this.list = res.data
                    this.currentPageList = this.list.slice((this.page-1)*8,(this.page)*8)
                    this.pageLength = Math.ceil(res.data.length/8)
                    this.list_ready = true
                    this.isSearch = true
                })
            }
        }
    }
}
</script>
<style scoped>
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style scoped>
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 13px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 13px;
}

/* 성공 통합본? */
::v-deep .v-text-field {
    margin-left:8px;
    font-size: 13px;
}
</style>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>