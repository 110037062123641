<template>
    <div>
        <!-- 검색버튼 (Header 대용) -->
        <div
            style="position:absolute; top:12px; right:58px;"
        >
            <v-btn
                width="34"
                height="28"
                style="font-size:10px; letter-spacing:-1px; min-width:0 !important;"
                depressed
                class="rounded-10 px-0"
                dark
                x-small
                v-ripple="false"
                color="primary"
                @click="search_switch = !search_switch"
            >
                <v-icon small>mdi-magnify</v-icon>
            </v-btn>
        </div>

        <!-- 상세조건 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile && search_switch"
            class="pt-8 px-3 ma-2 mobile"
            style="position:relative; border:2px solid #f8468d;"
        >
            <v-icon style="position:absolute; top:8px; right:8px;" size="20" color="#F8468D" @click="search_switch = false">mdi-close</v-icon>
            <!-- 키워드 검색 -->
            <v-sheet
                class="rounded-10 pa-5 mobile"
            >
                <p>
                    <v-icon size="8" color="#F8468D" class="pb-1 mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">키워드 검색</span>
                </p>

                <!-- 검색 항목 -->
                <v-select
                    height="34"
                    class="rounded-10 mb-1"
                    label="검색 항목"
                    dense
                    outlined
                    hide-details
                    :items="select_list.text_type"
                    item-text="name"
                    item-value="value"
                    v-model="keyword.text_type"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- TEXT -->
                <v-text-field
                    height="34"
                    class="rounded-10"
                    outlined
                    hide-details
                    dense
                    placeholder="검색어를 입력하세요"
                    persistent-placeholder
                    v-model="keyword.text"
                    append-icon="mdi-magnify"
                    @keyup.enter="searchPost()"
                >
                    <template v-slot:append>
                        <v-icon size="20" class="mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </v-sheet>

            <!-- 세부 검색 -->
            <v-sheet
                class="rounded-10 pa-5 mt-2 mobile"
            >
                <p>
                    <v-icon size="8" color="#F8468D" class="pb-1 mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">세부 검색</span>
                </p>

                <!-- 상품유형 -->
                <v-select
                    height="34"
                    class="rounded-10 mb-1"
                    label="상품유형"
                    dense
                    outlined
                    multiple
                    hide-details
                    :items="select_list.product_type"
                    v-model="keyword.product_type"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 거래방법 -->
                <v-select
                    height="34"
                    class="rounded-10 mb-1"
                    label="거래방법"
                    dense
                    outlined
                    multiple
                    hide-details
                    :items="select_list.trade_way"
                    v-model="keyword.trade_way"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 카드상태 -->
                <v-select
                    height="34"
                    class="rounded-10 mb-1"
                    label="카드상태"
                    dense
                    outlined
                    multiple
                    hide-details
                    :items="select_list.trade_state"
                    v-model="keyword.trade_state"
                    :menu-props="{ offsetY: true }"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" size="20" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-sheet>

            <div
                class="d-flex justify-center mt-4 mb-5"
            >
                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    x-small
                    class="mr-2 rounded-10"
                    style="font-size:13px; font-weight:bold"
                    color="primary"
                    width="78"
                    height="34"
                    @click="searchPost(); search_switch = false;"
                >
                    검색
                </v-btn>

                <!-- 초기화 -->
                <v-btn
                    depressed
                    dark
                    x-small
                    class="rounded-10"
                    style="font-size:13px; font-weight:bold"
                    color="#F8468D"
                    width="78"
                    height="34"
                    @click="keywordReset()"
                >
                    초기화
                </v-btn>
            </div>
        </div>

        <!-- 목록 -->
        <v-sheet class="py-2 mt-2">
            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mx-auto my-10"
                size="80"
            ></v-progress-circular>

            <v-data-table
                v-if="!loading"
                :items="list"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                hide-default-header
                hide-default-footer
                no-data-text="거래글이 존재하지 않습니다."
            >
                <template v-slot:item="{ item }">
                    <tr 
                        class="pa-0 px-1 ma-0 mb-1" 
                        style="cursor:pointer; border-bottom:1px solid #e0e0e0; position:relative;" 
                        @click="read(item)"
                    >
                        <td style="border:none;">
                            <!-- 거래유형 & 제목 -->
                            <p class="mt-1 mb-1" style="font-size:13px; max-height:34px; width:100%; overflow:hidden; word-wrap:break-word">
                                <font class="mr-2 icu_purple--text">{{item.trade_type}}</font>
                                <font class="font-weight-medium">{{item.title}}</font>
                            </p>

                            <!-- 등급, 카드 수, 총 가격 -->
                            <p v-if="item.count" class="mb-0" style="font-size:12px;">
                                <span style="font-weight:500">{{item.card_state}}</span>
                                <span style="font-size:11px;" class="ml-2">{{item.count}}개 카드</span>
                                <span style="font-size:11px;" class="ml-2">{{$toComma(item.total_price)}}원</span>
                            </p>

                            <p v-if="item.etc_count" class="mb-0" style="font-size:12px;">
                                <span style="font-weight:500">{{item.etc_state}}</span>
                                <span style="font-size:11px;" class="ml-2">{{item.etc_count}}개 서플라이</span>
                                <span style="font-size:11px;" class="ml-2">{{$toComma(item.etc_total_price)}}원</span>
                            </p>

                            <p v-if="item.deck_count" class="mb-0" style="font-size:12px;">
                                <span style="font-weight:500">{{item.deck_state}}</span>
                                <span style="font-size:11px;" class="ml-2">{{item.deck_count}}개 덱</span>
                                <span style="font-size:11px;" class="ml-2">{{$toComma(item.deck_total_price)}}원</span>
                            </p>

                            <!-- 거래방법, 등록일자 -->
                            <p class="mb-0 text-caption">
                                <span style="font-weight:500">{{item.trade_way}}</span>
                                <span style="font-size:8px;" class="ml-1 grey--text">{{new Date(item.created).toLocaleString()}}</span>
                            </p>

                            <v-sheet
                                color="transparent"
                                style="position:absolute; bottom:2px; right:22px;"
                            >
                                <div class="d-flex justify-end mr-2px">
                                    <Profile v-if="item.user_id" :user="item.user_id" :color="'black'"/>
                                </div>
                                <!-- 매너점수 -->
                                <div class="d-flex align-center" style="margin-top:-4px;">
                                    <!-- 리뷰 평균평점 -->
                                    <v-rating
                                        :value="item.review_rating"
                                        x-small
                                        readonly
                                        background-color="primary"
                                    ></v-rating>

                                    <!-- 리뷰 수, 거래완료 수 -->
                                    <p class="primary--text ml-1 my-0 pt-3px" style="font-size:9px; letter-spacing:1px;">
                                        ({{item.review_count}}/{{item.finish_count}})
                                    </p>
                                </div>
                            </v-sheet>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- 버튼 & 페이지네이션 -->
            <v-sheet class="mt-3 d-flex align-center justify-center">
                <!-- 페이징 -->
                <v-pagination
                    color="primary"
                    v-model="page"
                    :length="pageCount"
                    :total-visible="5"
                ></v-pagination>
            </v-sheet>
        </v-sheet>

        <BannerView
            v-if="$store.state.ads_banner.card_trade"
            class="mt-3"
            type="card_trade"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />

        <!-- 로딩 -->
        <!-- <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 30%; left: 50%; transform: translate(-50%, -50%);"
            indeterminate
            color="primary"
            size="80"
        ></v-progress-circular> -->
    </div>
</template>
<script>
import Profile from "@/components/Profile"
import BannerView from '@/components/BannerView'

export default {
    components: {
        Profile,
        BannerView
    },
    
    data: () => ({
        // 선택 목록
        select_list: {
            product_type: ["싱글카드", "서플라이", "덱"],
            trade_type: ["시세등록", "팝니다", "삽니다"],
            trade_way: ["등기(준등기)", "택배", "직거래", "기타"],
            trade_state: ["기타", "S급", "A급", "B급", "C급 이하", "등급", "서플라이 제품"],
            text_type: ["제목", "내용", "판매자", "카드이름"]
        },

        // 키워드
        keyword: {
            product_type: [],
            trade_type: [],
            trade_way: [],
            trade_state: [],
            text_type: "제목",
            text: ""
        },

        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,

        // 내 배송지
        myAddressDefault: [],
        loading: false,

        search_switch: false
    }),

    watch: {
        page(newVal) {
            this.$router.replace({ query: { ...this.$route.query, page: newVal } })
            window.scrollTo(0, 0)
        },
    },

    mounted() {
        // 목록
        this.load()

        // 내 배송지가 있는지
        this.isMyAddressExist()

        // 내 거래글
        if(this.$route.query.my){
            this.searchMyPost()
        }
    },

    methods: {
        // 목록
        load(){
            this.loading = true
            // 카드별 > 카드 목록
            if(this.$route.query.card_id && this.$route.query.card_nation){
                this.$http.post('/api/trade/post/list/searchByCard', {
                    params: {
                        card_id: this.$route.query.card_id,
                        card_nation: this.$route.query.card_nation
                    }
                }).then(res => {
                    this.list = Array.from(new Set(res.data))

                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }
                    this.loading = false
                })
            // 기본 목록
            }else{
                this.$http.post('/api/trade/post/list')
                .then(res => {
                    this.list = res.data

                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }
                    this.loading = false
                })
            }
        },

        // 내 배송지가 있는지
        isMyAddressExist(){
            this.$http.post('/api/shipping/select', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                this.myAddressDefault = res.data.filter(e => e.selected == "기본")
            })
        },

        // 검색
        searchPost(){
            this.loading = true
            console.log(this.search_switch)
            this.search_switch = true
            console.log(this.search_switch)
            this.loading = true
            this.$http.post('/api/trade/post/list/search', {
                params: {
                    keyword: this.keyword
                }
            }).then(res => {
                this.loading = false
                this.list = res.data
                this.loading = false
            })
        },

        // 검색조건 초기화
        keywordReset(){
            this.loading = true
            Object.assign(this.$data.keyword, this.$options.data().keyword)

            this.$http.post('/api/trade/post/list')
            .then(res => {
                this.list = res.data
                this.loading = false
            })
        },

        // 내 거래글
        searchMyPost(){
            this.loading = true
            if(!this.$store.state.is_logined){
                alert("로그인 후 이용 가능합니다.")
                this.loading = false
            }else{
                this.$http.post('/api/trade/post/list/searchMyPost', {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    let check = this.$store.state.type=='관리자'? res.data.length-1 : res.data.length // root 관리자 입력 예외처리
                    if(check){
                        this.list = res.data
                    }else{
                        alert("작성하신 거래글이 없습니다.")
                    }
                    this.loading = false
                })
            }
        },

        // 거래중
        searchMyTradePost(){
            this.loading = true
            if(!this.$store.state.is_logined){
                alert("로그인 후 이용 가능합니다.")
                this.loading = false
            }else{
                this.$http.post('/api/trade/post/list/searchMyTradePost', {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    if(!res.data.length){
                        alert("거래중인 거래글이 없습니다.")
                    }else{
                        this.list = res.data
                    }
                    this.loading = false
                })
            }
        },

        // 거래글 등록
        write(){
            if(!this.$store.state.is_logined){
                alert("글쓰기는 로그인 후에만 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 밴 기간동안 글쓰기가 제한됩니다.')
            }else{
                if(!this.myAddressDefault.length) {
                    if(confirm("판매글 작성시 기본 배송지가 설정되어 있어야합니다.\n확인을 눌러 배송지관리에서 기존 배송지를 추가해주세요.")){
                        this.$router.push("/mypage/address")
                    }
                }else{
                    this.$router.push("/trade/post/write")
                }
            }
        },

        // 읽기
        read(item){
            this.$router.push("/trade/post/read?id="+item.id)
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:0px;
    font-size: 13px;
}

::v-deep .v-select input {
    margin-left:0px;
    font-size: 13px;
}

/* 페이징 */
::v-deep .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>

<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

::v-deep .pc .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .pc .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}
</style>
<style scoped>
/* 페이징 */
::v-deep .mobile .v-pagination__item {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 active */
::v-deep .mobile .v-pagination__item--active {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

/* 페이징 arrow */
::v-deep .mobile .v-pagination__navigation {
    width: 32px;
    height: 34px;
    margin: 3px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

/* 페이징 icon */
::v-deep .mobile .v-pagination__navigation .v-icon{
    color:white;
}


/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 33px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .mobile .v-input--switch .v-label {
    font-size:11px !important;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 33px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 33px;
    min-height: 33px;
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 33px !important;
    height: 33px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 33px;
    margin-left: 12px;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

</style>