<template>
    <div
        class="pa-4 pt-0 rounded-10"
        style="position:relative;"
    >
        <v-icon
            style="position:absolute; top:-33px; right:180px;"
            size="24"
            color="primary"
            @click="dialog.notice = true"
        >
            mdi-information-outline
        </v-icon>

        <!-- 간편모드 -->
        <v-switch
            style="position:absolute; top:-52px; right:20px;"
            v-model="write.is_simple"
            label="간편모드"
            inset
            dense
            color="primary"
        >
            <template v-slot:label>
                <span style="font-size:12px;">간편모드</span>
                <span style="color:#0086FF; font-weight:medium; font-size:12px;" class="ml-6px">AI</span>
                <v-img class="ml-2" height="20" max-width="30" contain src="@/assets/ai.svg" />
            </template>
        </v-switch>

        <!-- 상단 -->
        <v-text-field
            class="rounded-10 font-weight-bold white"
            height="48"
            hide-details
            dense
            placeholder="제목을 입력해주세요"
            outlined
            v-model="write.title"
        ></v-text-field>

        <div class="d-flex justify-space-between mt-2">
            <v-select
                class="rounded-10 font-weight-bold shrink white"
                style="width:33%;"
                height="48"
                hide-details
                dense
                outlined
                placeholder="거래유형"
                :items="select_list.trade_type"
                v-model="write.trade_type"
            ></v-select>
            <v-select
                class="rounded-10 font-weight-bold shrink white mx-2"
                style="width:33%;"
                height="48"
                hide-details
                dense
                outlined
                placeholder="거래방법"
                :items="select_list.trade_way"
                v-model="write.trade_way"
            ></v-select>
            <v-text-field
                class="rounded-10 font-weight-bold shrink white"
                style="width:33%;"
                height="48"
                dense
                hide-details
                placeholder="배송비"
                suffix="원"
                outlined
                v-model="write.delivery_price"
                @input="e => write.delivery_price = $inputToComma(e)"
            ></v-text-field>
        </div>

        <v-select
            class="rounded-10 font-weight-bold mt-2 white"
            height="48"
            hide-details
            dense
            outlined
            placeholder="판매자 주소"
            :items="select_list.seller_address"
            v-model="write.seller_address"
        ></v-select>

        <!-- 본문 -->
        <TipTapWriterMobile 
            class="tiptap_style mt-2"
            :options="options"
            @emit_count="import_content_count"
            :key="tiptapKey"
        />

        <!-- 저작권법 안내문구 -->
        <v-sheet
            height="24"
            color="#F8468D"
            class="rounded-lg pt-1 mt-3"
        >
            <p
                class="white--text text-center mb-0 pt-2px"
                style="font-size:10px; letter-spacing:-0.5px;"
            >
                타인이 촬영한 사진을 무단으로 사용시 저작권법 위반으로 처벌 될 수 있습니다.
            </p>
        </v-sheet>

        <!-- 일반모드 -->
        <div
            v-if="!write.is_simple"
        >
            <!-- 싱글카드 -->
            <p class="mt-4 mb-0 ml-1 text-subtitle-1 font-weight-medium">
                <v-icon
                    class="mb-2px"
                    color="primary"
                    small
                >
                    mdi-package-variant
                </v-icon>
                싱글카드
            </p>
            <div
                v-for="(item, index) in card_list"
                :key="'card_'+index"
                class="mt-2"
            >
                <v-divider class="my-2" v-if="index != 0"></v-divider>
                <div class="d-flex mb-2">
                    <!-- 카드 ID -->
                    <v-text-field
                        v-model="item.card_view_name"
                        class="rounded-10 white mr-2"
                        style="width:64%;"
                        height="48"
                        hide-details
                        dense
                        placeholder="카드 선택"
                        outlined
                        readonly
                        @click="searchCard(index)"
                    ></v-text-field>

                    <!-- 카드가격 -->
                    <v-text-field
                        v-model="item.card_price"
                        class="rounded-10 white mr-2"
                        style="width:52%;"
                        height="48"
                        hide-details
                        dense
                        placeholder="카드가격"
                        outlined
                        reverse
                        prefix="원"
                        @input="e => item.card_price = $inputToComma(e)"
                    ></v-text-field>

                    <!-- 카드 추가/삭제 버튼 -->
                    <v-sheet>
                        <v-btn
                            v-if="!index"
                            height="48"
                            class="rounded-10"
                            block
                            color="primary"
                            dark
                            depressed
                            @click="addField()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="index"
                            height="48"
                            class="rounded-10"
                            block
                            color="primary"
                            dark
                            depressed
                            @click="subtractField(index)"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </v-sheet>
                </div>
                <div class="d-flex">
                    <!-- 상태/등급 -->
                    <v-select
                        v-model="item.card_state"
                        class="rounded-10 mr-2 shrink white"
                        style="width:96px;"
                        height="48"
                        hide-details
                        dense
                        outlined
                        placeholder="상태/등급"
                        :items="select_list.trade_card_state"
                        @change="card_state_changed(item)"
                    >
                        <template v-slot:append>
                            <v-icon class="pt-2px ml-0" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 상세등급 -->
                    <v-select
                        :disabled="item.card_state != 'PSA 등급' && item.card_state != '기타'"
                        :class="(item.card_state != 'PSA 등급' && item.card_state != '기타')? 'grey lighten-5':'white'"
                        v-model="item.card_state2"
                        class="rounded-10 mr-2 shrink"
                        style="width:96px;"
                        height="48"
                        hide-details
                        dense
                        outlined
                        placeholder="상세등급"
                        :items="select_list.trade_card_state2"
                    >
                        <template v-slot:append>
                            <v-icon class="pt-2px ml-0" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 이미지 -->
                    <!-- <v-btn 
                        style="width:35%;"
                        height="48"
                        class="rounded-10 mr-2"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="copyField(index)"
                    >
                        이미지 ({{item.add_image? '1':'0'}})
                    </v-btn> -->

                    <v-file-input
                        height="48"
                        class="rounded-10 mr-2 shrink"
                        style="cursor:pointer; width:60px; overflow:hidden; border:1px solid #23D2E2;"
                        dense
                        solo
                        flat
                        hide-details
                        prepend-icon
                        v-model="item.add_image"
                        label="사진"
                    ></v-file-input>

                    <!-- 카드 복사 버튼 -->
                    <v-sheet>
                        <v-btn 
                            height="48"
                            class="rounded-10"
                            block
                            color="primary"
                            dark
                            depressed
                            @click="copyField(index)"
                        >
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                    </v-sheet>
                </div>
            </div>

            <!-- 서플라이 -->
            <p class="mt-4 mb-0 ml-1 text-subtitle-1 font-weight-medium">
                <v-icon
                    class="mb-2px"
                    color="primary"
                    small
                >
                    mdi-package-variant-closed
                </v-icon>
                서플라이
            </p>
            <div
                v-for="(item, index) in etc_list"
                :key="'etc_'+index"
                class="mt-2"
            >
                <v-divider class="my-2" v-if="index != 0"></v-divider>

                <div class="d-flex">
                    <!-- 기타상품명 -->
                    <v-file-input
                        class="rounded-10 white mr-2"
                        style="width:60%;"
                        height="104"
                        outlined
                        dense
                        hide-details
                        prepend-icon
                        color="primary"
                        placeholder="이미지"
                        v-model="item.etc_image"
                    ></v-file-input>

                    <div>
                        <div
                            class="d-flex mb-2"
                        >
                            <!-- 기타상품명 -->
                            <v-text-field
                                style="width:100%;"
                                class="rounded-10 white mr-2"
                                height="48"
                                v-model="item.etc_name"
                                hide-details
                                dense
                                placeholder="상품명"
                                outlined
                            ></v-text-field>

                            <!-- 기타상품 추가/삭제 버튼 -->
                            <v-btn
                                v-if="!index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="addEtcField()"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="subtractEtcField(index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </div>

                        <div
                            class="d-flex"
                        >
                            <!-- 기타상품상태 -->
                            <v-select
                                style="width:60%;"
                                class="rounded-10 white mr-2"
                                height="48"
                                v-model="item.etc_state"
                                hide-details
                                dense
                                outlined
                                placeholder="상품상태"
                                :items="select_list.trade_state"
                            >
                                <template v-slot:append>
                                    <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <!-- 기타상품가격 -->
                            <v-text-field
                                style="width:60%;"
                                class="rounded-10 white mr-2"
                                height="48"
                                v-model="item.etc_price"
                                hide-details
                                dense
                                placeholder="상품가격"
                                outlined
                                reverse
                                prefix="원"
                                @input="e => item.etc_price = $inputToComma(e)"
                            ></v-text-field>

                            <!-- 기타상품 복사 버튼 -->
                            <v-sheet>
                                <v-btn 
                                    height="48"
                                    class="rounded-10"
                                    block
                                    color="primary"
                                    dark
                                    depressed
                                    @click="copyEtcField(index)"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </v-sheet>
                        </div>
                    </div>
                </div>

                
            </div>

            <!-- 덱상품 -->
            <p class="mt-4 mb-0 ml-1 text-subtitle-1 font-weight-medium">
                <v-icon
                    class="mb-2px"
                    color="primary"
                    small
                >
                    mdi-package-variant-closed
                </v-icon>
                덱
            </p>
            <div
                v-for="(item, index) in deck_list"
                :key="'deck_'+index"
                class="mt-2"
            >
                <v-divider class="my-2" v-if="index != 0"></v-divider>

                <div class="d-flex">
                    <!-- 덱상품명 -->
                    <v-file-input
                        class="rounded-10 white mr-2"
                        style="width:60%;"
                        height="104"
                        outlined
                        dense
                        hide-details
                        prepend-icon
                        color="primary"
                        placeholder="이미지"
                        v-model="item.deck_image"
                    ></v-file-input>

                    <div>
                        <div
                            class="d-flex mb-2"
                        >
                            <!-- 덱상품명 -->
                            <v-text-field
                                style="width:100%;"
                                class="rounded-10 white mr-2"
                                height="48"
                                v-model="item.deck_name"
                                hide-details
                                dense
                                placeholder="상품명"
                                outlined
                                readonly
                                @click="searchDeck(index)"
                            ></v-text-field>

                            <!-- 덱상품 추가/삭제 버튼 -->
                            <v-btn
                                v-if="!index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="addDeckField()"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="48"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="subtractDeckField(index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </div>

                        <div
                            class="d-flex"
                        >
                            <!-- 덱상품상태 -->
                            <v-select
                                style="width:60%;"
                                class="rounded-10 white mr-2"
                                height="48"
                                v-model="item.deck_state"
                                hide-details
                                dense
                                outlined
                                placeholder="덱상태"
                                :items="select_list.trade_state"
                            >
                                <template v-slot:append>
                                    <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <!-- 덱상품가격 -->
                            <v-text-field
                                style="width:60%;"
                                class="rounded-10 white mr-2"
                                height="48"
                                v-model="item.deck_price"
                                hide-details
                                dense
                                placeholder="덱가격"
                                outlined
                                reverse
                                prefix="원"
                                @input="e => item.deck_price = $inputToComma(e)"
                            ></v-text-field>

                            <!-- 덱상품 복사 버튼 -->
                            <v-sheet>
                                <v-btn 
                                    height="48"
                                    class="rounded-10"
                                    block
                                    color="primary"
                                    dark
                                    depressed
                                    @click="copyDeckField(index)"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </v-sheet>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 간편모드 -->
        <div
            v-if="write.is_simple"
            class="my-4"
        >
            <div
                class="d-flex align-center"
            >
                간편모드 <span style="color:#0086FF; font-weight:medium;" class="ml-6px">AI</span>
                <v-img class="ml-1 mt-2px" height="20" max-width="30" contain src="@/assets/ai.svg" />
                상태입니다(베타버전)
            </div>
            <p class="mt-4" style="font-size:12px;">
                게시글 본문에 카드 이미지와, 카드 이름, 카드 제품명 그리고 카드 상태, 카드 가격을 입력해주세요 너정다 AI가 자동으로
                카드를 등록해 드립니다.(분류까지 최대 24시간 소요)<br/><br/>
                (예제)<br/>
                "흑염 리자몽 ex S급 4천원"<br/>
                "흑염 리자몽 ex SAR S급 0.4"<br/>
                "배틀VIP패스 플레이용 0.4"
            </p>
        </div>

        <!-- 안내문구 -->
        <p class="text-center mt-4 mb-2 primary--text" style="font-size:10px;">
            <v-icon x-small color="primary" class="mr-1" style="margin-bottom:2px;">mdi-alert</v-icon>
            한 번 등록한 카드상태와 가격은 삭제가 되지 않습니다.
        </p>

        <!-- 하단 버튼 -->
        <div class="d-flex justify-center">
            <v-btn
                height="34"
                width="84"
                class="rounded-10 mr-2"
                style="font-size:12px;"
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                등록하기
            </v-btn>
            <v-btn
                height="34"
                width="84"
                class="rounded-10"
                style="font-size:12px;"
                color="#ADAFCA"
                dark
                depressed
                @click="$router.go(-1)"
            >
                취소하기
            </v-btn>
        </div>

        <!-- 카드목록 (dialog) -->
        <v-bottom-sheet
            v-model="dialog.cardList"
            content-class="white"
            fullscreen
        >
            <CardListMobile
                :isWrite="true"
                @goToDetail="goToDetail"
                @CardSelected="CardSelected"
            />
        </v-bottom-sheet>

        <!-- 덱 레시피 불러오기 (dialog) -->
        <v-dialog
            v-model="dialog.deckList"
            width="880"
            content-class="rounded-15"
        >
            <v-sheet
                style="overflow-x:hidden;"
            >
                <DeckRecipeList
                    @DeckSelected="DeckSelected"
                    :key="componentKey"
                />
            </v-sheet>
        </v-dialog>

        <!-- 카드상세 (dialog) -->
        <!-- <v-dialog
            v-model="dialog.cardDetail"
            width="1040"
            content-class="pa-4 rounded-15 white"
        >
            <CardDetail
                :id="cardDetail_id"
                :isBoardContent="false"
                @CardSelected="CardSelected"
                @Close="dialog.cardDetail = false"
                :key="componentKey"
            />
        </v-dialog> -->

        <!-- 공지사항 -->
        <v-dialog
            v-model="dialog.notice"
            content-class="rounded-10"
        >
            <v-sheet
                class="pa-4 rounded-10"
            >
                <!-- 제목 -->
                <p
                    class="mb-5 font-weight-bold text-center"
                    style="font-size:18px;"
                >
                    거래글 작성 공지사항
                </p>

                <!-- 본문 -->
                <TipTapReader 
                    class="tiptap_style"
                    v-if="options_reader.content"
                    :options="options_reader"
                />

                <!-- 닫기 -->
                <v-btn
                    class="rounded-10 mt-4 px-12 d-flex mx-auto"
                    depressed
                    dark
                    color="primary"
                    @click="dialog.notice = false"
                >
                    닫기
                </v-btn>
            </v-sheet>
        </v-dialog>

        <!-- 글 등록시 로딩 동그라미 -->
        <v-progress-circular
            v-if="loading"
            size="100"
            style="position:absolute; top: 94%; left: 50%; transform: translate(-50%, -50%);"
            color="primary"
            indeterminate
        ></v-progress-circular>
    </div>
</template>
<script>
import TipTapWriterMobile from "@/components/tiptap/WriterMobile"
import TipTapReader from "@/components/tiptap/Reader"
import CardListMobile from '@/components/card/ListForMobile'
// import CardDetail from '@/components/card/detailForTrade/Detail'
import PointModule from '@/components/PointModule.js'
import BanModule from '@/components/BanModule.js'
import DeckRecipeList from '@/components/tiptap/extensions/DeckRecipeList2'

export default {
    components: {
        TipTapWriterMobile,
        TipTapReader,
        CardListMobile,
        // CardDetail,
        DeckRecipeList
    },

    data: () => ({
        select_list: {
            trade_type: ['시세등록', '팝니다', '삽니다', '서플라이 제품'],
            trade_way: ['등기(준등기)', '택배', '직거래', '기타'],
            trade_state: ['S급', 'A급', 'B급', 'C급 이하', '기타'],
            trade_card_state: ['S급', 'A급', 'B급', 'C급 이하', 'PSA 등급', '기타'],
            trade_card_state2: [
                '10등급',
                '9.5등급',
                '9등급',
                '8.5등급',
                '8등급',
                '7.5등급',
                '7등급',
                '6.5등급',
                '6등급',
                '5.5등급',
                '5등급',
                '4.5등급',
                '4등급',
                '3.5등급',
                '3등급',
                '2.5등급',
                '2등급',
                '1.5등급',
                '1등급',
            ],
            seller_address: []
        },

        write: {
            is_simple: false,
            writer: "",
            title: "",
            content: "",
            trade_type: "",
            trade_way: "",
            delivery_price: null,
            seller_address: ""
        },

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        //TipTap Reader
        options_reader: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        card_list: [
            {
                add_image: null,
                card_view_name: "",
                card_id: "",
                card_nation: "",
                card_name: "",
                card_state: "",
                card_state2: "",
                card_price: null
            }
        ],

        etc_list: [
            {
                etc_image: null,
                etc_name: "",
                etc_state: "",
                etc_price: null
            }
        ],

        deck_list: [
            {
                deck_id: null,
                deck_image: null,
                deck_name: "",
                deck_state: "",
                deck_price: null
            }
        ],

        cardTab: null,
        cardDetail_id:"",
        cardImage: "",

        dialog: {
            cardList:false,
            cardDetail: false,
            deckList: false,
            notice: true
        },

        selectedIndex: 0,

        writer_default_address: "",

        content_count:0,
        key: 0,

        componentKey: 0,

        isPass: false,
        loading: false,

        notice: "",

        tiptapKey: 0
    }),

    mounted(){
        BanModule.ban_check(this.$store.state.user_id).then((is_banned) => {
            if(is_banned){
                alert("밴 유저는 글을 작성하실 수 없습니다")
                this.$router.go(-1)
            }
        })

        // 내 배송지 목록 불러오기
        this.$http.post('/api/shipping/select', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then(res => {
            this.select_list.seller_address = res.data.map(a => a.address)

            if(!this.select_list.seller_address.length)
            {
                alert("아직 등록하신 배송지가 없습니다. 배송지 관리로 이동합니다.")
                this.$router.push("/mypage/address")
            }
        })

        // 카드거래 공지사항 표시
        this.$http.post('/api/admin/trade/notice/import')
        .then((res) => {
            this.options_reader.content = res.data[0].text
        })
    },

    // 페이지 이동 전 확인
    beforeRouteLeave (to, from, next) {
        if(!this.isPass){
            const answer = window.confirm("지금 작성하고 계신 글이 저장되지 않을 수 있습니다.\n페이지를 이동하시겠습니까?")
            if (answer) {
                next()
            } else {
                next(false)
            }
        }else{
            next()
        }
    },

    computed:{
        // 총 가격
        totalPrice(){
            let sum = 0
            this.card_list.forEach(e => {
                if(e.card_price != null){
                    sum += parseInt(this.$toUnComma(e.card_price))
                }
            })
            return Math.round(sum)
        }
    },

    methods: {
        card_state_changed(item){
            if(item.card_state != 'PSA 등급' && item.card_state != '기타'){
                item.card_state2 = "해당없음"
            }else{
                item.card_state2 = ""
            }
        },

        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        // 카드 선택창 열기
        searchCard(index){
            this.selectedIndex = index
            this.dialog.cardList = true
        },

        // 덱 선택창 열기
        searchDeck(index){
            this.selectedIndex = index
            this.dialog.deckList = true
        },

        // 덱레시피 입력
        DeckSelected(item) {
            this.deck_list[this.selectedIndex].deck_id = item.id
            this.deck_list[this.selectedIndex].deck_name = item.title
            this.dialog.deckList = false
        },

        // 카드 목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
            this.componentKey++
        },

        // 선택한 카드 정보
        CardSelected(card, nation){
            if(nation=='kr'){
                this.card_list[this.selectedIndex].card_view_name = card.name_kr + " (" + card.id+")"
                this.card_list[this.selectedIndex].card_name = card.name_kr
            }else if(nation=='en'){
                this.card_list[this.selectedIndex].card_view_name = card.name_en + " (" + card.id+")"
                this.card_list[this.selectedIndex].card_name = card.name_en
            }else if(nation=='jp'){
                this.card_list[this.selectedIndex].card_view_name = card.name_jp + " (" + card.id+")"
                this.card_list[this.selectedIndex].card_name = card.name_jp
            }

            this.card_list[this.selectedIndex].card_id = card.id
            this.card_list[this.selectedIndex].card_nation = nation
            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 카드 추가
        addField(){
            this.card_list.push(
                {
                    add_image: null,
                    card_view_name: "",
                    card_id: "",
                    card_nation: "",
                    card_name: "",
                    card_state: "",
                    card_state2: "",
                    card_price: null
                }
            )
        },

        // 카드 빼기
        subtractField(index){
            this.card_list.splice(index,1)
        },

        // 카드 복사
        copyField(index){
            this.card_list.push(
                {
                    add_image: this.card_list[index].add_image,
                    card_view_name: this.card_list[index].card_view_name,
                    card_id: this.card_list[index].card_id,
                    card_nation: this.card_list[index].card_nation,
                    card_name: this.card_list[index].card_name,
                    card_state: this.card_list[index].card_state,
                    card_state2: this.card_list[index].card_state2,
                    card_price: this.card_list[index].card_price
                }
            )
        },

        // 기타상품 추가
        addEtcField(){
            this.etc_list.push(
                {
                    etc_image: null,
                    etc_name: "",
                    etc_state: "",
                    etc_price: null
                }
            )
        },

        // 기타상품 빼기
        subtractEtcField(index){
            this.etc_list.splice(index,1)
        },

        // 기타상품 복사
        copyEtcField(index){
            this.etc_list.push(
                {
                    etc_image: this.etc_list[index].etc_image,
                    etc_name: this.etc_list[index].etc_name,
                    etc_state: this.etc_list[index].etc_state,
                    etc_price: this.etc_list[index].etc_price
                }
            )
        },

        // 덱상품 추가
        addDeckField(){
            this.deck_list.push(
                {
                    deck_id: null,
                    deck_image: null,
                    deck_name: "",
                    deck_state: "",
                    deck_price: null
                }
            )
        },

        // 덱상품 빼기
        subtractDeckField(index){
            this.deck_list.splice(index,1)
        },

        // 덱상품 복사
        copyDeckField(index){
            this.deck_list.push(
                {
                    deck_image: this.deck_list[index].deck_image,
                    deck_name: this.deck_list[index].deck_name,
                    deck_state: this.deck_list[index].deck_state,
                    deck_price: this.deck_list[index].deck_price
                }
            )
        },

        // 글 등록 시 카드 입력
        async cardSubmit(insertId){
            for(let i=0; i<this.card_list.length; i++){
                if (!this.card_list[i].add_image || this.card_list[i].add_image === "") {
                    this.$http.post('/api/trade/post/insert/card', {
                        params: {
                            post_id : insertId,
                            add_image : null,
                            card_id: this.card_list[i].card_id,
                            card_nation : this.card_list[i].card_nation,
                            card_name : this.card_list[i].card_name,
                            card_state : this.card_list[i].card_state === 'PSA 등급' ? 'PSA ' + this.card_list[i].card_state2 : (this.card_list[i].card_state === '기타' ? '기타 ' + this.card_list[i].card_state2 : this.card_list[i].card_state),
                            card_price : this.$toUnComma(this.card_list[i].card_price)
                        }
                    })
                }else{
                    let formData = new FormData()
                    formData.append("image", this.card_list[i].add_image)

                    await this.$http.post('/api/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "trade/card"
                        }
                    }).then(async(res) => {
                        await this.$http.post('/api/trade/post/insert/card', {
                            params: {
                                post_id : insertId,
                                add_image : res.data,
                                card_id: this.card_list[i].card_id,
                                card_nation : this.card_list[i].card_nation,
                                card_name : this.card_list[i].card_name,
                                card_state : this.card_list[i].card_state === 'PSA 등급' ? 'PSA ' + this.card_list[i].card_state2 : (this.card_list[i].card_state === '기타' ? '기타 ' + this.card_list[i].card_state2 : this.card_list[i].card_state),
                                card_price : this.$toUnComma(this.card_list[i].card_price)
                            }
                        })
                    })
                }
            }
        },

        // 기타상품 입력
        async etcInsert(insertId){
            for(let i=0; i<this.etc_list.length; i++){
                if (!this.etc_list[i].etc_image || this.etc_list[i].etc_image === "") {
                    this.$http.post('/api/trade/post/insert/etc', {
                        params: {
                            post_id : insertId,
                            etc_image : null,
                            etc_name : this.etc_list[i].etc_name,
                            etc_state : this.etc_list[i].etc_state,
                            etc_price : this.$toUnComma(this.etc_list[i].etc_price)
                        }
                    })
                }else{
                    let formData = new FormData()
                    formData.append("image", this.etc_list[i].etc_image)

                    await this.$http.post('/api/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "trade/etc"
                        }
                    }).then(async(res) => {
                        await this.$http.post('/api/trade/post/insert/etc', {
                            params: {
                                post_id : insertId,
                                etc_image : res.data,
                                etc_name : this.etc_list[i].etc_name,
                                etc_state : this.etc_list[i].etc_state,
                                etc_price : this.$toUnComma(this.etc_list[i].etc_price)
                            }
                        })
                    })
                }
            }
        },

        // 덱상품 입력
        async deckInsert(insertId){
            for(let i=0; i<this.deck_list.length; i++){
                if (!this.deck_list[i].deck_image || this.deck_list[i].deck_image === "") {
                    this.$http.post('/api/trade/post/insert/deck', {
                        params: {
                            post_id : insertId,
                            deck_id : this.deck_list[i].deck_id,
                            deck_image : null,
                            deck_name : this.deck_list[i].deck_name,
                            deck_state : this.deck_list[i].deck_state,
                            deck_price : this.$toUnComma(this.deck_list[i].deck_price)
                        }
                    })
                }else{
                    let formData = new FormData()
                    formData.append("image", this.deck_list[i].deck_image)

                    await this.$http.post('/api/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "trade/deck"
                        }
                    }).then(async(res) => {
                        await this.$http.post('/api/trade/post/insert/deck', {
                            params: {
                                post_id : insertId,
                                deck_id : this.deck_list[i].deck_id,
                                deck_image : res.data,
                                deck_name : this.deck_list[i].deck_name,
                                deck_state : this.deck_list[i].deck_state,
                                deck_price : this.$toUnComma(this.deck_list[i].deck_price)
                            }
                        })
                    })
                }
            }
        },

        // 등록하기
        submit(){
            let checkCardListEmpty = this.card_list.filter( 
                e => e.card_id == "" || e.card_price == null || e.card_state == ""
            )

            let checkEtcListEmpty = this.etc_list.filter( 
                e => e.etc_name == "" || e.etc_price == null || e.etc_state == ""
            )

            let checkDeckListEmpty = this.deck_list.filter( 
                e => e.deck_name == "" || e.deck_price == null || e.deck_state == ""
            )

            if(!this.write.title.length)
            {
                alert("제목을 입력해주세요")
            }
            else if(!this.content_count)
            {
                alert("내용을 입력해주세요")
            }
            else if(!this.write.trade_type)
            {
                alert("거래유형을 선택해주세요")
            }
            else if(!this.write.trade_way)
            {
                alert("거래방법을 선택해주세요")
            }
            else if(this.write.delivery_price == null || this.write.delivery_price=="")
            {
                alert("배송비를 입력해주세요")
            }
            else if(!this.write.seller_address)
            {
                alert("판매자 주소를 선택해주세요")
            }

            // 카드목록 입력란이 비어있는 경우
            else if(checkCardListEmpty.length && this.card_list.length > 1 && !this.write.is_simple)
            {
                alert("카드목록 중 입력되지 않은 값이 있습니다")
            }

            // 기타상품 입력란이 비어있는 경우
            else if(checkEtcListEmpty.length && this.etc_list.length > 1 && !this.write.is_simple)
            {
                alert("기타상품 중 입력되지 않은 값이 있습니다")
            }

            // 덱 상품 입력란이 비어있는 경우
            else if(checkDeckListEmpty.length && this.deck_list.length > 1 && !this.write.is_simple)
            {
                alert("덱 상품 중 입력되지 않은 값이 있습니다")
            }

            // 카드목록, 기타상품이 한줄이면서 둘다 입력란이 비어있는 경우
            // else if(!checkEtcListEmpty.length && !checkEtcListEmpty.length)
            // {
            //     alert("기타상품 및 카드상품을 등록하지 않고 거래글을 입력할 수 없습니다")
            // }

            else{
                this.write.writer = this.$store.state.user_id
                this.write.content = this.options.content
                this.write.delivery_price = this.$toUnComma(this.write.delivery_price)
                
                // 로딩 시작
                this.loading = true

                // 거래글 입력
                this.$http.post('/api/trade/post/insert', {
                    params: {
                        write: this.write
                    }
                }).then(async(res) => {

                    if(!this.write.is_simple)
                    {
                        // 카드 입력
                        if(checkCardListEmpty.length != this.card_list.length){
                            await this.cardSubmit(res.data.insertId)
                        }

                        // 기타상품 입력
                        if(checkEtcListEmpty.length != this.etc_list.length){
                            await this.etcInsert(res.data.insertId)
                        }

                        // 덱 상품 입력
                        if(checkDeckListEmpty.length != this.deck_list.length){
                            await this.deckInsert(res.data.insertId)
                        }
                    }

                    // DB 알림
                    this.$dbAlarm(
                        "카드거래 - 거래글",
                        this.$store.state.nickname,
                        this.$store.state.user_id
                    )

                    // 로딩 종료
                    this.loading = false

                    // 포인트 적립
                    PointModule.amountCheck('카드거래', '거래글 등록 카드 1개당').then((res) => {
                        PointModule.Update(this.$store.state.user_id, res.data[0].amount*this.card_list.length)
                        PointModule.Record(this.$store.state.user_id, '카드거래', '거래글 등록 카드 1개당', this.write.title, res.data[0].amount*this.card_list.length)
                    })

                    this.isPass = true
                    this.$router.push("/trade/post/read?id="+res.data.insertId)
                })
            }
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>