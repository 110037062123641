<template>
    <div>
        <!-- 상단 -->
        <v-sheet class="mx-6 d-flex">
            <!-- 총 수량 -->
            <p class="mb-0 mr-5" style="font-size:13px;">
                총 수량
                <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{deck_list.length}}개</span>
            </p>

            <!-- 총 가격 -->
            <p class="mb-0 mr-5" style="font-size:13px;">
                총 가격
                <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(totalSum)}}원</span>
            </p>
        </v-sheet>

        <v-divider class="my-2"></v-divider>

        <!-- 목록 -->
        <div
            v-for="item in deck_list" :key="item.id"
            style="cursor:pointer; border-bottom:1px solid #e0e0e0; width:100%;"
            class="pl-5 pr-2 pt-1 pb-2 d-flex align-center"
        >
            <!-- 이미지 -->
            <v-sheet class="my-2" @click="goToDetail(item.deck_id)">
                <v-img
                    height="80"
                    width="58"
                    :src="computed_image(item)"
                >
                    <v-sheet
                        color="#23D2E2"
                        class="white--text"
                        style="position:absolute; bottom:0px; width:100%; height:24px; font-size:9px; text-align:center; padding-top:5px;"
                        @click.stop="openImage(item)"
                    >
                        사진보기 ({{item.deck_image == 'default.png'? 0:1}})
                    </v-sheet>
                </v-img>
            </v-sheet>

            <!-- 정보 -->
            <v-sheet
                class="ml-4"
                @click="goToDetail(item.deck_id)"
            >
                <!-- 카드 이름 -->
                <p
                    class="mb-0"
                    style="font-size:15px; font-weight:bold;"
                >
                    {{item.deck_name}}
                </p>

                <p
                    class="mb-0"
                    style="font-size:14px; font-weight:medium; line-height:18px;"
                >
                    {{item.deck_state}} <font class="icu_purple--text">{{$toComma(item.deck_price)}}원</font>
                </p>
            </v-sheet>

            <!-- 체크박스 -->
            <v-sheet class="d-flex justify-center flex-grow-1">
                <v-checkbox
                    v-if="item.state=='등록됨'"
                    class="ml-3"
                    v-model="selected"
                    :value="item"
                    @change="emitSelected()"
                ></v-checkbox>
                <p
                    v-if="item.state=='거래진행중'"
                    class="my-0 grey--text text-body-2"
                    style="letter-spacing:-1px !important;"
                >
                    거래진행중
                </p>
                <p
                    v-if="item.state=='판매완료'"
                    class="my-0 text-body-2"
                    style="color:#F8468D;"
                >
                    판매완료
                </p>
            </v-sheet>
        </div>

        <div
            class="d-flex justify-end align-center mx-6"
        >
            <p class="text-body-2 font-weight-medium">전체선택</p>
            <v-sheet class="d-flex justify-center">
                <v-checkbox
                    class="ml-3 my-2"
                    @change="selectAll(); emitSelected()"
                ></v-checkbox>
            </v-sheet>
        </div>
        <v-sheet class="d-flex justify-space-between mx-5" style="margin-top:-16px;">
            <v-sheet class="py-1">
                <!-- 총 수량 -->
                <p class="mb-0 mr-2" style="font-size:12px;">
                    선택된 덱 총 수량
                    <span class="ml-1 font-weight-bold" style="font-size:12px; color:#615DFA;">{{selected.length}}개</span>
                </p>

                <!-- 총 가격 -->
                <p class="mb-0 mr-2" style="font-size:12px;">
                    선택된 덱 총 가격
                    <span class="ml-1 font-weight-bold" style="font-size:12px; color:#615DFA;">{{$toComma(selectedSum)}}원</span>
                </p>

                <!-- 배송비 -->
                <p class="mb-0 mr-2" style="font-size:12px;">
                    배송비
                    <span class="ml-1 font-weight-bold" style="font-size:12px; color:#615DFA;">{{$toComma(delivery_price)}}원</span>
                </p>
            </v-sheet>

            <!-- 선택한 상품 거래신청 -->
            <v-btn
                height="60"
                width="90"
                class="rounded-10 ml-6 px-6"
                style="font-size:13px;"
                color="#615DFA"
                dark
                depressed
                @click="$emit('tradeApply')"
            >
                <div>
                    선택한 상품<br/>
                    거래신청
                </div>
            </v-btn>
        </v-sheet>

        <v-dialog
            v-model="dialog.image_big"
            width="600"
            content-class="rounded-xl"
        >
            <v-sheet
                class="pa-4 rounded-xl"
                style="border:2px solid #4ed6e2"
            >
                <v-img
                    contain
                    width="568"
                    max-height="720"
                    class="rounded-10"
                    :src="'/upload/trade/deck/'+selected_image"
                ></v-img>
                <div
                    class="d-flex justify-start mt-2"
                    style="width:100%; overflow-x:auto"
                >
                    <v-sheet
                        v-for="item in add_image_list" :key="item"
                        class="py-1 px-2 mr-2 rounded-10"
                        outlined
                        @click="selected_image = item"
                    >
                        <v-img
                            contain
                            class="d-flex rounded-10"
                            width="32"
                            height="48"
                            :src="'/upload/trade/deck/'+item"
                        ></v-img>
                    </v-sheet>
                    <v-spacer></v-spacer>
                    <v-btn
                        width="68"
                        height="24"
                        color="#ADAFCA"
                        class="white--text rounded-10"
                        style="font-size:10px;"
                        depressed
                        @click="dialog.image_big = false"
                    >닫기</v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: [
        "delivery_price"
    ],
    data: () => ({
        deck_list: [],
        selected: [],

        selected_item: {},

        dialog: {
            image_big: false
        },

        selected_image: null,
        add_image_list: []
    }),

    mounted() {
        this.$http.post('/api/trade/post/read/import/deckList', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            this.deck_list = res.data
        })
    },

    computed:{
        selectedSum(){
            let sum = 0
            this.selected.forEach(e => {
                sum +=  e.deck_price
            })

            return Math.round(parseInt(sum))
        },

        totalSum(){
            let sum = 0
            this.deck_list.forEach(e => {
                sum +=  e.deck_price
            })

            return Math.round(parseInt(sum))
        }
    },

    methods: {
        // 전체선택/취소
        selectAll(){
            if(this.selected.length){
                this.selected = []
            }else{
                this.selected = this.deck_list.filter(e => e.state=='등록됨')
            }
        },

        // 선택값 Emit
        emitSelected(){
            this.$emit("selected", this.selected)
        },

        // 상세 페이지 이동
        goToDetail(id){
            window.open('/deck/read?id='+id, '_blank') //새 탭
        },

        openImage(item){
            this.dialog.image_big = true;
            this.selected_image = item.add_image
            this.add_image_list[0] = item.add_image
        },

        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },
    }
}
</script>
<style scoped>
.cardListTable{
    width:100%;
    border-collapse: collapse;
}

.cardListTable tr{
    border-top:1px solid #e0e0e0;
}

.cardListTable tr:last-child{
    border-bottom:1px solid #e0e0e0;
}

.cardListTable tr td{
    padding:8px;
}

.cardListTable tr th{
    height:70px;
    font-size:16px;
   
    padding:8px;
}
</style>