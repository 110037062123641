<template>
    <v-card outlined class="pa-2">
        <!-- 상단 -->
        <div
            class="d-flex justify-space-between"
        >
            <v-sheet
                width="200"
            ></v-sheet>
            <p class="pt-4 font-weight-bold text-center text-h5 grey--text text--darken-2">카드거래글 등록(수정)</p>
            <v-sheet
                width="200"
                class="d-flex justify-end pr-4"
            >
                <!-- 간편모드 -->
                <v-switch
                    v-model="write.is_simple"
                    label="간편모드"
                    inset
                    color="primary"
                >
                    <template v-slot:label>
                        간편모드 <span style="color:#0086FF; font-weight:medium;" class="ml-6px">AI</span> <v-img class="ml-2" height="20" max-width="30" contain src="@/assets/ai.svg" />
                    </template>
                </v-switch>
            </v-sheet>
        </div>

        <v-text-field
            class="rounded-10 font-weight-bold"
            height="48"
            hide-details
            dense
            placeholder="제목을 입력해주세요"
            outlined
            v-model="write.title"
        ></v-text-field>

        <v-sheet class="d-flex justify-space-between mt-2">
            <v-select
                class="rounded-10 font-weight-bold shrink"
                style="width:33%;"
                height="48"
                hide-details
                dense
                outlined
                placeholder="거래유형"
                :items="select_list.trade_type"
                v-model="write.trade_type"
            ></v-select>
            <v-select
                class="rounded-10 font-weight-bold shrink mx-2"
                style="width:33%;"
                height="48"
                hide-details
                dense
                outlined
                placeholder="거래방법"
                :items="select_list.trade_way"
                v-model="write.trade_way"
            ></v-select>
            <v-text-field
                class="rounded-10 font-weight-bold shrink"
                style="width:33%;"
                height="48"
                dense
                hide-details
                placeholder="배송비"
                suffix="원"
                outlined
                v-model="write.delivery_price"
                @input="e => write.delivery_price = $inputToComma(e)"
            ></v-text-field>
        </v-sheet>
        <v-sheet class="d-flex justify-space-between mt-2">
            <v-select
                class="rounded-10 font-weight-bold shrink"
                style="width:67%;"
                height="48"
                hide-details
                dense
                outlined
                placeholder="판매자 주소"
                :items="select_list.seller_address"
                v-model="write.seller_address"
            ></v-select>
            <v-sheet outlined width="33%" class="ml-2 px-4 d-flex align-center rounded-10 font-weight-bold" style="font-size:12px; color:#424361">
                등록한 카드 총 수량: {{card_list.length}}개<br/>
                총 가격: {{$toComma(totalPrice)}}원
            </v-sheet>
        </v-sheet>

        <!-- 본문 -->
        <TipTapWriter 
            class="tiptap_style mt-2"
            :options="options"
            v-if="options.content"
            @emit_count="import_content_count"
        />

        <!-- 일반모드 -->
        <div
            v-if="!write.is_simple"
        >
            <!-- 싱글카드 -->
            <p class="mt-4 mb-0 ml-1 text-subtitle-1 font-weight-medium">
                <v-icon
                    class="mb-2px"
                    color="primary"
                    small
                >
                    mdi-package-variant
                </v-icon>
                싱글카드
            </p>
            <v-sheet
                v-for="(item, index) in card_list"
                :key="'card_'+index"
                class="mt-2 d-flex"
            >
                <!-- 사진 -->
                <v-sheet
                    outlined
                    class="pa-1 mr-2 rounded-10"
                    style="cursor:pointer;"
                    @click="openUpload('card_list', index)"
                >
                    <v-img
                        class="rounded-10 pt-2px"
                        :src="'/upload/trade/card/'+item.add_image"
                        width="100"
                        height="38"
                    ></v-img>
                </v-sheet>

                <!-- 카드 ID -->
                <v-text-field
                    v-model="item.card_view_name"
                    class="rounded-10 mr-2"
                    style="width:54%;"
                    height="48"
                    hide-details
                    dense
                    placeholder="카드 선택"
                    outlined
                    readonly
                    @click="searchCard(index)"
                ></v-text-field>

                <!-- 상태/등급 -->
                <v-select
                    v-model="item.card_state"
                    class="rounded-10 mr-2 shrink"
                    style="width:60%;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상태/등급"
                    :items="select_list.trade_card_state"
                    @change="card_state_changed(item)"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px ml-0" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 상세등급 -->
                <v-select
                    :disabled="item.card_state != 'PSA 등급' && item.card_state != '기타'"
                    :class="(item.card_state != 'PSA 등급' && item.card_state != '기타')? 'grey lighten-5':''"
                    v-model="item.card_state2"
                    class="rounded-10 mr-2 shrink"
                    style="width:60%;"
                    height="48"
                    hide-details
                    dense
                    outlined
                    placeholder="상세등급"
                    :items="select_list.trade_card_state2"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px ml-0" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 카드가격 -->
                <v-text-field
                    v-model="item.card_price"
                    class="rounded-10 mr-2"
                    style="width:50%;"
                    height="48"
                    hide-details
                    dense
                    placeholder="카드가격"
                    outlined
                    reverse
                    prefix="원"
                    @input="e => item.card_price == 0? item.card_price = $inputToComma(1000) : item.card_price = $inputToComma(e)"
                    :readonly="item.state == '판매완료' || item.state == '거래진행중'"
                ></v-text-field>

                <!-- 카드 검색 버튼 -->
                <v-sheet class="mr-2">
                    <v-btn 
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="searchCard(index)"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-sheet>

                <!-- 카드 추가/삭제 버튼 -->
                <v-sheet class="mr-2">
                    <v-btn
                        v-if="!index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="addField()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="subtractField(index)"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </v-sheet>

                <!-- 카드 복사 버튼 -->
                <v-sheet>
                    <v-btn 
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="copyField(index)"
                    >
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 서플라이 -->
            <p class="mt-4 mb-0 ml-1 text-subtitle-1 font-weight-medium">
                <v-icon
                    class="mb-2px"
                    color="primary"
                    small
                >
                    mdi-package-variant-closed
                </v-icon>
                서플라이
            </p>
            <v-sheet
                v-for="(item, index) in etc_list"
                :key="'etc_'+index"
                class="mt-2 d-flex"
            >
                <!-- 사진 -->
                <v-sheet
                    outlined
                    class="pa-1 mr-2 rounded-10"
                    style="cursor:pointer;"
                    @click="openUpload('etc_list', index)"
                >
                    <v-img
                        class="rounded-10 pt-2px"
                        :src="'/upload/trade/etc/'+item.etc_image"
                        width="100"
                        height="38"
                    ></v-img>
                </v-sheet>

                <!-- 기타상품명 -->
                <v-text-field
                    style="width:100%;"
                    class="rounded-10 mr-2"
                    height="48"
                    v-model="item.etc_name"
                    hide-details
                    dense
                    placeholder="상품명"
                    outlined
                ></v-text-field>

                <!-- 기타상품상태 -->
                <v-select
                    style="width:60%;"
                    class="rounded-10 mr-2"
                    height="48"
                    v-model="item.etc_state"
                    hide-details
                    dense
                    outlined
                    placeholder="상품상태"
                    :items="select_list.trade_state"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 기타상품가격 -->
                <v-text-field
                    style="width:60%;"
                    class="rounded-10 mr-2"
                    height="48"
                    v-model="item.etc_price"
                    hide-details
                    dense
                    placeholder="상품가격"
                    outlined
                    reverse
                    prefix="원"
                    @input="e => item.etc_price = $inputToComma(e)"
                ></v-text-field>

                <!-- 기타상품 추가/삭제 버튼 -->
                <v-sheet class="mr-2">
                    <v-btn
                        v-if="!index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="addEtcField()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="subtractEtcField(index)"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </v-sheet>

                <!-- 기타상품 복사 버튼 -->
                <v-sheet>
                    <v-btn 
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="copyEtcField(index)"
                    >
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 덱상품 -->
            <p class="mt-4 mb-0 ml-1 text-subtitle-1 font-weight-medium">
                <v-icon
                    class="mb-2px"
                    color="primary"
                    small
                >
                    mdi-package-variant-closed
                </v-icon>
                덱
            </p>
            <v-sheet
                v-for="(item, index) in deck_list"
                :key="'deck_'+index"
                class="mt-2 d-flex"
            >
                <!-- 사진 -->
                <v-sheet
                    outlined
                    class="pa-1 mr-2 rounded-10"
                    style="cursor:pointer;"
                    @click="openUpload('deck_list', index)"
                >
                    <v-img
                        class="rounded-10 pt-2px"
                        :src="'/upload/trade/deck/'+item.deck_image"
                        width="100"
                        height="38"
                    ></v-img>
                </v-sheet>

                <!-- 덱상품명 -->
                <v-text-field
                    style="width:80%;"
                    class="rounded-10 mr-2"
                    height="48"
                    v-model="item.deck_name"
                    hide-details
                    dense
                    placeholder="상품명"
                    outlined
                ></v-text-field>

                <!-- 덱상태 -->
                <v-select
                    style="width:60%;"
                    class="rounded-10 mr-2"
                    height="48"
                    v-model="item.deck_state"
                    hide-details
                    dense
                    outlined
                    placeholder="덱상태"
                    :items="select_list.trade_state"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 덱가격 -->
                <v-text-field
                    style="width:60%;"
                    class="rounded-10 mr-2"
                    height="48"
                    v-model="item.deck_price"
                    hide-details
                    dense
                    placeholder="덱가격"
                    outlined
                    reverse
                    prefix="원"
                    @input="e => item.deck_price = $inputToComma(e)"
                ></v-text-field>

                <!-- 카드 검색 버튼 -->
                <v-sheet class="mr-2">
                    <v-btn 
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="searchDeck(index)"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-sheet>

                <!-- 덱상품 추가/삭제 버튼 -->
                <v-sheet class="mr-2">
                    <v-btn
                        v-if="!index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="addDeckField()"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="index"
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="subtractDeckField(index)"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>
                </v-sheet>

                <!-- 덱상품 복사 버튼 -->
                <v-sheet>
                    <v-btn 
                        height="48"
                        class="rounded-10"
                        block
                        color="primary"
                        dark
                        depressed
                        @click="copyDeckField(index)"
                    >
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- 간편모드 -->
        <div
            v-if="write.is_simple"
            class="pa-3"
        >
            <div
                class="d-flex align-center"
            >
                간편모드 <span style="color:#0086FF; font-weight:medium;" class="ml-6px">AI</span>
                <v-img class="ml-1 mt-2px" height="20" max-width="30" contain src="@/assets/ai.svg" />
                상태입니다(베타버전)
            </div>
            <p class="mt-4" style="font-size:14px;">
                게시글 본문에 카드 이미지와, 카드 이름, 카드 제품명 그리고 카드 상태, 카드 가격을 입력해주세요 너정다 AI가 자동으로<br/>
                카드를 등록해 드립니다.(분류까지 최대 24시간 소요)<br/><br/>
                (예제)<br/>
                "흑염 리자몽 ex S급 4천원"<br/>
                "흑염 리자몽 ex SAR S급 0.4"<br/>
                "배틀VIP패스 플레이용 0.4"
            </p>
        </div>

        <!-- 안내문구 -->
        <p class="text-center mt-2 primary--text" style="font-size:13px;">
            <v-icon x-small color="primary" class="mr-1" style="margin-bottom:2px;">mdi-alert</v-icon>
            한 번 등록한 카드상태와 가격은 삭제가 되지 않습니다.
        </p>

        <!-- 버튼 -->
        <v-sheet class="d-flex justify-center pa-1 mt-2">
            <v-btn
                height="49"
                width="140"
                class="mx-2 font-weight-bold rounded-10"
                style="font-size:16px;"
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                수정하기
            </v-btn>
            <v-btn
                height="49"
                width="140"
                class="mx-2 font-weight-bold rounded-10"
                style="font-size:16px;"
                color="#ADAFCA"
                dark
                depressed
                @click="$router.go(-1)"
            >
                취소하기
            </v-btn>
        </v-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 94%; left: 50%; transform: translate(-50%, -50%);"
            indeterminate
            color="primary"
            size="100"
        ></v-progress-circular>

        <!-- 카드목록 (dialog) -->
        <v-dialog
            v-model="dialog.cardList"
            width="1040"
            content-class="rounded-15"
        >
            <v-sheet>
                <CardList
                    :isWrite="true"
                    @goToDetail="goToDetail"
                />
            </v-sheet>
        </v-dialog>

        <!-- 카드상세 (dialog) -->
        <v-dialog
            v-model="dialog.cardDetail"
            width="1040"
            content-class="rounded-15"
        >
            <v-sheet class="pa-4">
                <CardDetail
                    :id="cardDetail_id"
                    :isBoardContent="false"
                    @CardSelected="CardSelected"
                    @Close="dialog.cardDetail = false"
                />
            </v-sheet>
        </v-dialog>

        <!-- 덱 레시피 불러오기 (dialog) -->
        <v-dialog
            v-model="dialog.deckList"
            width="880"
            content-class="rounded-15"
        >
            <v-sheet
                style="overflow-x:hidden;"
            >
                <DeckRecipeList
                    @DeckSelected="DeckSelected"
                    :key="componentKey"
                />
            </v-sheet>
        </v-dialog>

        <!-- 이미지 업로드 (dialog) -->
        <v-dialog
            v-model="dialog.upload"
            width="auto"
            content-class="rounded-10"
        >
            <v-sheet
                class="pa-4 rounded-10"
                width="300"
            >
                <p
                    class="text-center font-weight-medium mb-3"
                >
                    사진 등록
                </p>
                <v-file-input
                    class="rounded-10"
                    color="primary"
                    v-model="imageFile"
                    hide-details
                    outlined
                    prepend-icon=""
                    append-icon="mdi-image-outline"
                ></v-file-input>
                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        class="px-6 rounded-10 mr-2"
                        depressed
                        @click="cancelUpload()"
                    >
                        취소
                    </v-btn>
                    <v-btn
                        class="px-6 rounded-10"
                        depressed
                        color="primary"
                        dark
                        @click="submitUpload()"
                    >
                        확인
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
</v-card>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

import CardList from '@/components/card/List'
import CardDetail from '@/components/card/detailForTrade/Detail'
import PointModule from '@/components/PointModule.js'
import DeckRecipeList from '@/components/tiptap/extensions/DeckRecipeList2'

export default {
    components: {
        TipTapWriter,
        CardList,
        CardDetail,
        DeckRecipeList
    },

    data: () => ({
        select_list: {
            trade_type: ['시세등록', '팝니다', '삽니다', '서플라이 제품'],
            trade_way: ['등기(준등기)', '택배', '직거래', '기타'],
            trade_state: ['S급', 'A급', 'B급', 'C급 이하', '기타'],
            trade_card_state: ['S급', 'A급', 'B급', 'C급 이하', 'PSA 등급', '기타'],
            trade_card_state2: [
                '10등급',
                '9.5등급',
                '9등급',
                '8.5등급',
                '8등급',
                '7.5등급',
                '7등급',
                '6.5등급',
                '6등급',
                '5.5등급',
                '5등급',
                '4.5등급',
                '4등급',
                '3.5등급',
                '3등급',
                '2.5등급',
                '2등급',
                '1.5등급',
                '1등급',
            ],
            seller_address: []
        },

        write: {
            is_simple: false,
            writer: "",
            title: "",
            content: "",
            trade_type:"",
            trade_way:"",
            delivery_price: null,
            post_card_list_id:1,
            seller_address: ""
        },

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        // 카드상품 목록
        card_list: [
            {
                add_image: null,
                card_id: "",
                card_nation: "",
                card_name: "",
                card_state: "",
                card_state2: "",
                card_price: null
            }
        ],

        first_card_list_length: 0,

        // 기타상품 목록
        etc_list: [
            {
                etc_image: null,
                etc_name: "",
                etc_state: "",
                etc_price: null
            }
        ],

        first_etc_list_length: 0,

        // 덱상품 목록
        deck_list: [
            {
                deck_id: null,
                deck_image: null,
                deck_name: "",
                deck_state: "",
                deck_price: null
            }
        ],

        first_deck_list_length: 0,

        dialog: {
            cardList:false,
            cardDetail: false,
            deckList: false,
            upload: false,
        },

        componentKey: 0,

        cardTab: null,
        cardDetail_id:"",
        cardImage: "",

        selectedIndex: 0,

        content_count:1, //수정하기는 기본으로 내용 있으므로
        isPass: false,

        loading: false,

        selected_type: "",
        selected_index: null,

        imageFile: {}
    }),

    mounted(){
        // 내 배송지 목록 불러오기
        this.$http.post('/api/shipping/select', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then(res => {
            this.select_list.seller_address = res.data.map(a=>a.address)
        })

        // 글 정보 불러오기
        this.$http.post('/api/trade/post/select', {
            params: {
                id : this.$route.query.id
            }
        }).then(res => {
            this.write.is_simple = res.data[0].is_simple
            this.write.title = res.data[0].title
            this.options.content = res.data[0].content
            this.write.trade_type = res.data[0].trade_type
            this.write.trade_way = res.data[0].trade_way
            this.write.delivery_price = this.$toComma(res.data[0].delivery_price)
            this.write.seller_address = res.data[0].seller_address
        })

        // 카드목록 불러오기
        this.$http.post('/api/trade/post/select/cardlist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            if(res.data.length){
                this.card_list = res.data.map(e => {
                    e.card_price = this.$toComma(e.card_price)
                    e.card_view_name = e.card_name + " (" + e.card_id+")"
                    if(e.card_state.startsWith("PSA ")){
                        e.card_state2 = e.card_state.substring(4)
                        e.card_state = "PSA 등급"
                    } else if(e.card_state.startsWith("기타 ")){
                        e.card_state2 = e.card_state.substring(3)
                        e.card_state = "기타"
                    } else {
                        e.card_state2 = "해당없음"
                    }
                    return e
                })

                // this.card_list.forEach(e => {
                //     e.card_price = this.$toComma(e.card_price);
                //     e.card_view_name = e.card_name + " (" + e.card_id+")"
                // })
                this.first_card_list_length = res.data.length
            }
        })

        // 기타상품목록 불러오기
        this.$http.post('/api/trade/post/select/etclist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            if(res.data.length){
                this.etc_list = res.data
                this.etc_list.forEach(e => {
                    e.etc_price = this.$toComma(e.etc_price)
                })
                this.first_etc_list_length = res.data.length
            }
        })

        // 덱상품 목록 불러오기
        this.$http.post('/api/trade/post/select/decklist', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            if(res.data.length){
                this.deck_list = res.data
                this.deck_list.forEach(e => {
                    e.deck_price = this.$toComma(e.deck_price)
                })
                this.first_deck_list_length = res.data.length
            }
        })
    },

    // 페이지 이동 전 확인
    beforeRouteLeave (to, from, next) {
        if(!this.isPass){
            const answer = window.confirm("지금 작성하고 계신 글이 저장되지 않을 수 있습니다.\n페이지를 이동하시겠습니까?")
            if (answer) {
                next()
            } else {
                next(false)
            }
        }else{
            next()
        }
    },

    computed:{
        // 총 가격
        totalPrice(){
            let sum = 0
            this.card_list.forEach(e => {
                if(e.card_price != null){
                    sum += parseInt(this.$toUnComma(e.card_price))
                }
            })

            return Math.round(sum)
        }
    },

    methods: {
        card_state_changed(item){
            if(item.card_state != 'PSA 등급' && item.card_state != '기타'){
                item.card_state2 = "해당없음"
            }else{
                item.card_state2 = ""
            }
        },

        // 사진 등록 열기
        openUpload(type, index){
            this.selected_type = type
            this.selected_index = index
            this.dialog.upload = true
        },

        // 사진 등록 취소
        cancelUpload(){
            this.selected_type = ""
            this.selected_index = null
            this.dialog.upload = false
        },

        // 사진 등록 확인
        submitUpload(){
            let formData = new FormData()
            formData.append("image", this.imageFile)

            if(this.selected_type == "card_list"){
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "trade/card"
                    }
                }).then((res) => {
                    this.card_list[this.selected_index].add_image = res.data
                })
            }
            else if(this.selected_type == "etc_list"){
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "trade/etc"
                    }
                }).then((res) => {
                    this.etc_list[this.selected_index].etc_image = res.data
                })
            }
            else if(this.selected_type == "deck_list"){
                this.$http.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "trade/deck"
                    }
                }).then((res) => {
                    this.deck_list[this.selected_index].deck_image = res.data
                })
            }

            this.imageFile = {}
            this.dialog.upload = false
        },

        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        // 카드 선택창 열기
        searchCard(index){
            this.selectedIndex = index
            this.dialog.cardList = true
        },

        // 덱 선택창 열기
        searchDeck(index){
            this.selectedIndex = index
            this.dialog.deckList = true
        },

        // 덱레시피 입력
        DeckSelected(item) {
            this.deck_list[this.selectedIndex].deck_id = item.id
            this.deck_list[this.selectedIndex].deck_name = item.title
            this.dialog.deckList = false
        },

        // 카드 추가
        addField(){
            this.card_list.push(
                {
                    add_image: null,
                    card_view_name: "",
                    card_id: "",
                    card_nation: "",
                    card_name: "",
                    card_state: "",
                    card_state2: "",
                    card_price: null
                }
            )
        },

        // 카드 빼기
        subtractField(index){
            this.card_list.splice(index,1)
        },

        // 카드 복사
        copyField(index){
            this.card_list.push(
                {
                    add_image: this.card_list[index].add_image,
                    card_view_name: this.card_list[index].card_view_name,
                    card_id: this.card_list[index].card_id,
                    card_nation: this.card_list[index].card_nation,
                    card_name: this.card_list[index].card_name,
                    card_state: this.card_list[index].card_state,
                    card_state2: this.card_list[index].card_state2,
                    card_price: this.card_list[index].card_price
                }
            )
        },

        // 카드 목록에서 카드 선택
        goToDetail(id){
            this.cardDetail_id = id
            this.dialog.cardDetail = true
        },

        // 선택한 카드 정보
        CardSelected(card, nation){
            if(nation == 'kr'){
                this.card_list[this.selectedIndex].card_view_name = card.kr.name + " (" + card.kr.id+")"
                this.card_list[this.selectedIndex].card_name = card.kr.name
            }else if(nation == 'en'){
                this.card_list[this.selectedIndex].card_view_name = card.en.name + " (" + card.en.id+")"
                this.card_list[this.selectedIndex].card_name = card.en.name
            }else if(nation == 'jp'){
                this.card_list[this.selectedIndex].card_view_name = card.jp.name + " (" + card.jp.id+")"
                this.card_list[this.selectedIndex].card_name = card.jp.name
            }

            this.card_list[this.selectedIndex].card_id = card.en.id
            this.card_list[this.selectedIndex].card_nation = nation
            this.dialog.cardList = false
            this.dialog.cardDetail = false
        },

        // 기타상품 추가
        addEtcField(){
            this.etc_list.push(
                {
                    etc_image: null,
                    etc_name: "",
                    etc_state: "",
                    etc_price: null
                }
            )
        },

        // 기타상품 빼기
        subtractEtcField(index){
            if(index < this.first_etc_list_length){
                alert("기존 등록한 기타상품은 삭제하실 수 없습니다.")
            }else{
                this.etc_list.splice(index,1)
            }
        },

        // 기타상품 복사
        copyEtcField(index){
            this.etc_list.push(
                {
                    etc_image: this.etc_list[index].etc_image,
                    etc_name: this.etc_list[index].etc_name,
                    etc_state: this.etc_list[index].etc_state,
                    etc_price: this.etc_list[index].etc_price
                }
            )
        },

        // 덱상품 추가
        addDeckField(){
            this.deck_list.push(
                {
                    deck_id: null,
                    deck_image: null,
                    deck_name: "",
                    deck_state: "",
                    deck_price: null
                }
            )
        },

        // 덱상품 빼기
        subtractDeckField(index){
            this.deck_list.splice(index,1)
        },

        // 덱상품 복사
        copyDeckField(index){
            this.deck_list.push(
                {
                    deck_image: this.deck_list[index].deck_image,
                    deck_name: this.deck_list[index].deck_name,
                    deck_state: this.deck_list[index].deck_state,
                    deck_price: this.deck_list[index].deck_price
                }
            )
        },

        // 카드목록 수정
        async cardUpdate(){
            // 기존 카드목록 줄 수와 동일한 줄까지는 UPDATE
            let card_list_for_update = this.card_list.slice(0, this.first_card_list_length)

            // UPDATE
            for(let i=0; i<card_list_for_update.length; i++){
                await this.$http.post('/api/trade/post/update/card', {
                    params: {
                        post_id : this.$route.query.id,
                        add_image: card_list_for_update[i].add_image,
                        card_id: card_list_for_update[i].card_id,
                        card_nation : card_list_for_update[i].card_nation,
                        card_name : card_list_for_update[i].card_name,
                        card_state : card_list_for_update[i].card_state === 'PSA 등급' ? 'PSA ' + card_list_for_update[i].card_state2 : (card_list_for_update[i].card_state === '기타' ? '기타 ' + card_list_for_update[i].card_state2 : card_list_for_update[i].card_state),
                        card_price : this.$toUnComma(card_list_for_update[i].card_price),
                        id : card_list_for_update[i].id
                    }
                })
            }

            // 기존 카드목록 줄 수 이상의 줄부터는 INSERT
            let card_list_for_insert = this.card_list.slice(this.first_card_list_length)

            // INSERT
            for(let i=0; i<card_list_for_insert.length; i++){
                await this.$http.post('/api/trade/post/insert/card', {
                    params: {
                        post_id : this.$route.query.id,
                        add_image: card_list_for_insert[i].add_image,
                        card_id: card_list_for_insert[i].card_id,
                        card_nation : card_list_for_insert[i].card_nation,
                        card_name : card_list_for_insert[i].card_name,
                        card_state : card_list_for_insert[i].card_state === 'PSA 등급' ? 'PSA ' + card_list_for_insert[i].card_state2 : (card_list_for_insert[i].card_state === '기타' ? '기타 ' + card_list_for_insert[i].card_state2 : card_list_for_insert[i].card_state),
                        card_price : this.$toUnComma(card_list_for_insert[i].card_price)
                    }
                })
            }
        },

        // 기타상품 수정
        async etcUpdate(){
            // 기존 기타상품목록 줄 수와 동일한 줄까지는 UPDATE
            let etc_list_for_update = this.etc_list.slice(0, this.first_etc_list_length)

            // UPDATE
            for(let i=0; i<etc_list_for_update.length; i++){
                await this.$http.post('/api/trade/post/update/etc', {
                    params: {
                        etc_image : etc_list_for_update[i].etc_image,
                        etc_name : etc_list_for_update[i].etc_name,
                        etc_state : etc_list_for_update[i].etc_state,
                        etc_price : this.$toUnComma(etc_list_for_update[i].etc_price),
                        id : etc_list_for_update[i].id
                    }
                })
            }

            // 기존 카드목록 줄 수 이상의 줄부터는 INSERT
            let etc_list_for_insert = this.etc_list.slice(this.first_etc_list_length)

            // INSERT
            for(let i=0; i<etc_list_for_insert.length; i++){
                await this.$http.post('/api/trade/post/insert/etc', {
                    params: {
                        post_id : this.$route.query.id,
                        etc_image : etc_list_for_insert[i].etc_image,
                        etc_name : etc_list_for_insert[i].etc_name,
                        etc_state : etc_list_for_insert[i].etc_state,
                        etc_price : this.$toUnComma(etc_list_for_insert[i].etc_price)
                    }
                })
            }
        },

        // 덱상품 수정
        async deckUpdate(){
            // 기존 덱상품목록 줄 수와 동일한 줄까지는 UPDATE
            let deck_list_for_update = this.deck_list.slice(0, this.first_deck_list_length)

            // UPDATE
            for(let i=0; i<deck_list_for_update.length; i++){
                await this.$http.post('/api/trade/post/update/deck', {
                    params: {
                        deck_id : deck_list_for_update[i].deck_id,
                        deck_image : deck_list_for_update[i].deck_image,
                        deck_name : deck_list_for_update[i].deck_name,
                        deck_state : deck_list_for_update[i].deck_state,
                        deck_price : this.$toUnComma(deck_list_for_update[i].deck_price),
                        id : deck_list_for_update[i].id
                    }
                })
            }

            // 기존 카드목록 줄 수 이상의 줄부터는 INSERT
            let deck_list_for_insert = this.deck_list.slice(this.first_deck_list_length)

            // INSERT
            for(let i=0; i<deck_list_for_insert.length; i++){
                await this.$http.post('/api/trade/post/insert/deck', {
                    params: {
                        post_id : this.$route.query.id,
                        deck_id : deck_list_for_insert[i].deck_id,
                        deck_image : deck_list_for_insert[i].deck_image,
                        deck_name : deck_list_for_insert[i].deck_name,
                        deck_state : deck_list_for_insert[i].deck_state,
                        deck_price : this.$toUnComma(deck_list_for_insert[i].deck_price)
                    }
                })
            }
        },

        // 거래글 수정
        async submit(){
            let checkCardListEmpty = this.card_list.filter( 
                e => e.card_id == "" || e.card_price == null || e.card_state == ""
            )

            if(!this.write.title.length)
            {
                alert("제목을 입력해주세요")
            }
            else if(!this.content_count)
            {
                alert("내용을 입력해주세요")
            }
            else if(!this.write.trade_type)
            {
                alert("거래유형을 선택해주세요")
            }
            else if(!this.write.trade_way)
            {
                alert("거래방법을 선택해주세요")
            }
            else if(this.write.delivery_price == null || this.write.delivery_price == "")
            {
                alert("배송비를 입력해주세요")
            }
            else if(!this.write.seller_address)
            {
                alert("판매자 주소를 선택해주세요")
            }
            else if(this.first_card_list_length !=0 && checkCardListEmpty.length && !this.write.is_simple)
            {
                alert("카드목록 중 입력되지 않은 값이 있습니다")
            }
            else{
                this.write.writer = this.$store.state.user_id
                this.write.content = this.options.content
                this.write.delivery_price = this.$toUnComma(this.write.delivery_price)

                // 로딩 시작
                this.loading = true

                await this.cardUpdate()
                await this.etcUpdate()
                await this.deckUpdate()

                // 포인트 지급 & 회수
                if(this.card_list.length > this.first_card_list_length)
                {
                    let minus_amount = this.card_list.length - this.first_card_list_length

                    // 포인트 적립
                    PointModule.amountCheck('카드거래', '거래글 등록 카드 1개당').then((res) => {
                        PointModule.Update(this.$store.state.user_id, res.data[0].amount*minus_amount)
                        PointModule.Record(this.$store.state.user_id, '카드거래', '거래글 등록 카드 1개당', this.write.title, res.data[0].amount*minus_amount)
                    })
                }
                else if(this.card_list.length < this.first_card_list_length)
                {
                    let plus_amount = this.first_card_list_length - this.card_list.length

                    // 포인트 회수
                    PointModule.amountCheck('카드거래', '거래글 등록 카드 1개당').then((res) => {
                        PointModule.Update(this.$store.state.user_id, -res.data[0].amount*plus_amount)
                        PointModule.Record(this.$store.state.user_id, '카드거래', '거래글 등록 카드 1개당', this.write.title+' (취소)', -res.data[0].amount*plus_amount)
                    })
                }

                // 거래글 수정
                this.$http.post('/api/trade/post/update', {
                    params: {
                        write: this.write,
                        id: this.$route.query.id
                    }
                }).then(() => {
                    this.isPass = true

                    // DB 알림
                    this.$dbAlarm(
                        "카드거래 - 거래글",
                        this.$store.state.nickname,
                        this.$store.state.user_id
                    )

                    // 로딩 종료
                    this.loading = false

                    // 거래글 읽기로 이동
                    this.$router.push("/trade/post/read?id="+this.$route.query.id)
                })
            }
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}
</style>