<template>
    <div>
        <table class="cardListTable">
            <tr style="border-top:none;">
                <td colspan="6">
                    <v-sheet class="d-flex justify-end py-1">
                        <!-- 총 수량 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            총 수량
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{card_list.length}}개</span>
                        </p>

                        <!-- 총 가격 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            총 가격
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(totalSum)}}원</span>
                        </p>
                    </v-sheet>
                </td>
            </tr>
            <tr>
                <th>사진</th>
                <th>이미지</th>
                <th>이름</th>
                <th>상태</th>
                <th>가격</th>
                <th>선택</th>
            </tr>
            <tr v-for="item in card_list" :key="item.id">
                <td width="80" class="pl-3">
                    <v-img
                        @click="selected_item = item; dialog.image = true"
                        style="cursor:pointer;"
                        width="48"
                        :src="'/upload/trade/card/'+item.add_image"
                    ></v-img>
                </td>
                <td width="76" style="cursor:pointer" @click="goToDetail(item.card_id)">
                    <!-- <v-img width="48" src="@/assets/default.png"></v-img> -->
                    <v-img
                        v-if="item.card_nation=='en'" 
                        width="48" 
                        :src="'/upload/card_en_image/'+(item.image_en==null? 'default.png':encodeURI(item.image_en))"
                    ></v-img>
                    <v-img 
                        v-if="item.card_nation=='kr'"
                        width="48" 
                        :src="'/upload/card_kr_image/'+(item.image_kr==null? 'default.png':encodeURI(item.image_kr))"
                    ></v-img>
                    <v-img 
                        v-if="item.card_nation=='jp'" 
                        width="48" 
                        :src="'/upload/card_jp_image/'+(item.image_jp==null? 'default.png':encodeURI(item.image_jp))"
                    ></v-img>
                </td>
                <td style="cursor:pointer" @click="goToDetail(item.card_id)">
                    <p class="mb-0">{{item.card_name}}</p>
                    <p class="mb-0 text-caption">
                        <v-icon small style="margin-bottom:2px;" class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                        <font v-if="item.card_nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                        <font v-if="item.card_nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                        <font v-if="item.card_nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                        <font v-if="item.card_nation=='en' && item.printedTotal"> / {{item.printedTotal.replaceAll("\"", "")}}</font>
                        <font v-if="item.card_nation=='kr' && item.set_printedTotal_kr"> / {{item.set_printedTotal_kr}}</font>
                        <font v-if="item.card_nation=='jp' && item.set_printedTotal_jp"> / {{item.set_printedTotal_jp}}</font>
                        <span class="ml-2">
                            <font v-if="item.card_nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                            <font v-if="item.card_nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                            <font v-if="item.card_nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                        </span>
                    </p>
                    <p v-if="item.card_nation=='en' && item.set_en" class="text-caption ml-1 mb-0">{{item.set_en}}</p>
                    <p v-if="item.card_nation=='kr' && item.set_kr" class="text-caption ml-1 mb-0">{{item.set_kr}}</p>
                    <p v-if="item.card_nation=='jp' && item.set_jp" class="text-caption ml-1 mb-0">{{item.set_jp}}</p>
                </td>
                <td width="120" class="text-center">{{item.card_state}}</td>
                <td width="200" class="text-center">{{$toComma(item.card_price)}}원</td>
                <td width="80">
                    <v-sheet class="d-flex justify-center">
                        <v-checkbox
                            v-if="item.state=='등록됨'"
                            class="ml-3"
                            v-model="selected"
                            :value="item"
                            @change="emitSelected()"
                        ></v-checkbox>
                         <p
                            v-if="item.state=='거래종료'"
                            class="my-0 grey--text text-body-2"
                            style="letter-spacing:-1px !important;"
                        >
                            거래종료
                        </p>
                        <p
                            v-if="item.state=='거래진행중'"
                            class="my-0 grey--text text-body-2"
                            style="letter-spacing:-1px !important;"
                        >
                            거래진행중
                        </p>
                        <p
                            v-if="item.state=='판매완료'"
                            class="my-0 grey--text text-body-2"
                        >
                            판매완료
                        </p>
                    </v-sheet>
                </td>
            </tr>
            <tr>
                <td colspan="4" class="py-0">
                    <v-sheet class="d-flex py-1 ml-2">
                        <!-- 총 수량 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            선택된 카드 총 수량
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{selected.length}}개</span>
                        </p>

                        <!-- 총 가격 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            선택 된 카드 총 가격
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(selectedSum)}}원</span>
                        </p>

                        <!-- 배송비 -->
                        <p class="mb-0 mr-5" style="font-size:13px;">
                            배송비
                            <span class="ml-1 font-weight-bold" style="font-size:13px; color:#615DFA;">{{$toComma(delivery_price)}}원</span>
                        </p>
                    </v-sheet>
                </td>
                <td class="py-0 text-center">
                    <span class="text-body-2 font-weight-medium">전체선택/취소</span>
                </td>
                <td class="py-0">
                    <v-sheet class="d-flex justify-center">
                    <v-checkbox
                        class="ml-3"
                        @change="selectAll(); emitSelected()"
                    ></v-checkbox>
                    </v-sheet>
                </td>
            </tr>
        </table>

        <!-- 사진 확대 (dialog) -->
        <v-dialog
            v-model="dialog.image"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                class="pa-2 rounded-15"
            >
                <v-img
                    class="rounded-10"
                    contain
                    max-width="1000"
                    :src="'/upload/trade/card/'+selected_item.add_image"
                ></v-img>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: [
        "delivery_price"
    ],
    data: () => ({
        card_list: [],
        selected: [],

        dialog: {
            image: false
        },

        selected_item: {}
    }),

    mounted() {
        this.$http.post('/api/trade/post/read/import/cardList', {
            params: {
                post_id : this.$route.query.id
            }
        }).then(res => {
            this.card_list = res.data
        })
    },

    computed:{
        selectedSum(){
            let sum = 0
            this.selected.forEach(e => {
                sum +=  e.card_price
            })

            return Math.round(parseInt(sum))
        },

        totalSum(){
            let sum = 0
            this.card_list.forEach(e => {
                sum +=  e.card_price
            })

            return Math.round(parseInt(sum))
        }
    },

    methods: {
        // 전체선택/취소
        selectAll(){
            if(this.selected.length){
                this.selected = []
            }else{
                this.selected = this.card_list.filter(e => e.state=='등록됨')
            }
        },

        // 선택값 Emit
        emitSelected(){
            this.$emit("selected", this.selected.map(e => e.id))
        },

        // 상세 페이지 이동
        goToDetail(id){
            window.open('/card/detail?id='+id, '_blank') //새 탭
        }
    }
}
</script>
<style scoped>
.cardListTable{
    width:100%;
    border-collapse: collapse;
}

.cardListTable tr{
    border-top:1px solid #e0e0e0;
}

.cardListTable tr:last-child{
    border-bottom:1px solid #e0e0e0;
}

.cardListTable tr td{
    padding:8px;
}

.cardListTable tr th{
    height:70px;
    font-size:16px;
   
    padding:8px;
}
</style>