<template>
    <div>
        <!-- 검색조건 -->
        <v-sheet class="pa-6 mt-2 rounded-10">
            <v-sheet class="d-flex justify-space-between mb-6">
                <!-- 거래유형 -->
                <v-sheet width="24%">
                    <v-select
                        height="49"
                        class="mr-1 rounded-15 font-weight-bold"
                        dense
                        :items="select_list.product_type"
                        v-model="keyword.product_type"
                        label="상품유형(중복)"
                        placeholder="전체"
                        persistent-placeholder
                        multiple
                        hide-details
                        outlined
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </v-sheet>

                <!-- 거래유형 -->
                <v-sheet width="24%">
                    <v-select
                        height="49"
                        class="mr-1 rounded-15 font-weight-bold"
                        dense
                        :items="select_list.trade_type"
                        v-model="keyword.trade_type"
                        label="거래유형(중복)"
                        placeholder="전체"
                        persistent-placeholder
                        multiple
                        hide-details
                        outlined
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </v-sheet>

                <!-- 거래방법 -->
                <v-sheet width="24%">
                    <v-select
                        :height="keyword.trade_way.length>3? '100':'49'"
                        class="mx-1 rounded-15 font-weight-bold"
                        dense
                        :items="select_list.trade_way"
                        v-model="keyword.trade_way"
                        label="거래방법(중복)"
                        placeholder="전체"
                        persistent-placeholder
                        multiple
                        hide-details
                        outlined
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </v-sheet>

                <!-- 카드상태 -->
                <v-sheet width="24%">
                    <v-select
                        :height="keyword.trade_state.length>3? '100':'49'"
                        class="ml-1 rounded-15 font-weight-bold"
                        dense
                        :items="select_list.trade_state"
                        v-model="keyword.trade_state"
                        label="카드상태(중복)"
                        placeholder="전체"
                        persistent-placeholder
                        multiple
                        hide-details
                        outlined
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </v-sheet>
            </v-sheet>

            <v-sheet class="d-flex">
                <!-- 검색 항목 -->
                <v-sheet width="189">
                    <v-select
                        height="49"
                        class="mr-4 rounded-15 font-weight-bold"
                        dense
                        :items="select_list.text_type"
                        v-model="keyword.text_type"
                        label="검색 항목"
                        hide-details
                        outlined
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-1 mr-1" color="#424361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </v-sheet>

                <!-- 검색어 -->
                <v-text-field
                    dense
                    outlined
                    hide-details
                    v-model="keyword.text"
                    placeholder="검색어를 입력하세요"
                    class="rounded-15 font-weight-bold"
                    height="49"
                    @keyup.enter="searchPost()"
                >
                    <template v-slot:append>
                        <v-icon class="pt-1 mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-4 rounded-10"
                    style="font-size:18px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="49"
                    @click="searchPost()"
                >
                    검색
                </v-btn>

                <!-- 초기화 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="ml-2 rounded-10"
                    style="font-size:18px; font-weight:bold; letter-spacing:-0.6px;"
                    color="primary"
                    width="86"
                    height="49"
                    @click="keywordReset()"
                >
                    초기화
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 목록 상단 -->
        <div class="px-4 pt-4 pb-2">
            <table>
                <tr>
                    <td class="text-center" width="120px" style="font-size:16px; font-weight:bold;">매너점수</td>
                    <td class="text-center" width="118px" style="font-size:16px; font-weight:bold;">거래</td>
                    <td class="text-center" width="138px" style="font-size:16px; font-weight:bold;">가격</td>
                    <td class="text-center" width="468px" style="font-size:16px; font-weight:bold;">제목</td>
                </tr>
            </table>
        </div>

        <!-- 목록 -->
        <v-sheet class="pa-4 pt-2 mt-2 rounded-10">
            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mx-auto my-10"
                size="80"
            ></v-progress-circular>
            
            <v-data-table
                v-if="!loading"
                :items="list"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                hide-default-header
                hide-default-footer
                no-data-text="거래글이 존재하지 않습니다."
            >
                <template v-slot:item="{ item }">
                    <tr 
                        class="pa-0 ma-0" 
                        style="cursor:pointer;" 
                        @click="read(item)"
                    >
                        <td width="80px">
                            <!-- 매너점수 -->
                            <v-sheet color="transparent">
                                <!-- 리뷰 평균평점 -->
                                <v-rating
                                    :value="item.review_rating"
                                    x-small
                                    readonly
                                    background-color="primary"
                                ></v-rating>

                                <!-- 리뷰 수, 거래완료 수 -->
                                <p class="text-caption primary--text my-0">
                                    ({{item.review_count}} / {{item.finish_count}})
                                </p>
                            </v-sheet>
                        </td>
                        <td width="130px">
                            <p class="mt-1 mb-1">{{item.trade_type}}</p>
                            <p class="mb-0">{{item.trade_way}}</p>
                        </td>
                        <td width="110px">
                            <div v-if="item.count">
                                <p v-if="item.total_price" style="width:90px;" class="mb-0 text-truncate">{{$toComma(item.total_price)}}원</p>
                                <p class="mb-0">{{item.count}}개 카드</p>
                            </div>
                            <div v-else-if="item.deck_count">
                                <p v-if="item.deck_total_price" style="width:90px;" class="mb-0 text-truncate">{{$toComma(item.deck_total_price)}}원</p>
                                <p class="mb-0">{{item.deck_count}}개 덱</p>
                            </div>
                            <div v-else-if="item.etc_count">
                                <p v-if="item.deck_total_price" style="width:90px;" class="mb-0 text-truncate">{{$toComma(item.etc_total_price)}}원</p>
                                <p class="mb-0">{{item.etc_count}}개 서플라이</p>
                            </div>
                        </td>
                        <td width="460px">
                            <p :class="item.status=='close'? 'grey--text':''" class="mt-1 mb-1 text-subtitle-1">
                                <v-chip :disabled="item.status=='close'" dark color="primary" small style="margin-bottom:2px;" class="mr-1">
                                    <span class="white--text">{{item.card_state}}</span>
                                </v-chip>
                                <font v-if="item.status=='close'" class="mr-1">[판매종료]</font>
                                {{item.title}}
                            </p>
                            <v-sheet class="d-flex align-center mb-2" color="transparent">
                                <Profile v-if="item.user_id" :user="item.user_id" :color="'black'"/>
                                <v-divider vertical class="mx-2"></v-divider>
                                <p class="mb-0 text-caption">
                                    {{
                                        new Date().toLocaleDateString() == new Date(item.created).toLocaleDateString()?
                                        new Date(item.created).toLocaleTimeString() : new Date(item.created).toLocaleDateString()
                                    }}
                                </p>
                            </v-sheet>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-divider></v-divider>

            <!-- 버튼 & 페이지네이션 -->
            <v-sheet class="mt-3 d-flex align-center justify-center">
                <!-- 내 거래글 -->
                <v-btn
                    width="116"
                    height="40"
                    class="rounded-10"
                    style="font-size:16px; font-weight:bold;"
                    color="#ADAFCA"
                    dark
                    depressed
                    @click="searchMyPost()"
                >
                    내 거래글
                </v-btn>

                <!-- 거래중 -->
                <v-btn
                    width="116"
                    height="40"
                    class="ml-3 rounded-10"
                    style="font-size:16px; font-weight:bold;"
                    color="#ADAFCA"
                    dark
                    depressed
                    @click="searchMyTradePost()"
                >
                    거래중
                </v-btn>
                <v-spacer></v-spacer>

                <!-- 페이징 -->
                <v-pagination
                    color="primary"
                    v-model="page"
                    :length="pageCount"
                    :total-visible="5"
                ></v-pagination>
                <v-spacer></v-spacer>

                <div
                    width="102"
                    height="40"
                    class="rounded-10"
                >
                </div>

                <!-- 거래글 등록 -->
                <v-btn
                    width="142"
                    height="40"
                    class="rounded-10"
                    style="font-size:16px; font-weight:bold; letter-spacing:-0.8px;"
                    color="primary"
                    dark
                    depressed
                    @click="write()"
                >
                    거래글 등록
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- 로딩 -->
        <!-- <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 30%; left: 50%; transform: translate(-50%, -50%);"
            indeterminate
            color="primary"
            size="80"
        ></v-progress-circular> -->
    </div>
</template>
<script>
import Profile from "@/components/Profile"

export default {
    components: {
        Profile
    },
    
    data: () => ({
        // 선택 목록
        select_list: {
            product_type: ["싱글카드", "서플라이", "덱"],
            trade_type: ["시세등록", "팝니다", "삽니다"],
            trade_way: ["등기(준등기)", "택배", "직거래", "기타"],
            trade_state: ["기타", "S급", "A급", "B급", "C급 이하", "등급", "서플라이 제품"],
            text_type: ["제목", "내용", "판매자", "카드이름"]
        },

        // 키워드
        keyword: {
            product_type: [],
            trade_type: [],
            trade_way: [],
            trade_state: [],
            text_type: "제목",
            text: ""
        },

        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,

        // 내 배송지
        myAddressDefault: [],
        loading: false
    }),

    watch: {
        page(newVal) {
            this.$router.replace({ query: { ...this.$route.query, page: newVal } })
            window.scrollTo(0, 0)
        },
    },

    mounted() {
        // 목록
        this.load()

        // 내 배송지가 있는지
        this.isMyAddressExist()
    },

    methods: {
        // 목록
        load(){
            this.loading = true
            // 카드별 > 카드 목록
            if(this.$route.query.card_id && this.$route.query.card_nation){
                this.$http.post('/api/trade/post/list/searchByCard', {
                    params: {
                        card_id: this.$route.query.card_id,
                        card_nation: this.$route.query.card_nation
                    }
                }).then(res => {
                    this.list = Array.from(new Set(res.data))

                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }

                    this.loading = false
                })
            // 기본 목록
            }else{
                this.$http.post('/api/trade/post/list')
                .then(res => {
                    this.list = res.data

                    // 주소 page 불러오기
                    if(this.$route.query.page){
                        this.page = parseInt(this.$route.query.page)
                    }

                    this.loading = false
                })
            }
        },

        // 내 배송지가 있는지
        isMyAddressExist(){
            this.$http.post('/api/shipping/select', {
                params: {
                    user_id: this.$store.state.user_id
                }
            }).then(res => {
                this.myAddressDefault = res.data.filter(e => e.selected == "기본")
            })
        },

        // 검색
        searchPost(){
            this.loading = true
            this.$http.post('/api/trade/post/list/search', {
                params: {
                    keyword: this.keyword
                }
            }).then(res => {
                this.loading = false
                this.list = res.data
            })
        },

        // 검색조건 초기화
        keywordReset(){
            this.loading = true
            Object.assign(this.$data.keyword, this.$options.data().keyword)

            this.$http.post('/api/trade/post/list')
            .then(res => {
                this.list = res.data
                this.loading = false
            })
        },

        // 내 거래글
        searchMyPost(){
            this.loading = true
            if(!this.$store.state.is_logined){
                alert("로그인 후 이용 가능합니다.")
                this.loading = false
            }else{
                this.$http.post('/api/trade/post/list/searchMyPost', {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    let check = this.$store.state.type=='관리자'? res.data.length-1 : res.data.length // root 관리자 입력 예외처리
                    if(check){
                        this.list = res.data
                    }else{
                        alert("작성하신 거래글이 없습니다.")
                    }
                    this.loading = false
                })
            }
        },

        // 거래중
        searchMyTradePost(){
            this.loading = true
            if(!this.$store.state.is_logined){
                alert("로그인 후 이용 가능합니다.")
                this.loading = false
            }else{
                this.$http.post('/api/trade/post/list/searchMyTradePost', {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then(res => {
                    if(!res.data.length){
                        alert("거래중인 거래글이 없습니다.")
                    }else{
                        this.list = res.data
                    }
                    this.loading = false
                })
            }
        },

        // 거래글 등록
        write(){
            if(!this.$store.state.is_logined){
                alert("글쓰기는 로그인 후에만 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 밴 기간동안 글쓰기가 제한됩니다.')
            }else{
                if(!this.myAddressDefault.length) {
                    if(confirm("판매글 작성시 기본 배송지가 설정되어 있어야합니다.\n확인을 눌러 배송지관리에서 기존 배송지를 추가해주세요.")){
                        this.$router.push("/mypage/address")
                    }
                }else{
                    this.$router.push("/trade/post/write")
                }
            }
        },

        // 읽기
        read(item){
            this.$router.push("/trade/post/read?id="+item.id)
        }
    }
}
</script>
<style scoped>
/* 입력란 기본 테두리 색상 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 활성화 테두리 색상 */
.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* pagination (페이징) */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>