<template>
    <div class="rounded-15 pt-2 pb-4 px-8">
        <!-- 제목 -->
        <v-sheet class="pb-2">
            <p
                class="font-weight-bold py-4 ma-0 text-center"
                style="font-size:26px;"
            >
                선택한 상품 거래 신청
            </p>
        </v-sheet>

        <!-- 기타상품 목록 -->
        <table class="cardList mb-10">
            <tr>
                <th>이미지</th>
                <th>기타상품명</th>
                <th>상태</th>
                <th>가격</th>
            </tr>
            <tr v-for="item in etc_list" :key="item.id">
                <td width="76">
                    <v-img
                        width="48"
                        :src="'/upload/trade/etc/'+item.etc_image"
                    ></v-img>
                </td>
                <td class="text-center">
                    <p class="mb-0">{{item.etc_name}}</p>
                </td>
                <td width="120" class="text-center">{{item.etc_state}}</td>
                <td width="200" class="text-center">{{$toComma(item.etc_price)}}원</td>
            </tr>
            <tr>
                <th colspan="5" class="text-end">
                    <p class="text-right mr-2" style="font-size:13px;">
                        <span class="mr-2">총 수량</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{etc_list.length}}개</span>

                        <span class="mr-2">총 가격</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{$toComma(selectedEtcSum)}}원</span>
                    </p>
                </th>
            </tr>
        </table>

        <!-- 덱 상품 목록 -->
        <table class="cardList mb-10">
            <tr>
                <th>사진</th>
                <th>이미지</th>
                <th>이름</th>
                <th>상태</th>
                <th>가격</th>
                <th>선택</th>
            </tr>
            <tr v-for="item in deck_list" :key="item.id">
                <td width="80" class="pl-3">
                    <v-img
                        @click="selected_item = item; dialog.image = true"
                        style="cursor:pointer;"
                        width="48"
                        :src="'/upload/trade/deck/'+item.deck_image"
                    ></v-img>
                </td>
                <td width="76" style="cursor:pointer" @click="goToDetail(item.deck_id)">
                    <!-- <v-img width="48" src="@/assets/default.png"></v-img> -->
                    <v-img
                        v-if="item.deck_nation=='en'" 
                        width="48" 
                        :src="'/upload/card_en_image/'+(item.main_pokemon_image==null? 'default.png':encodeURI(item.main_pokemon_image))"
                    ></v-img>
                    <v-img 
                        v-if="item.deck_nation=='kr'"
                        width="48" 
                        :src="'/upload/card_kr_image/'+(item.main_pokemon_image==null? 'default.png':encodeURI(item.main_pokemon_image))"
                    ></v-img>
                    <v-img 
                        v-if="item.deck_nation=='jp'" 
                        width="48" 
                        :src="'/upload/card_jp_image/'+(item.main_pokemon_image==null? 'default.png':encodeURI(item.main_pokemon_image))"
                    ></v-img>
                </td>
                <td style="cursor:pointer" @click="goToDetail(item.deck_id)">
                    <p class="mb-0">{{item.deck_name}}</p>
                </td>
                <td width="120" class="text-center">{{item.deck_state}}</td>
                <td width="200" class="text-center">{{$toComma(item.deck_price)}}원</td>
                <td width="80">
                    <v-sheet class="d-flex justify-center">
                        <v-checkbox
                            v-if="item.state=='등록됨'"
                            class="ml-3"
                            v-model="selected"
                            :value="item"
                            @change="emitSelected()"
                        ></v-checkbox>
                        <p
                            v-if="item.state=='거래진행중'"
                            class="my-0 grey--text text-body-2"
                            style="letter-spacing:-1px !important;"
                        >
                            거래진행중
                        </p>
                        <p
                            v-if="item.state=='판매완료'"
                            class="my-0 grey--text text-body-2"
                        >
                            판매완료
                        </p>
                    </v-sheet>
                </td>
            </tr>
        </table>

        <!-- 카드 목록 -->
        <table class="cardList">
            <tr>
                <th>이미지</th>
                <th>이름</th>
                <th>상태</th>
                <th>가격</th>
            </tr>
            <tr v-for="item in card_list" :key="item.id">
                <td width="76" style="cursor:pointer">
                    <v-img
                        v-if="item.card_nation=='en' && item.image_en" 
                        width="48" 
                        :src="'/upload/card_en_image/'+encodeURI(item.image_en)"
                    ></v-img>
                    <v-img 
                        v-if="item.card_nation=='kr' && item.image_kr"
                        width="48" 
                        :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)"
                    ></v-img>
                    <v-img 
                        v-if="item.card_nation=='jp' && item.image_jp" 
                        width="48" 
                        :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)"
                    ></v-img>
                </td>
                <td class="text-center" style="cursor:pointer">
                    <p class="mb-0">{{item.card_name}}</p>
                    <p class="mb-0 text-caption">
                        <v-icon small style="margin-bottom:2px;" class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                        <font v-if="item.card_nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                        <font v-if="item.card_nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                        <font v-if="item.card_nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                        <font v-if="item.card_nation=='en' && item.printedTotal"> / {{item.printedTotal.replaceAll("\"", "")}}</font>
                        <font v-if="item.card_nation=='kr' && item.set_printedTotal_kr"> / {{item.set_printedTotal_kr}}</font>
                        <font v-if="item.card_nation=='jp' && item.set_printedTotal_jp"> / {{item.set_printedTotal_jp}}</font>
                    </p>
                    <p v-if="item.card_nation=='en' && item.set_en" class="text-caption ml-1 mb-0">{{item.set_en}}</p>
                    <p v-if="item.card_nation=='kr' && item.set_kr" class="text-caption ml-1 mb-0">{{item.set_kr}}</p>
                    <p v-if="item.card_nation=='jp' && item.set_jp" class="text-caption ml-1 mb-0">{{item.set_jp}}</p>
                </td>
                <td width="120" class="text-center">{{item.card_state}}</td>
                <td width="200" class="text-center">{{$toComma(item.card_price)}}원</td>
            </tr>
            <tr>
                <th colspan="5" class="text-end">
                    <p class="text-right mr-2" style="font-size:13px;">
                        <span class="mr-2">거래방법</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{trade_way}}</span>

                        <span class="mr-2">주소</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{seller_address.split(' ').slice(0, 3).join(' ')}}</span>

                        <span class="mr-2">총 수량</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{card_list.length}}개</span>

                        <span class="mr-2">총 가격</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{$toComma(selectedSum)}}원</span>

                        <span class="mr-2">배송비</span>
                        <span class="font-weight-bold mr-4" style="color:#615DFA;">{{delivery_price}}원</span>
                    </p>
                </th>
            </tr>
        </table>

        <!-- 입력란 -->
        <v-sheet class="mt-4 mb-4">
            <v-select
                class="rounded-10 font-weight-bold"
                hide-details
                dense
                outlined
                placeholder="배송지 목록"
                :items="select_list.shipping_address"
                v-model="shipping_address"
            ></v-select>
            <v-textarea
                class="mt-2 rounded-10"
                outlined
                hide-details
                placeholder="메세지"
                v-model="content"
            ></v-textarea>
        </v-sheet>

        <!-- 버튼 -->
        <v-sheet class="px-1 d-flex justify-center">
            <v-btn
                height="49"
                width="140"
                class="mx-2 rounded-10"
                color="#ADAFCA"
                dark
                depressed
                @click="cancel()"
            >
                취소하기
            </v-btn>
            <v-btn
                height="49"
                width="140"
                class="mx-2 rounded-10"
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                거래신청하기
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
export default {
    props: [
        "trade_way",
        "seller_address",
        "delivery_price",
        "selected_etc_list",
        "selected_card_list",
        "selected_deck_list",
        "seller"
    ],

    data: () => ({
        etc_list: [],
        card_list: [],
        deck_list: [],

        select_list: {
            shipping_address: []
        },

        shipping_address: "",
        content: "",

        etc_sum: 0,
        sum: 0
    }),

    mounted(){
        this.etc_list = this.selected_etc_list
        this.deck_list = this.selected_deck_list

        // 선택한 카드목록 가져오기
        this.$http.post('/api/trade/post/read/import/cardList/selected', {
            params: {
                ids : this.selected_card_list
            }
        }).then(res => {
            if(res.data.length){
                this.card_list = res.data
                this.card_list.forEach(e => {
                    this.sum += e.card_price
                })
            }
        })

        // 내 배송지 목록 가져오기
        this.$http.post('/api/shipping/select', {
            params: {
                user_id: this.$store.state.user_id
            }
        }).then(res => {
            this.select_list.shipping_address = res.data.map(e => e.address)
        })
    },

    computed:{
        selectedEtcSum(){
            let sum = 0

            this.etc_list.forEach(e => {
                sum +=  e.etc_price
            })

            return Math.round(parseInt(sum))
        },

        selectedSum(){
            let sum = 0

            this.card_list.forEach(e => {
                sum +=  e.card_price
            })

            return Math.round(parseInt(sum))
        }
    },

    watch: {
        // 선택한 카드목록 가져오기
        selected_list(){
            this.$http.post('/api/trade/post/read/import/cardList/selected', {
                params: {
                    ids : this.selected_card_list
                }
            }).then(res => {
                this.card_list = res.data
            })
        }
    },
    
    methods: {
        // 취소하기
        cancel(){
            this.$emit("cancel")
        },

        // 거래신청하기
        submit(){
            // 거래정보 입력
            this.$http.post('/api/trade/post/read/apply/submit', {
                params: {
                    post_id: this.$route.query.id,
                    trade_post_card_ids: this.card_list.map(a => a.id).toString(),
                    buyer: this.$store.state.user_id,
                    seller: this.seller,
                    shipping_address: this.shipping_address,
                    content: this.content
                }
            }).then(res => {
                // 메세지 입력
                this.$http.post('/api/message/insert', {
                    params: {
                        sender: this.$store.state.user_id,
                        reciever: this.seller,
                        type: "order",
                        order_id: res.data.insertId
                    }
                }).then(res => {
                    alert("거래신청이 완료되었습니다.")
                    this.$router.push("/mypage/message")
                })

                // DB 알림
                this.$dbAlarm(
                    "카드거래 - 거래",
                    this.$store.state.nickname,
                    this.$store.state.user_id
                )

                // 판매자에게 거래신청 알림
                this.$http.post('/api/alarm/insert', {
                    params: {
                        user_id: this.seller,
                        sender: this.$store.state.user_id,
                        type: "trade_requested"
                    }
                })
            })
        }
    }
}
</script>
<style scoped>
.cardList{
    width:100%;
    border-collapse: collapse;
}

.cardList tr{
    border-top:1px solid #e0e0e0;
}

.cardList tr:last-child{
    border-bottom:1px solid #e0e0e0;
}

.cardList tr td{
    padding:8px;
}

.cardList tr th{
    height:70px;
    font-size:16px;
   
    padding:8px;
}
</style>