<template>
    <div>
        <!-- 상단 & 검색 & 버튼 (Mobile) -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mobile"
        >
            <!-- 제목 & 상세검색 & 초기화 & 작성하기 -->
            <div
                class="d-flex justify-space-between px-4 pt-3 pb-2"
            >
                <!-- 제목 -->
                <p
                    style="font-size:16px; font-weight:bold;"
                    class="mb-0"
                    @click="search()"
                >
                    덱 레시피
                </p>

                <div
                    class="d-flex"
                >
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-size:12px;"
                        color="primary"
                        width="98"
                        height="28"
                        @click="mobile.search_detail_switch = !mobile.search_detail_switch"
                    >
                        <v-icon small class="mr-1">mdi-magnify</v-icon>
                        상세검색
                    </v-btn>

                    <v-btn
                        depressed
                        dark
                        x-small
                        class="rounded-10 ml-1 pa-0 custom-button"
                        style="font-size:12px;"
                        color="primary"
                        width="33"
                        height="28"
                    >
                        <v-icon small>mdi-refresh</v-icon>
                    </v-btn>

                    <v-btn
                        depressed
                        dark
                        x-small
                        class="rounded-10 ml-1 pa-0"
                        style="font-size:12px; width:33px !important;"
                        color="#615DFA"
                        height="28"
                        to="/deck/write"
                    >
                        <v-icon small>mdi-pencil-outline</v-icon>
                    </v-btn>
                </div>
            </div>

            <!-- 덱 이름 & 검색 버튼 -->
            <v-sheet
                v-if="!mobile.search_detail_switch"
                class="d-flex py-3 px-4 ma-0"
            >
                <!-- 덱 이름 -->
                <v-text-field
                    style="font-size:12px !important; height:33px;"
                    class="flex-grow rounded-10 font-weight-bold mr-2"
                    outlined
                    hide-details
                    dense
                    placeholder="검색어를 입력해주세요"
                    persistent-placeholder
                    v-model="keyword.text"
                    append-icon="mdi-magnify"
                    @keyup.enter="search(false)"
                >
                    <template v-slot:append>
                        <v-icon size="20" class="mt-2px pr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <!-- 검색 버튼 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 font-weight-bold"
                    style="font-size:13px;"
                    color="primary"
                    width="41"
                    height="33"
                    @click="search(false)"
                >
                    검색
                </v-btn>
            </v-sheet>

            <!-- 상세조건 (Mobile) -->
            <div
                v-if="$vuetify.breakpoint.mobile && mobile.search_detail_switch"
                class="pt-10 px-3 ma-2 mobile"
                style="position:relative; border:2px solid #f8468d;"
            >
                <v-icon style="position:absolute; top:12px; right:12px;" size="20" color="#F8468D" @click="mobile.search_detail_switch = false">mdi-close</v-icon>
                <!-- 키워드 검색 -->
                <v-sheet
                    class="rounded-10 pa-5"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">키워드 검색</span>

                    <div class="d-flex mt-3 mb-2">
                        <v-select
                            height="33"
                            style="flex: 1"
                            class="rounded-10 font-weight-bold"
                            dense
                            outlined
                            hide-details
                            :items="select_list.search_type"
                            v-model="keyword.search_type"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pb-1px">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                    <div class="d-flex">
                        <!-- TEXT -->
                        <v-text-field
                            height="34"
                            class="rounded-10"
                            outlined
                            hide-details
                            dense
                            placeholder="검색할 카드이름을 입력하세요"
                            persistent-placeholder
                            v-model="keyword.text"
                            append-icon="mdi-magnify"
                            @keyup.enter="search(false); mobile.search_detail_switch = false;"
                        >
                            <template v-slot:append>
                                <v-icon size="18" class="pt-1 pr-1px" color="#424361">mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </div>
                </v-sheet>

                <!-- 시즌으로 검색 -->
                <v-sheet
                    class="rounded-10 pa-5 mt-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">시즌으로 검색</span>

                    <div class="d-flex mt-3 mb-2">
                        <!-- 시리즈 -->
                        <v-select
                            height="34"
                            style="width:34%;"
                            class="rounded-10"
                            label="제품명 시즌"
                            dense
                            outlined
                            placeholder="전체"
                            persistent-placeholder
                            hide-details
                            :items="set_list"
                            v-model="keyword.season"
                            :menu-props="{ offsetY: true }"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-2 pb-1px">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 분류로 검색 -->
                <v-sheet
                    class="rounded-10 pa-5 mt-2"
                >
                    <v-icon size="8" color="#F8468D" class="pb-2px mr-2">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">분류로 검색</span>

                    <div class="d-flex mt-3">
                        <!-- 국가별 -->
                        <v-select
                            height="33"
                            style="flex: 1"
                            class="rounded-10 font-weight-bold mr-2"
                            label="국가별"
                            dense
                            outlined
                            hide-details
                            item-text="name"
                            item-value="value"
                            :items="select_list.card_nation"
                            v-model="keyword.card_nation"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-2 pb-1px">{{item.name}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                        
                        <!-- 레귤레이션 -->
                        <v-select
                            height="34"
                            style="flex: 1"
                            class="rounded-10"
                            label="레귤레이션"
                            dense
                            outlined
                            hide-details
                            placeholder="선택"
                            persistent-placeholder
                            :items="select_list.regulation_group"
                            v-model="keyword.regulation_group"
                            :menu-props="{ offsetY: true }"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-2 pb-1px">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <div
                    class="d-flex justify-center mt-3 mb-3"
                >
                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        x-small
                        class="mr-2 rounded-10"
                        style="font-size:13px; font-weight:bold"
                        color="primary"
                        width="78"
                        height="34"
                        @click="search(false); mobile.search_detail_switch = false;"
                    >
                        검색
                    </v-btn>

                    <!-- 초기화 -->
                    <v-btn
                        depressed
                        dark
                        x-small
                        class="rounded-10"
                        style="font-size:13px; font-weight:bold"
                        color="#F8468D"
                        width="78"
                        height="34"
                        @click="keywordReset()"
                    >
                        초기화
                    </v-btn>
                </div>
            </div>

            <!-- 순서 & 발매국가 -->
            <div
                class="d-flex px-4 pt-2"
            >
                <!-- 순서 -->
                <v-select
                    height="33"
                    style="flex: 1"
                    class="rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    :items="select_list.sequence"
                    v-model="sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pb-1px">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 발매국가 -->
                <v-select
                    height="33"
                    style="flex: 1"
                    class="rounded-10 font-weight-bold mx-2"
                    dense
                    outlined
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="select_list.card_nation"
                    v-model="keyword.card_nation"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pb-1px">{{item.name}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 덱 태그 -->
                <v-select
                    height="33"
                    style="flex: 1"
                    class="rounded-10 font-weight-bold"
                    dense
                    outlined
                    hide-details
                    placeholder="태그선택"
                    persistent-placeholder
                    :items="select_list.tag"
                    v-model="keyword.tag"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pb-1px">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon small class="pt-6px mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </div>
        </div>

        <!-- 상단 (PC) -->
        <Header
            v-if="!$vuetify.breakpoint.mobile"
            @nationUpdated="nationUpdated_handler"
        />

        <!-- 검색 (PC) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="px-6 py-5 rounded-15"
        >
            <!-- 첫 단 -->
            <div
                class="d-flex mb-5"
            >
                <!-- 레귤레이션 -->
                <v-select
                    height="56"
                    style="width:25%; font-size:18px;"
                    class="rounded-15 font-weight-bold mr-3"
                    label="레귤레이션"
                    dense
                    outlined
                    hide-details
                    :items="select_list.regulation_group"
                    v-model="keyword.regulation_group"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 순서 -->
                <v-select
                    height="56"
                    style="width:25%; font-size:18px;"
                    class="rounded-15 font-weight-bold mr-3"
                    label="순서"
                    dense
                    outlined
                    hide-details
                    :items="select_list.sequence"
                    v-model="sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 제품명 시즌 -->
                <v-autocomplete
                    height="56"
                    style="width:50%; font-size:18px;"
                    class="rounded-15 font-weight-bold"
                    label="제품명 시즌"
                    placeholder="전체"
                    persistent-placeholder
                    dense
                    outlined
                    hide-details
                    :items="set_list"
                    v-model="keyword.season"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-autocomplete>
            </div>

            <!-- 두번째 단 -->
            <div
                class="d-flex align-center"
            >
                <!-- 덱 태그 -->
                <v-select
                    height="56"
                    style="width:188px; font-size:18px;"
                    class="rounded-15 font-weight-bold mr-3"
                    label="덱 태그"
                    dense
                    outlined
                    hide-details
                    placeholder="전체"
                    persistent-placeholder
                    :items="select_list.tag"
                    v-model="keyword.tag"
                >
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 검색 종류 -->
                <v-select
                    height="56"
                    style="width:188px; font-size:18px;"
                    class="rounded-15 font-weight-bold mr-3"
                    label="검색 종류"
                    dense
                    outlined
                    hide-details
                    :items="select_list.search_type"
                    v-model="keyword.search_type"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3 pt-1">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- 덱 이름 -->
                <v-text-field
                    height="56"
                    style="width:387px; font-size:18px;"
                    class="rounded-15 font-weight-bold mr-3"
                    outlined
                    hide-details
                    dense
                    placeholder="검색어를 입력해주세요"
                    persistent-placeholder
                    v-model="keyword.text"
                    append-icon="mdi-magnify"
                    @keyup.enter="search(false)"
                >
                    <template v-slot:append>
                        <v-icon size="30" class="pt-1 mt-1px mr-1" color="#424361">mdi-magnify</v-icon>
                    </template>
                </v-text-field>

                <v-sheet
                    width="188"
                    class="d-flex justify-space-between px-1"
                >
                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        color="primary"
                        width="88"
                        height="49"
                        @click="search(false)"
                    >
                        검색
                    </v-btn>

                    <!-- 조건 초기화 -->
                    <v-btn
                        depressed
                        dark
                        class="ml-2 rounded-10 font-weight-bold"
                        style="font-size:16px;"
                        color="primary"
                        width="88"
                        height="49"
                        @click="keywordReset()"
                    >
                        초기화
                    </v-btn>
                </v-sheet>
            </div>
        </v-sheet>

        <!-- 목록 -->
        <div>
            <!-- 검색결과 있을때 -->
            <div
                v-if="list.length"
                class="mt-2"
                style="margin:0 -4px;"
            >
                <v-row
                    v-if="currentPageList.length"
                    no-gutters 
                >
                    <v-col
                        :class="$vuetify.breakpoint.mobile? 'py-2 px-10':'pa-1'"
                        :cols="$vuetify.breakpoint.mobile? 12:4"
                        v-for="item in currentPageList" :key="item.id"
                    >
                        <v-card
                            style="cursor:pointer; box-shadow:0px 2px 10px #eee !important; border:1px solid #eee; max-width:271px;"
                            class="customHover text-center rounded-xl pb-2 mx-auto elevation-0"
                            @click="read(item)"
                        >
                            <v-sheet
                                height="136"
                                style="position:relative;"
                            >
                                <!-- 덱 이미지 -->
                                <v-img
                                    height="100"
                                    class="rounded-t-xl"
                                    position="center 30%"
                                    :src="computed_image(item)"
                                >
                                    <v-sheet
                                        v-if="item.like_count >= 10"
                                        width="64"
                                        height="24"
                                        class="font-weight-bold rounded-15"
                                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:10px; top:10px;"
                                    >
                                        <span style="color:#424361; font-size:14px;">인기덱</span>
                                    </v-sheet>
                                </v-img>

                                <!-- 레귤레이션 그룹 (스탠다드/익스텐디드) -->
                                <v-sheet
                                    width="120"
                                    class="rounded-20 mx-auto"
                                    style="position:absolute; bottom:20px; box-shadow:0px 2px 8px #ccc; left:50%; transform:translateX(-50%);"
                                >
                                    <p
                                        class="mb-0 py-2"
                                        style="font-size:13px; font-weight:bold;"
                                        :style="item.regulation_group == '스탠다드'? 'color:#615DFA;':'color:#23D2E2;'"
                                    >
                                        {{item.regulation_group}}
                                    </p>
                                </v-sheet>
                            </v-sheet>

                            <div
                                style="position:relative; margin-top:-9px; z-index:2;"
                            >
                                <!-- 덱 제목 -->
                                <p
                                    style="font-size:13px; font-weight:bold; line-height:18px;"
                                    class="px-8 text-truncate mb-0"
                                >
                                    {{item.title}}
                                </p>

                                <!-- 덱 작성일자 -->
                                <p
                                    class="mb-0 pb-1px pt-1px"
                                    style="font-size:9px; color:#B2B2B2; line-height:11px;"
                                >
                                    {{new Date(item.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}
                                </p>

                                <!-- 덱 태그 -->
                                <p
                                    style="font-size:10px; height:20px;"
                                    class="mb-1"
                                >
                                    <font
                                        v-if="item.tag"
                                        class="primary--text font-weight-medium"
                                    >
                                        {{"#"+item.tag?.replaceAll(',', ' #')}}
                                    </font>
                                </p>
                            </div>

                            <div
                                class="d-flex px-5"
                            >
                                <v-sheet
                                    width="33%"
                                >
                                    <p
                                        class="mb-1"
                                        style="font-size:11px; font-weight:bold; color:#424361;"
                                    >
                                        {{item.pokemon_count}}
                                    </p>
                                    <p
                                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                    >
                                        포켓몬
                                    </p>
                                </v-sheet>
                                <v-sheet
                                    width="1"
                                    class="mb-3"
                                    style="background:#f8f7fb;"
                                ></v-sheet>
                                <v-sheet
                                    width="33%"
                                >
                                    <p
                                        class="mb-1"
                                        style="font-size:11px; font-weight:bold; color:#424361;"
                                    >
                                        {{item.trainer_count}}
                                    </p>
                                    <p
                                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                    >
                                        트레이너스
                                    </p>
                                </v-sheet>
                                <v-sheet
                                    width="1"
                                    class="mb-3"
                                    style="background:#f8f7fb;"
                                ></v-sheet>
                                <v-sheet
                                    width="33%"
                                >
                                    <p
                                        class="mb-1"
                                        style="font-size:11px; font-weight:bold; color:#424361;"
                                    >
                                        {{item.energy_count}}
                                    </p>
                                    <p
                                        style="font-size:11px; font-weight:bold; color:#ADAFCA;"
                                    >
                                        에너지
                                    </p>
                                </v-sheet>
                            </div>

                            <p
                                style="font-size:11px; margin-bottom:-2px;"
                            >
                                <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-eye-outline</v-icon> {{item.view_count}}
                                <v-icon class="ml-1 pb-1 mr-2px" size="16">mdi-thumb-up-outline</v-icon> {{item.like_count}}
                                <v-icon class="pb-1 mr-2px" size="16">mdi-content-copy</v-icon> {{item.copy_count}}
                            </p>

                            <v-sheet
                                v-if="item.status=='공개'"
                                height="60"
                            >
                                <!-- 별점 -->
                                <div class="d-flex justify-center align-center mb-1">
                                    <v-rating
                                        :value="item.rating_avg"
                                        half-increments
                                        readonly
                                        background-color="grey"
                                        color="#615DFA"
                                        size="14"
                                    ></v-rating>
                                    <p
                                        class="ml-1 mt-1 mb-0"
                                        style="font-size:11px;"
                                    >
                                        평점
                                        <span style="color:#615DFA">({{item.rating_count}})</span>
                                    </p>
                                </div>

                                <!-- 작성자 닉네임 -->
                                <div
                                    class="d-flex justify-center align-center mb-3"
                                >
                                    <Avatar2
                                        v-if="item.writer"
                                        :user="item.writer"
                                        :color="'black'"
                                    />
                                    <p
                                        class="mb-0"
                                        style="font-size:12px; color:#424361; font-weight:bold; margin-left:-12px;"
                                    >
                                        {{item.writer_nickname}}
                                    </p>
                                </div>
                            </v-sheet>
                            <v-sheet
                                v-if="item.status=='비공개'"
                                height="60"
                                class="pt-5"
                            >
                                <!-- 비공개 -->
                                <p
                                    class="mb-0"
                                    style="font-size:12px; color:#424361; font-weight:medium;"
                                >
                                    비공개
                                </p>
                            </v-sheet>

                            <v-sheet
                                height="1"
                                class="mb-2"
                                style="background:#f8f7fb;"
                            ></v-sheet>

                            <div
                                class="d-flex justify-center"
                                style="font-size:13px; font-weight:bold; color:#424361;"
                            >
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Grass').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Grass').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Fire').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Fire').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Water').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Water').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Lightning').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Lightning').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Psychic').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Psychic').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Fighting').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Fighting').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Darkness').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Darkness').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Metal').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Metal').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Fairy').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Fairy').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Dragon').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Dragon').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                                </div>
                                <div
                                    v-if="item.types.split(',').filter(item => item === 'Colorless').length"
                                    class="mx-2px"
                                >
                                    {{item.types.split(',').filter(item => item === 'Colorless').length}}<br/>
                                    <img width="26" height="26" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <!-- 검색결과 없을떄 -->
            <div
                v-if="list.length == 0"
                class="mt-5 text-center py-10"
            >
                <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                <p style="color:#ccc">검색 결과가 없습니다</p>
            </div>
        </div>

        <!-- 페이지네이션 -->
        <v-pagination
            class="mt-5"
            :class="!$vuetify.breakpoint.mobile? 'mb-2':'mb-2'"
            color="primary"
            v-model="page"
            :length="pageLength"
            :total-visible="10"
        ></v-pagination>
    </div>
</template>
<script>
import Header from '@/views/deck/components/Header'
import Avatar2 from '@/components/AvatarForDeckList'

export default {
    components: {
        Header,
        Avatar2
    },

    data: () => ({
        select_list: {
            card_nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"},
            ],

            regulation_group: [
                "스탠다드",
                "익스펜디드",
                "전체"
            ],

            sequence: [
                "최신순",
                "오래된 순",
                "평점 높은순",
                "평점 낮은순",
                "조회수 높은순",
                "조회수 낮은순",
                "복사수 높은순",
                "복사수 낮은순",
                "댓글 많은 순"
            ],

            search_type: [
                "전체",
                "덱 이름",
                "카드 이름",
                "카드 ID",
                "덱 코드",
                "작성자"
            ],

            // 덱 태그
            tag: [],

            // 제품명 시즌
            set: {
                en: [],
                kr: [],
                jp: [],
            }
        },

        keyword: {
            card_nation: "kr",
            regulation_group: "전체",
            season: "",
            search_type: "전체",
            text: "",

            // 덱 태그
            tag: "전체"
        },

        sequence: "최신순",


        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        mobile: {
            search_detail_switch: false
        },

        standard_list: [],

        condition: {
            my: false,
            comment: false,
            hot: false
        }
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*9,(this.page)*9)
            window.scrollTo(0, 0)
        },

        // 순서
        sequence(){
            this.sortList()
        }
    },

    computed: {
        // 제품명
        set_list(){
            console.log(this.keyword.card_nation)
            switch(this.keyword.card_nation){
                case 'kr' : return this.select_list.set.kr
                case 'en' : return this.select_list.set.en
                case 'jp' : return this.select_list.set.jp
            }
        },
    },

    mounted(){
        // 카드상세 > 더보기 (검색 유형 갖고오기)
        if(this.$route.query.search_type){
            this.keyword.search_type = this.$route.query.search_type
        }

        // 카드상세 > 더보기 (검색어 갖고오기)
        if(this.$route.query.text){
            this.keyword.text = this.$route.query.text
        }

        // 검색
        this.search()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 태그 목록 불러오기
        this.loadTagList()
    },

    methods: {
        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },
        
        // 태그 목록 불러오기
        loadTagList(){
            this.$http.get('/api/admin/deck/tag/list')
            .then((res) => {
                this.select_list.tag = res.data.reverse().map(e => e.tag)
                this.select_list.tag.unshift("전체")
            })
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "jp"
                }
            }).then(res => { 
                this.select_list.set.jp = res.data.map(a => a.name)
            })
        },

        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 국가선택 $Emit Handler
        nationUpdated_handler(card_nation){
            this.keyword.card_nation = card_nation

            this.search()
        },

        // 검색
        search(){
            this.$http.post("/api/new/deck/select/search", {
                params: {
                    deck_nation: this.keyword.card_nation,
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data

                this.currentPageList = this.list.slice(0,9)
                this.pageLength = Math.ceil(this.list.length/9)
                this.list_ready = true
                this.page = 1
            })
        },

        // 검색 키워드 초기화
        keywordReset(){
            this.keyword = {
                card_nation: "kr",
                regulation_group: "전체",
                start_date: "",
                end_date: "",
                search_type: "전체",
                text: ""
            }

            this.sequence = "최신순"
            this.search()
        },

        // 순서 정렬
        sortList(){
            if(this.sequence == "최신순")
            {
                this.list.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
            }
            else if(this.sequence == "오래된 순")
            {
                this.list.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
            }
            else if(this.sequence == "평점 높은순")
            {
                this.list.sort((a, b) => b.rating_avg - a.rating_avg)
            }
            else if(this.sequence == "평점 낮은순")
            {
                this.list.sort((a, b) => a.rating_avg - b.rating_avg)
            }
            else if(this.sequence == "조회수 높은순")
            {
                this.list.sort((a, b) => b.view_count - a.view_count)
            }
            else if(this.sequence == "조회수 낮은순")
            {
                this.list.sort((a, b) => a.view_count - b.view_count)
            }
            else if(this.sequence == "복사수 높은순")
            {
                this.list.sort((a, b) => b.copy_count - a.copy_count)
            }
            else if(this.sequence == "복사수 낮은순")
            {
                this.list.sort((a, b) => a.copy_count - b.copy_count)
            }
            else if(this.sequence == "댓글 많은 순")
            {
                this.list.sort((a, b) => b.rating_count - a.rating_count)
            }

            this.currentPageList = this.list.slice(0,9)
            this.pageLength = Math.ceil(this.list.length/9)
            this.list_ready = true
            this.page = 1
        },

        // 읽기 페이지로 이동
        read(item){
            // TIPTAP에 입력
            this.$emit("DeckSelected", item)
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

</style>